import React, { useEffect, useRef, useState, KeyboardEvent } from "react";
import AbstractButton from "../../components/AbstractButton";
import ForegroundColorSet from "../../../utilities/enums/ForegroundColorSet";
import BackgroundColorSet from "../../../utilities/enums/BackgroundColorSet";
import { ReactComponent as CancelIcon } from "../../../assets/icons/backspace-20-regular.svg";
import { ReactComponent as SaveIcon } from "../../../assets/icons/checkbox-checked-regular.svg";
import {
  PartsAppPurchaseOrderLine,
  SerialInputs,
} from "../../../interfaces/Po";
import { Slide, ToastContainer, toast } from "react-toastify";

const SerialsPage = (props: {
  showSerialsPage: boolean;
  depotId: string;
  line: PartsAppPurchaseOrderLine;
  onCancel(): void;
  onSave(lineNumber: number | undefined, serials: SerialInputs[]): void;
}) => {
  const [localSerials, setLocalSerials] = useState<SerialInputs[]>([]);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  useEffect(() => {
    setTimeout(() => {
      if (props.line.depotLines.filter((d) => d.depot == props.depotId)[0])
        setLocalSerials(
          JSON.parse(
            JSON.stringify(
              props.line.depotLines.filter((d) => d.depot == props.depotId)[0]
                .serialLines
            )
          )
        ); // Deep copy

      let firstEmptySerialIndex = -1;
      for (const s of localSerials) {
        firstEmptySerialIndex++;
        if (s.serialNumber == undefined || s.serialNumber == "") {
          break;
        }
      }
      if (
        firstEmptySerialIndex != localSerials.length - 1 &&
        firstEmptySerialIndex !== -1 &&
        inputRefs.current != null &&
        inputRefs.current[firstEmptySerialIndex]
      ) {
        inputRefs.current[firstEmptySerialIndex]?.focus();
      }
    }, 200);
  }, [props.showSerialsPage]);

  const checkDuplicate = (index: number) => {
    if (
      localSerials.filter(
        (e) => e.serialNumber == inputRefs.current[index]?.value
      ).length > 1 &&
      inputRefs.current[index]?.value.length != 0
    ) {
      toast.error("Cannot enter duplicate Serial Numbers", {
        position: "bottom-center",
        autoClose: 3000,
        theme: "colored",
        transition: Slide,
      });
      const currentInput = inputRefs.current[index];
      if (currentInput) {
        currentInput.value = "";
        localSerials[index].serialNumber = "";
      }
      inputRefs.current[index]?.blur();
      return true;
    }
  };

  const handleKeyDown = (
    event: KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.key === "Enter") {
      if (checkDuplicate(index)) {
        return;
      }
      const nextIndex = index + 1;
      if (nextIndex >= localSerials.length) {
        inputRefs.current[index]?.blur();
      }
      if (nextIndex < localSerials.length && inputRefs.current[nextIndex]) {
        inputRefs.current[nextIndex]?.focus();
      }
    }
  };

  return (
    <div
      className={
        "serial-page " + (props.showSerialsPage ? "modal-open" : "modal-closed")
      }
    >
      <div
        id="serial-page-modal-mask"
        onClick={props.onCancel}
        className="modal-mask"
      ></div>
      <div className="modal-page">
        <div className="info-page-header">
          <p id="finish-page-title">Serial Number Input</p>
        </div>
        <hr className="modal-header-underline" />
        <div className="info-page-section">
          Enter serial numbers or scan their barcodes to receive{" "}
          {props.line?.partNumber}.
        </div>
        <span className="serial-page-items-header">
          Items
          <hr id="serial-page-items-hr" />
        </span>
        <div className="serial-page-grid">
          <div id="serial-grid-part">Part</div>
          <div id="serial-grid-serial">Serial</div>
          <div></div>
        </div>
        <div id="serial-scroll-div">
          {Array.from(
            {
              length: localSerials.length,
            },
            (obj, index) => (
              <div className="serial-page-grid" key={index}>
                <p className="serial-page-part-number">
                  {props.line?.partNumber}
                </p>
                <p className="serial-page-index">{index + 1}</p>
                <input
                  className="serial-page-input"
                  value={
                    localSerials[index] == null
                      ? ""
                      : localSerials[index].serialNumber
                  }
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  onBlur={() => {
                    checkDuplicate(index);
                  }}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onChange={(e) => {
                    // eslint-disable-next-line no-control-regex
                    const regexp = new RegExp("^[\x00-\x7F]*$");
                    // the above matches ascii characters only
                    if (
                      !regexp.test(e.target.value.toUpperCase()) ||
                      e.target.value.length > 20
                    ) {
                      //20 is the max serial length allowed by SKGRN
                      return;
                    }
                    const newSerial = [...localSerials];
                    newSerial[index].serialNumber = e.target.value.toUpperCase();
                    setLocalSerials(newSerial);
                  }}
                />
              </div>
            )
          )}
        </div>
        <span className="modal-button-span">
          <AbstractButton
            id="serial-cancel-button"
            specialClass="modal-button"
            text="Cancel"
            icon={<CancelIcon width="100%" height="100%" />}
            disabled={false}
            activeColor={ForegroundColorSet.Red}
            actionCallback={() => {
              props.onCancel();
            }}
            background={BackgroundColorSet.Black1}
          />
          <AbstractButton
            id="serial-save-button"
            specialClass="modal-button"
            text="Save"
            icon={<SaveIcon width="100%" height="100%" />}
            disabled={false}
            activeColor={ForegroundColorSet.Green}
            actionCallback={() => {
              for (let i = 0; i < localSerials.length; i++) {
                localSerials[i].serialNumber =
                  inputRefs.current[i]?.value ?? "";
              }
              props.onCancel();
              props.onSave(props.line?.lineNumber, localSerials);
            }}
            background={BackgroundColorSet.Black1}
          />
        </span>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SerialsPage;
