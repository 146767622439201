import React from "react";
import PartSearch from "../../../interfaces/PartSearch";
import edit from "../../../assets/icons/edit-bluepencil.svg";

interface FilterItemProperties {
  label: string;
  searchData: PartSearch;
  labelText: string;
  onClick: () => void;
}

function FilterItem(props: FilterItemProperties) {
  const handleClick = () => {
    props.onClick();
  };

  return (
    <table className="filter-option-table">
      <tbody>
        <tr id={"filter-item-property-editor-"+props.label.replace(/\s/g, '')} className="filter-option-row" onClick={handleClick}>
          <td className="filter-option-label-cell">{props.label}:</td>
          <td className="filter-option-value-cell divImageAndLabel">
            <img id={"test-"+props.label.replace(/\s/g, '')} src={edit} className="edit-pencil" alt="" />
            <label>&nbsp;{props.labelText}</label>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default FilterItem;
