interface PartSearch {
  RangeStart: number;
  RangeEnd: number;
  SmartSearch: boolean;
  Order: PartSearchOrderOption;
  OrderDirection: OrderDirection;
  SearchTerm?: string;
  Depot: string;
  Prefixes: string[];
  ProductGroups: string[];
  ClassCodes: string[];
  OnOrder: Inclusivity;
  SerialNumbered: Inclusivity;
  Memo: Inclusivity;
  Supersessions: Inclusivity;
  ShowAllDepots: boolean;
}

export interface BarcodeSearchRequestModel {
  BarcodesToFind: string[];
  Depot: string;
}

export enum Inclusivity {
  Hidden = "Hidden",
  Shown = "Shown",
  Only = "Only",
}

export enum PartSearchOrderOption {
  PartNumber = "PartNumber",
  Description = "Description",
}

export enum OrderDirection {
  Ascending = "Ascending",
  Descending = "Descending",
}

export default PartSearch;
