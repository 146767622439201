import React, { useState } from "react";
import { PartsAppPartOrdered } from "../../../interfaces/PartsAppPart";
import edit from "../../../assets/icons/edit-bluepencil.svg";
import "./PartDetails.css";
import editgrey from "../../../assets/icons/edit-greypencil.svg";
import HeadedValueBox from "../../components/HeadedValueBox";
import Toggle from "react-toggle";

interface PartDetailPageProps {
  part: PartsAppPartOrdered;
  binLocationUpdating: boolean;
  canSeeCosts: boolean;
  canChangeBinLocation: boolean;
  isViewingOffline: boolean;
  availableAtDepot: boolean;
  onEditBinClicked(): void;
  otherPartClicked(partNumber: string): void;
}

const PartDetailsBasicInfo = (props: PartDetailPageProps) => {
  const [pricesVisible, setPricesVisible] = useState(false);
  const [costsVisible, setCostsVisible] = useState(false);

  const ShowHidePrices = () => {
    setPricesVisible(!pricesVisible);
  };
  const ShowHideCosts = () => {
    setCostsVisible(!costsVisible);
  };

  const ConvertDate = (date: Date) => {
    if (new Date(date).getFullYear() == 1 || new Date(date).getFullYear() == 0)
      return "";
    else return new Date(date).toLocaleDateString("en-GB");
  };

  const ConvertType = (type: number) => {
    switch (type) {
      case 1:
        return "Normal";
      case 2:
        return "Special Price";
      case 3:
        return "Memo";
      case 4:
        return "Serial No. Tracking";
      case 5:
        return "Batch Tracking";
      default:
        return "";
    }
  };

  return (
    <div id="part-details-basic-info" className={process.env.REACT_APP_BUILD_ENV?.toUpperCase() != "PLA" ? `part-details-content` : `part-details-content-pla`}>
      <div className="part-details-header">
        <span
          id="basic-details-part-number"
          className="part-details-part-number"
        >
          {props.part.partNumber}
        </span>
      </div>
      <div id="basic-details-description" className="part-details-description">
        {props.part.description}
      </div>
      {props.availableAtDepot ? (
        <div
          id="basic-details-bin"
          className={
            `part-details-description bin-details` +
            (props.binLocationUpdating ? ` black4-foreground` : null)
          }
        >
          <b>Bin</b>: {props.part.bin} &nbsp;
          {props.canChangeBinLocation && !props.isViewingOffline && process.env.REACT_APP_BUILD_ENV?.toUpperCase() != "PLA"  ? (
            !props.binLocationUpdating ? (
              <img
                onClick={() => props.onEditBinClicked()}
                src={edit}
                className="details-edit-pencil"
                alt=""
              />
            ) : (
              <img src={editgrey} className="details-edit-pencil" alt="" />
            )
          ) : null}
        </div>
      ) : null}
      <hr className="hr-divider" />
      <table className="part-details-table">
        <tbody>
          <tr>
            <td id="basic-details-type" className="part-details-2col-field">
              <b>Type</b>: {ConvertType(props.part.type)}
            </td>
            <td id="basic-details-cat" className="part-details-2col-field">
              <b>Cat</b>: {props.part.category}
            </td>
          </tr>
          <tr>
            <td
              id="basic-details-parts-memo"
              className="part-details-2col-field"
            >
              <b>Parts memo</b>: {props.part.partHasMemo ? "Yes" : "No"}
            </td>
            <td id="basic-details-vat-code" className="part-details-2col-field">
              <b>VAT Code</b>: {props.part.vatCode}
            </td>
          </tr>
          <tr>
            <td
              id="basic-details-disc-code"
              className="part-details-2col-field"
            >
              <b>Disc code</b>: {props.part.discCode}
            </td>
            <td id="basic-details-kit-part" className="part-details-2col-field">
              <b>Kit part</b>: {props.part.kitPart}
            </td>
          </tr>
          <tr></tr>
        </tbody>
      </table>
      <div
        id="basic-details-product-group"
        className="part-details-description"
      >
        <b>Product group</b>: {props.part.productGroup}
      </div>
      <div id="basic-details-class-codes" className="part-details-description">
        <b>Class codes</b>: {props.part.classCodes}
      </div>
      {props.part.altPartExistsAtDepot ? (
        <div id="basic-details-alt" className="part-details-description">
          <b>Alt</b>: &nbsp;
          <span
            onClick={() =>
              props.otherPartClicked(
                props.part.altPartNo.replace("<", "").replace(">", "")
              )
            }
            className="blue-foreground"
          >
            {props.part.altPartNo}
          </span>
        </div>
      ) : (
        <div id="basic-details-alt" className="part-details-description">
          <b>Alt</b>:{" "}
          {props.part.altPartNo !== "" ? props.part.altPartNo : " no alt part"}
        </div>
      )}

      <hr className="hr-divider" />
      {props.availableAtDepot ? (
        <>
          <div className="part-details-price-cost-container">
            <span className="price-span">
              <div className="price-cost-toggle-and-label">
                <div className="price-cost-toggle-label">Show Prices</div>
                <Toggle
                  id="price-toggle"
                  icons={false}
                  className="large-toggle"
                  defaultChecked={pricesVisible}
                  onChange={ShowHidePrices}
                />
              </div>
              {pricesVisible ? (
                <HeadedValueBox title="RRP" value={props.part.rrp} />
              ) : null}
              {pricesVisible ? (
                <HeadedValueBox
                  title="Uplift %"
                  value={props.part.uplift / 100}
                />
              ) : null}
              {pricesVisible ? (
                <HeadedValueBox title="Wrnty" value={props.part.warrCost} />
              ) : null}
              {props.part.depotPrice !== 0 ? (
                <HeadedValueBox title="Depot" value={props.part.depotPrice} />
              ) : null}
              <HeadedValueBox title="Price" value={props.part.price} />
              <HeadedValueBox title="Inc. VAT" value={props.part.incVAT} />
            </span>
          </div>
          {props.canSeeCosts ? (
            <>
              <div className="part-details-price-cost-divider" />
              <div className="part-details-price-cost-container">
                <span className="cost-span">
                  <div className="price-cost-toggle-and-label">
                    <div className="price-cost-toggle-label">Show Costs</div>
                    <Toggle
                      id="price-toggle"
                      icons={false}
                      className="large-toggle"
                      defaultChecked={costsVisible}
                      onChange={ShowHideCosts}
                    />
                  </div>

                  {costsVisible ? (
                    <HeadedValueBox title="Nett" value={props.part.nettPrice} />
                  ) : null}
                  {costsVisible ? (
                    <HeadedValueBox title="Avg" value={props.part.avgCost} />
                  ) : null}
                </span>
              </div>
            </>
          ) : null}
        </>
      ) : (
        <div className="part-details-header">
          PRICE AND COST INFO NOT AVAILABLE AT THIS DEPOT
        </div>
      )}

      <div className="hr-container">
        <span className="hr-label">Details</span>
        <hr className="hr-line" />
      </div>
      <div id="basic-details-weight" className="part-details-description">
        <b>Weight</b>: {props.part.weight}
      </div>
      <div id="basic-details-kit-part" className="part-details-description">
        <b>Kit part</b>: {props.part.kitPart}
      </div>
      <div id="basic-details-int-barcode" className="part-details-description">
        <b>Int barcode</b>: {props.part.barcode}
      </div>
      <div id="basic-details-sup-barcode" className="part-details-description">
        <b>Sup barcode</b>: {props.part.supBarcode}
      </div>
      <div
        id="basic-details-associated-part"
        className="part-details-description"
      >
        <b>Associated part</b>: {props.part.associatedPart}
      </div>
      <div id="basic-details-reorder-grp" className="part-details-description">
        <b>Reorder grp</b>: {props.part.reorderGroup}
      </div>
      <div id="basic-details-split-part" className="part-details-description">
        <b>Split part</b>: {props.part.splitPart}
      </div>
      <div id="basic-details-split-qty" className="part-details-description">
        <b>Split qty</b>: {props.part.splitQuantity}
      </div>
      <div id="basic-details-bulk-part" className="part-details-description">
        <b>Bulk part</b>: {props.part.bulkPart}
      </div>
      <div
        id="basic-details-unit-quantity"
        className="part-details-description"
      >
        <b>Unit quantity</b>: {props.part.unitQuantity}
      </div>
      <div className="part-details-description"></div>
      <br />
      <div className="hr-container">
        <span className="hr-label">Supplier</span>
        <hr className="hr-line" />
      </div>
      {pricesVisible ? (
        <div className="part-details-price-table-container">
          <table className="part-details-price-table">
            <tbody>
              <tr>
                <th></th>
                <th>New</th>
                <th>Prev 1</th>
                <th>Prev 2</th>
                <th>Prev 3</th>
                <th>Prev 4</th>
              </tr>
              <tr>
                <td className="part-details-price-heading">Effective</td>
                <td
                  id="basic-details-effnew-date"
                  className="part-details-price-field"
                >
                  {ConvertDate(props.part.effective)}
                </td>
                <td
                  id="basic-details-eff1-date"
                  className="part-details-price-field"
                >
                  {ConvertDate(props.part.prevEffective1)}
                </td>
                <td
                  id="basic-details-eff2-date"
                  className="part-details-price-field"
                >
                  {ConvertDate(props.part.prevEffective2)}
                </td>
                <td
                  id="basic-details-eff3-date"
                  className="part-details-price-field"
                >
                  {ConvertDate(props.part.prevEffective3)}
                </td>
                <td
                  id="basic-details-eff4-date"
                  className="part-details-price-field"
                >
                  {ConvertDate(props.part.prevEffective4)}
                </td>
              </tr>
              <tr>
                <td className="part-details-price-heading">RRP</td>
                <td
                  id="part-details-rrp-new"
                  className="part-details-price-field"
                >
                  {props.part.rrp.toFixed(2)}{" "}
                </td>
                <td
                  id="part-details-rrp-1"
                  className="part-details-price-field"
                >
                  {props.part.prevRRP1.toFixed(2)}
                </td>
                <td
                  id="part-details-rrp-2"
                  className="part-details-price-field"
                >
                  {props.part.prevRRP2.toFixed(2)}
                </td>
                <td
                  id="part-details-rrp-3"
                  className="part-details-price-field"
                >
                  {props.part.prevRRP3.toFixed(2)}
                </td>
                <td
                  id="part-details-rrp-4"
                  className="part-details-price-field"
                >
                  {props.part.prevRRP4.toFixed(2)}
                </td>
              </tr>
              <tr>
                <td className="part-details-price-heading">Nett</td>
                <td
                  id="part-details-nett-new"
                  className="part-details-price-field"
                >
                  {props.part.nettPrice.toFixed(2)}
                </td>
                <td
                  id="part-details-nett-1"
                  className="part-details-price-field"
                >
                  {props.part.prevNet1.toFixed(2)}
                </td>
                <td
                  id="part-details-nett-2"
                  className="part-details-price-field"
                >
                  {props.part.prevNet2.toFixed(2)}
                </td>
                <td
                  id="part-details-nett-3"
                  className="part-details-price-field"
                >
                  {props.part.prevNet3.toFixed(2)}
                </td>
                <td
                  id="part-details-nett-4"
                  className="part-details-price-field"
                >
                  {props.part.prevNet4.toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : null}
      <div id="basic-details-ud-status" className="part-details-description">
        <b>U/D status</b>: {props.part.udStatus}
      </div>
      <div id="basic-details-sup-code-1" className="part-details-description">
        <b>Sup code 1</b>: {props.part.supCode1}
      </div>
      <div id="basic-details-sup-code-2" className="part-details-description">
        <b>Sup code 2</b>: {props.part.supCode2}
      </div>
      <div id="basic-details-sup-code-3" className="part-details-description">
        <b>Sup code 3</b>: {props.part.supCode3}
      </div>
      <div id="basic-details-sup-code-4" className="part-details-description">
        <b>Sup code 4</b>: {props.part.supCode4}
      </div>
      {props.part?.altSupplier1 != "0" ? (
        <div
          id="basic-details-alt-supplier-1"
          className="part-details-description"
        >
          <b>Alt Supplier 1</b>: {props.part.altSupplier1}
        </div>
      ) : null}
      {props.part?.altSupplier2 != "0" ? (
        <div
          id="basic-details-alt-supplier-2"
          className="part-details-description"
        >
          <b>Alt Supplier 2</b>: {props.part.altSupplier2}
        </div>
      ) : null}
      {props.part?.altSupplier3 != "0" ? (
        <div
          id="basic-details-alt-supplier-3"
          className="part-details-description"
        >
          <b>Alt Supplier 3</b>: {props.part.altSupplier3}
        </div>
      ) : null}
      {props.part?.altSupplier4 != "0" ? (
        <div
          id="basic-details-alt-supplier-4"
          className="part-details-description"
        >
          <b>Alt Supplier 4</b>: {props.part.altSupplier4}
        </div>
      ) : null}
      <div
        id="basic-details-commodity-code"
        className="part-details-description"
      >
        <b>Commodity Code</b>: {props.part.commodityCode}
      </div>
      <div id="basic-details-country-code" className="part-details-description">
        <b>Country Code</b>: {props.part.countryCode}
      </div>
      <div
        id="basic-details-sup-part-type"
        className="part-details-description"
      >
        <b>Sup Part Type</b>: {props.part.suppPartType}
      </div>
      <div
        id="basic-details-ts-order-discount"
        className="part-details-description"
      >
        <b>TS Order Discount</b>: {props.part.tsOrderDiscount}
      </div>
      <div id="basic-details-new-part-no" className="part-details-description">
        <b>New Part No</b>: {props.part.newPartNo}
      </div>
    </div>
  );
};

export default PartDetailsBasicInfo;
