import React, { useState, useEffect } from "react";
import "./EditSortBy.css";
import AbstractButton from "../../components/AbstractButton";
import ForegroundColorSet from "../../../utilities/enums/ForegroundColorSet";
import BackgroundColorSet from "../../../utilities/enums/BackgroundColorSet";
import { ReactComponent as BackIcon } from "../../../assets/icons/checkbox-checked-regular.svg";
import { FilterEditorProps } from "../../../interfaces/FilterEditorProps";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { PartPrefix } from "../../../interfaces/SearchFilterCategoryValues";

const EditPrefixes = (props: FilterEditorProps) => {
  const [localSearchData, setLocalSearchData] = useState(props.searchData);

  const myPrefixes = useSelector(
    (state: RootState) => state.root.system.searchFilterCategoryValues.prefixes
  );

  const [prefixes, setPrefixes] = useState<PartPrefix[]>(myPrefixes);
  const [searchText, setSearchText] = useState("");

  const handleSearchTextChange = (event: { target: { value: string } }) => {
    setSearchText(event.target.value);
    if (event.target.value == "") {
      setPrefixes(myPrefixes);
    } else {
      setPrefixes(
        myPrefixes.filter(
          (e: PartPrefix) =>
            e.prefix.toLowerCase().includes(event.target.value.toLowerCase()) ||
            (e.desc &&
              e.desc.toLowerCase().includes(event.target.value.toLowerCase()))
        )
      );
    }
  };

  useEffect(() => {
    setLocalSearchData(props.searchData);
  }, [props.searchData]);

  useEffect(() => {
    setPrefixes(myPrefixes);
  }, [myPrefixes]);

  const handleBack = () => {
    props.onCancelCallback(localSearchData);
  };

  const handleCheckboxChange = (
    e: PartPrefix,
    ev: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!ev.target.checked) {
      setLocalSearchData({
        ...localSearchData,
        Prefixes: localSearchData.Prefixes.filter((p: string) => p != e.prefix),
      });
    } else {
      setLocalSearchData({
        ...localSearchData,
        Prefixes: [...localSearchData.Prefixes, e.prefix],
      });
    }
  };

  const handleClearClick = () => {
    setLocalSearchData({
      ...localSearchData,
      Prefixes: [],
    });
  };

  return (
    <div
      id="edit-sortby-modal"
      className={`filter-modal-contents ${
        props.visible ? "slide-in" : "slide-out-top"
      }`}
    >
      <div className="flex-items">
        <h1 className="sort-and-filter-header">Prefixes</h1>
      </div>

      <div className="flex-items">
        <hr></hr>
      </div>
      <div className="filter-options-search-container">
        <input
          type="text"
          id="prefix-search-box"
          className="filter-search-box"
          value={searchText}
          placeholder="Search prefixes..."
          onChange={handleSearchTextChange}
        />
        <button
          id="prefix-clear-checkboxes-button"
          className="clear-selection-button"
          onClick={handleClearClick}
        >
          CLEAR
        </button>
      </div>
      <div className="flex-items">
        <hr></hr>
      </div>
      <div className="filter-options-list-table-container">
        <table id="prefix-container" className="filter-options-list-table">
          <tbody>
            {prefixes.map((e: PartPrefix) => (
              <tr className="filter-options-row" key={e.prefix}>
                <td className="filter-option-label-cell-extended filter-option-label prefix-selection">
                  {e.prefix} - {e.desc}
                </td>
                <td className="filter-option-value-cell">
                  <input
                    type="checkbox"
                    id={"pre-" + e.prefix}
                    checked={
                      localSearchData.Prefixes.filter((f) => f == e.prefix)
                        .length == 1
                    }
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                      handleCheckboxChange(e, ev)
                    }
                    className="checkbox-style"
                  ></input>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex-items">
        <hr></hr>
      </div>

      <div>
        <span className="filter-modal-button-span">
          <AbstractButton
            id="prefixes-cancel-button"
            text="Done"
            icon={<BackIcon width="100%" height="100%" />}
            disabled={false}
            activeColor={ForegroundColorSet.Green}
            background={BackgroundColorSet.Black1}
            actionCallback={handleBack}
            specialClass="modal-button"
          />
        </span>
      </div>
    </div>
  );
};

export default EditPrefixes;
