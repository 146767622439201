import { combineReducers, configureStore } from '@reduxjs/toolkit'
import authReducer, { AuthState } from './slices/authSlice'
import systemReducer, { SystemState } from './slices/systemSlice'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunk from 'redux-thunk';

const authPersistConfig = {
  key: 'auth',
  storage,
  stateReconciler: autoMergeLevel2
}
const systemPersistConfig = {
  key: 'system',
  storage,
  stateReconciler: autoMergeLevel2
}
const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2
}

const rootReducer = combineReducers({ 
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  auth: persistReducer<AuthState, any>(authPersistConfig, authReducer),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  system: persistReducer<SystemState, any>(systemPersistConfig, systemReducer) 
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const persistedReducer = persistReducer<any, any>(persistConfig, rootReducer);

export const store = configureStore({
  reducer: {
    root:  persistedReducer
  },
  middleware: [thunk]
})

export const persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>