import { ListItem } from "./ScrollableList";

export interface PartsAppPartOrdered extends PartsAppPart {
  depotString: string;
  existsAtDepot: boolean;
}

export interface PartsAppPart extends ListItem{
  partNumber: string;
  prefix: string;
  productGroup: string;
  description: string;
  barcode: string;
  depot: string;
  bin: string;
  type: number;
  category: string;
  altPartNo: string;
  altPartExistsAtDepot: boolean;
  salesPackSize: number;
  purchasePackSize: number;
  classCodes: string;
  nettPrice: number;
  rrp: number;
  incVAT: number;
  avgCost: number;
  inStock: number;
  wipStock: number;
  wipStockCredits: number;
  freeStock: number;
  freeStockCollections: number;
  vanStock: number;
  minStock: number;
  maxStock: number;
  emergencyStock: number;
  stockDepth: number;
  stockLastChecked: Date;
  ordered: number;
  orderAllocated: number;
  orderReqForPO: number;
  orderReqNotAlloc: number;
  orderReqForVans: number;
  orderReceivedNoInvoice: number;
  orderSuggestedMain: number;
  orderSuggestedAdditional: number;
  idtIn: number;
  idtOut: number;
  idtFree: number;
  lastSold: Date;
  lastPurchased: Date;
  created: Date;
  vatCode: number;
  weight: number;
  discCode: string;
  kitPart: string;
  supBarcode: string;
  splitPart: string;
  splitQuantity: number;
  bulkPart: string;
  supCode1: string;
  supCode2: string;
  supCode3: string;
  supCode4: string;
  commodityCode: string;
  reorderGroup: string;
  associatedPart: string;
  unitQuantity: number;
  suppPartType: string;
  newPartNo: string;
  tsOrderDiscount: string;
  prevRRP1: number;
  prevRRP2: number;
  prevRRP3: number;
  prevRRP4: number;
  prevNet1: number;
  prevNet2: number;
  prevNet3: number;
  prevNet4: number;
  effective: Date;
  prevEffective1: Date;
  prevEffective2: Date;
  prevEffective3: Date;
  prevEffective4: Date;
  udStatus: string;
  warrCost: number;
  wholeGroupInfo?: PartsAppPartWholeGroupInfo[];
  countryCode: string;
  orderPlaced: number;
  wsjwip: number;
  poswip: number;
  salesRecord1: number;
  salesRecord2: number;
  salesRecord3: number;
  salesRecord4: number;
  salesRecord5: number;
  salesRecord6: number;
  salesRecord7: number;
  salesRecord8: number;
  salesRecord9: number;
  salesRecord10: number;
  salesRecord11: number;
  salesRecord12: number;
  salesRecord13: number;
  salesRecord14: number;
  salesRecord15: number;
  salesRecord16: number;
  salesRecord17: number;
  salesRecord18: number;
  salesRecord19: number;
  salesRecord20: number;
  salesRecord21: number;
  salesRecord22: number;
  salesRecord23: number;
  salesRecord24: number;
  salesRecord25: number;
  salesRecord26: number;
  salesRecord27: number;
  salesRecord28: number;
  salesRecord29: number;
  salesRecord30: number;
  salesRecord31: number;
  salesRecord32: number;
  salesRecord33: number;
  salesRecord34: number;
  salesRecord35: number;
  salesRecord36: number;
  hitsRecord1: number;
  hitsRecord2: number;
  hitsRecord3: number;
  hitsRecord4: number;
  hitsRecord5: number;
  hitsRecord6: number;
  hitsRecord7: number;
  hitsRecord8: number;
  hitsRecord9: number;
  hitsRecord10: number;
  hitsRecord11: number;
  hitsRecord12: number;
  hitsRecord13: number;
  hitsRecord14: number;
  hitsRecord15: number;
  hitsRecord16: number;
  hitsRecord17: number;
  hitsRecord18: number;
  hitsRecord19: number;
  hitsRecord20: number;
  hitsRecord21: number;
  hitsRecord22: number;
  hitsRecord23: number;
  hitsRecord24: number;
  hitsRecord25: number;
  hitsRecord26: number;
  hitsRecord27: number;
  hitsRecord28: number;
  hitsRecord29: number;
  hitsRecord30: number;
  hitsRecord31: number;
  hitsRecord32: number;
  hitsRecord33: number;
  hitsRecord34: number;
  hitsRecord35: number;
  hitsRecord36: number;
  altSupplier1: string;
  altSupplier2: string;
  altSupplier3: string;
  altSupplier4: string;
  partHasMemo: boolean;
  supplierNickname: string;
  price: number;
  uplift: number;
  depotPrice: number;
}

export interface PartsAppPartWholeGroupInfo {
  depotName: string;
  bin: string;
  qoH: number;
  free: number;
  wip: number;
  van: number;
  suggested: number;
  onOrder: number;
  emergency: number;
  min: number;
  max: number;
  allocated: number;
  reqForPO: number;
  reqForVans: number;
  reqNotAlloc: number;
  receivedNoInvoice: number;
}

const defaultValues: PartsAppPartOrdered = {
  partNumber: "",
  prefix: "",
  productGroup: "",
  description: "",
  barcode: "",
  depot: "",
  bin: "",
  type: 0,
  category: "",
  altPartNo: "",
  altPartExistsAtDepot: false,
  salesPackSize: 0,
  purchasePackSize: 0,
  classCodes: "",
  nettPrice: 0,
  rrp: 0,
  incVAT: 0,
  avgCost: 0,
  inStock: 0,
  wipStock: 0,
  wipStockCredits: 0,
  freeStock: 0,
  freeStockCollections: 0,
  vanStock: 0,
  minStock: 0,
  maxStock: 0,
  emergencyStock: 0,
  stockDepth: 0,
  stockLastChecked: new Date(),
  ordered: 0,
  orderAllocated: 0,
  orderReqForPO: 0,
  orderReqNotAlloc: 0,
  orderReqForVans: 0,
  orderReceivedNoInvoice: 0,
  orderSuggestedMain: 0,
  orderSuggestedAdditional: 0,
  idtIn: 0,
  idtOut: 0,
  idtFree: 0,
  lastSold: new Date(),
  lastPurchased: new Date(),
  created: new Date(),
  rangeStart: 0,
  depotString: "",
  vatCode: 0,
  weight: 0,
  discCode: "",
  kitPart: "",
  supBarcode: "",
  splitPart: "",
  splitQuantity: 0,
  bulkPart: "",
  supCode1: "",
  supCode2: "",
  supCode3: "",
  supCode4: "",
  commodityCode: "",
  reorderGroup: "",
  associatedPart: "",
  unitQuantity: 0,
  suppPartType: "",
  newPartNo: "",
  tsOrderDiscount: "",
  prevRRP1: 0,
  prevRRP2: 0,
  prevRRP3: 0,
  prevRRP4: 0,
  prevNet1: 0,
  prevNet2: 0,
  prevNet3: 0,
  prevNet4: 0,
  effective: new Date(),
  prevEffective1: new Date(),
  prevEffective2: new Date(),
  prevEffective3: new Date(),
  prevEffective4: new Date(),
  udStatus: "",
  warrCost: 0,
  countryCode: "",
  wsjwip: 0,
  poswip: 0,
  orderPlaced: 0,
  salesRecord1: 0,
  salesRecord2: 0,
  salesRecord3: 0,
  salesRecord4: 0,
  salesRecord5: 0,
  salesRecord6: 0,
  salesRecord7: 0,
  salesRecord8: 0,
  salesRecord9: 0,
  salesRecord10: 0,
  salesRecord11: 0,
  salesRecord12: 0,
  salesRecord13: 0,
  salesRecord14: 0,
  salesRecord15: 0,
  salesRecord16: 0,
  salesRecord17: 0,
  salesRecord18: 0,
  salesRecord19: 0,
  salesRecord20: 0,
  salesRecord21: 0,
  salesRecord22: 0,
  salesRecord23: 0,
  salesRecord24: 0,
  salesRecord25: 0,
  salesRecord26: 0,
  salesRecord27: 0,
  salesRecord28: 0,
  salesRecord29: 0,
  salesRecord30: 0,
  salesRecord31: 0,
  salesRecord32: 0,
  salesRecord33: 0,
  salesRecord34: 0,
  salesRecord35: 0,
  salesRecord36: 0,
  hitsRecord1: 0,
  hitsRecord2: 0,
  hitsRecord3: 0,
  hitsRecord4: 0,
  hitsRecord5: 0,
  hitsRecord6: 0,
  hitsRecord7: 0,
  hitsRecord8: 0,
  hitsRecord9: 0,
  hitsRecord10: 0,
  hitsRecord11: 0,
  hitsRecord12: 0,
  hitsRecord13: 0,
  hitsRecord14: 0,
  hitsRecord15: 0,
  hitsRecord16: 0,
  hitsRecord17: 0,
  hitsRecord18: 0,
  hitsRecord19: 0,
  hitsRecord20: 0,
  hitsRecord21: 0,
  hitsRecord22: 0,
  hitsRecord23: 0,
  hitsRecord24: 0,
  hitsRecord25: 0,
  hitsRecord26: 0,
  hitsRecord27: 0,
  hitsRecord28: 0,
  hitsRecord29: 0,
  hitsRecord30: 0,
  hitsRecord31: 0,
  hitsRecord32: 0,
  hitsRecord33: 0,
  hitsRecord34: 0,
  hitsRecord35: 0,
  hitsRecord36: 0,
  altSupplier1: "",
  altSupplier2: "",
  altSupplier3: "",
  altSupplier4: "",
  partHasMemo: false,
  supplierNickname: "",
  price: 0,
  uplift: 0,
  depotPrice: 0,
  index: 0,
  existsAtDepot: false,
};


export const defaultPartOrdered: PartsAppPartOrdered = { ...defaultValues };

export default PartsAppPart;
