import React, { useEffect, useState, KeyboardEvent } from "react";
import AbstractButton from "../components/AbstractButton";
import ForegroundColorSet from "../../utilities/enums/ForegroundColorSet";
import BackgroundColorSet from "../../utilities/enums/BackgroundColorSet";
import { ReactComponent as ScanIcon } from "../../assets/icons/barcode-scanner.svg";
import { ReactComponent as InsertLineIcon } from "../../assets/icons/window-bullet-list-add-regular.svg";
import { ReactComponent as SortIcon } from "../../assets/icons/sort-filter.svg";
import { ReactComponent as BackIcon } from "../../assets/icons/back-chevron.svg";
import { ReactComponent as FinishIcon } from "../../assets/icons/checkbox-checked-regular.svg";
import TabList, { TabProps } from "../components/TabList";
import { useNavigate } from "react-router-dom";
import PartsAppPurchaseOrder, { PoLock, PoOrdered } from "../../interfaces/Po";
import { PartsAppPurchaseOrderLock } from "../../interfaces/PoLock";
import PurchaseOrderSearch, {
  PartsAppPoTransType,
  defaultPurchaseOrderSearch,
} from "../../interfaces/PoSearch";
import { apiFetch } from "../../utilities/AuthenticatedFetch";
import { Slide, ToastContainer, toast } from "react-toastify";
import ScrollableCardList from "../components/ScrollableCardList";
import PoCard from "./PoCard";
import NavBar from "../components/NavBar";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import LoadingMask from "../../utilities/LoadingMask";
import loadingspinner from "../../assets/spinning-loading.gif";
import { Feature, PartsAppFeature } from "../../store/slices/authSlice";

const Receive = () => {
  const depotId = useSelector(
    (state: RootState) => state.root.auth.defaultDepotId
  );
  const userId = useSelector((state: RootState) => state.root.auth.id);
  const [range, setRange] = useState({ start: 0, end: 10 });
  const [isFetching, setIsFetching] = useState(false);
  const [isLoadingLines, setIsLoadingLines] = useState(false);

  const hasIDTFeature = useSelector((state: RootState) => state.root.auth.features.filter((e: PartsAppFeature) => e.featureId == Feature.IDTReceive).length > 0);

  const SEARCH_ENDPOINT = "/partsapp/receive/search";
  const tabList: TabProps[] = [
    {
      index: 0,
      label: "Purchase order",
      callback: () => {
        searchParams.SearchTerm = "";

        const scrollableDiv = document.getElementById(
          "scrollable-list"
        ) as HTMLElement;
        scrollableDiv.scrollTop = 0;

        searchParams.TransactionType = PartsAppPoTransType.PurchaseOrder;
        setIsFetching(true);
        fetchMoreSearchResults(10, undefined, {start: 0, end: 10}, true)
        .then((v) => {
          if (v) {
            setSearchResults(v);
          }}) 
        .finally(() => {
          setIsFetching(false);
        })
      },
      enabled: !isFetching,
    },
    {
      index: 1,
      label: "Depot transfer",
      callback: () => {
        searchParams.SearchTerm = "";
        const scrollableDiv = document.getElementById(
          "scrollable-list"
        ) as HTMLElement;
        scrollableDiv.scrollTop = 0;
        searchParams.TransactionType = PartsAppPoTransType.IDT;
        setIsFetching(true);
        fetchMoreSearchResults(10, undefined, {start: 0, end: 10}, true)
        .then((v) => {
          if (v) {
            setSearchResults(v);
          }}) 
        .finally(() => {
          setIsFetching(false);
        })
      },
      // CHECK WHETHER YOU HAVE THE IDT FEATURE 
      enabled: useSelector((state: RootState) => state.root.auth.features.filter((e: PartsAppFeature) => e.featureId == Feature.IDTReceive).length > 0) && !isFetching,
    },
  ];

  const navigate = useNavigate();
  const [lastSearchReturnedStuff, setLastSearchReturnedStuff] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const searchParams = defaultPurchaseOrderSearch;
  const [searchResults, setSearchResults] = useState<PoOrdered[]>([]);

  const handleHomePress = () => {
    navigate("/");
  };
  const handleEnquirePress = () => {
    navigate("/enquire");
  };

  // Define the function to fetch the search results
  async function fetchSearchResults(
    searchParams: PurchaseOrderSearch,
    start: number,
    end: number,
    lockedOrder?: PartsAppPurchaseOrderLock
  ): Promise<PoOrdered[]> {
    setIsFetching(true);
    ///lockedOrder can only be used if the user has an existing locked purchase order
    //the following condition is only to reset the ExactMatch and searchTerm when coming back from PurchaseOrderLines screen
    if (searchParams.ExactMatch) {
      searchParams.SearchTerm = "";
      searchParams.ExactMatch = false;
    }
    // Build the request options
    if (lockedOrder?.orderNumber != null) {
      searchParams.SearchTerm = lockedOrder.orderNumber;
      searchParams.ExactMatch = true;
      searchParams.TransactionType = hasIDTFeature 
        ? PartsAppPoTransType.Any 
        : PartsAppPoTransType.PurchaseOrder;
    }
    searchParams.RangeStart = start;
    searchParams.RangeEnd = end;
    searchParams.SearchTerm = searchParams.SearchTerm?.toUpperCase();
    searchParams.Depot = depotId;
    const requestOptions: RequestInit = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(searchParams),
    };
    // Send the request and wait for the response
    try {
      const response = await apiFetch<PartsAppPurchaseOrder[]>(
        SEARCH_ENDPOINT,
        requestOptions
      );

      // Parse the response body as JSON
      setLastSearchReturnedStuff(response.length > 0);

      setIsFetching(false);
      const rangeResponse = response as PoOrdered[];
      return rangeResponse.map((e) => {
        e.rangeStart = start;
        return e;
      }) as PoOrdered[];
    } catch (error: unknown) {
      let message: string;
      if (error instanceof Error) {
        message = error.message;
      } else {
        message = String(error);
      }
      if (message.includes("appear to be offline")) {
        setIsFetching(false);
      }
      toast.error("Purchase Order Search Failed:  " + message, {
        position: "bottom-center",
        autoClose: 3000,
        theme: "colored",
        transition: Slide,
      });
      return [];
    }
  }

  useEffect(() => {
    searchParams.TransactionType = PartsAppPoTransType.PurchaseOrder;
    getPurchaseLock().then((lOrder) => {
      fetchMoreSearchResults(10, lOrder, {start: 0, end: 10}, true).then((v) => {
        if (v) {
          setSearchResults(v);
        }
        if (v.length > 0 && lOrder?.orderNumber != null) {
          setIsLoadingLines(true);
          loadAdditionalPOInfo(v[0]).then((response) => {
            if (response.isIDT)
            {
              response.lines.map((l) => {
                l.depotLines.map((dl) => {
                  const idtReceived = dl.invoiced ?? 0;
                  dl.delivered = idtReceived;
                  return dl;
                })
                return l;
              });
            }
            navigate("/receive/purchaseorder", {
              state: { order: response, depotId: response.isIDT ? response.lines[0].depotLines.filter(e => 
              e.ordered != 0
              )[0].depot : depotId },
            });
          })
          .catch(() => {
           apiFetch<PartsAppPurchaseOrderLock>(
            "/partsapp/receive/DeleteLockedPurchaseOrder/" + userId,
            {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
              },
            } 
          )
            navigate("/receive");
          })
        }
      });
    });
  }, []);

  function fetchMoreSearchResults(
    amount: number,
    lOrder?: PartsAppPurchaseOrderLock,
    localRange?: {start: number, end: number},
    tabChangeUpdate?: boolean
  ) {
    if ((!lastSearchReturnedStuff || isFetching) && !tabChangeUpdate) {
      return Promise.resolve([]);
    }
    let {start, end} = { start: 0, end: 10 };
    if (localRange)
    {
      start = localRange.start;
      end = localRange.end;
    }
    else {
      start = range.start;
      end = range.end;
    }


    setRange({ start: end, end: end + amount });

    const results = fetchSearchResults(searchParams, start, end, lOrder);
    return results;
  }

  const changeSearchTerm = (event: { target: { value: string } }) => {
    const term = event.target.value;
    setSearchTerm(term);
  };

  const checkForReturn = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.key === "Go" || e.key === "Next") {
      const searchBar = document.getElementById(
        "search-term-input-text"
      ) as HTMLElement;
      searchBar.blur();
    }
  };

  function doSearch(data: PurchaseOrderSearch) {
    setSearchResults([]);
    let term = searchTerm;
    term = term.trim();

    setRange({ start: 0, end: 10 });
    data.SearchTerm = term;
    data.Depot = depotId;

    setSearchParams(data);

    const results = fetchSearchResults(data, 0, 10);

    results.then((e) => {
      setSearchResults(e);
      setRange({ start: 10, end: 20 });
    });

    const search = document.getElementById(
       "search-term-input-text"
    ) as HTMLInputElement;
     search.value = "";
  }

  function setSearchParams(data: PurchaseOrderSearch) {
    searchParams.Depot = data.Depot;
    searchParams.OrderDirection = data.OrderDirection;
    searchParams.PartiallyReceived = data.PartiallyReceived;
    searchParams.SearchTerm = data.SearchTerm;
  }

  function loadAdditionalPOInfo(purchaseorder: PoOrdered) {
    return apiFetch<PoOrdered>(
      "/partsapp/receive/LoadAdditionalPurchaseOrderInformation",
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(purchaseorder),
      }
    );
  }

  function addPurchaseLock(orderNumber: string) {
    return apiFetch<PartsAppPurchaseOrderLock>(
      "/partsapp/receive/AddPurchaseOrderLock",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          UserId: userId,
          OrderNumber: orderNumber,
        }),
      }
    );
  }

  function getPurchaseLock() {
    return apiFetch<PartsAppPurchaseOrderLock>(
      "/partsapp/receive/GetLockedPurchaseOrderByUserId/" + userId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }


  const sortAndMapLines = (order: PoOrdered) => {
    return [...order.lines].sort((a, b) => {
      let aDepotIndex = 0;
      let bDepotIndex = 0;
      a.depotLines.forEach((e) => {
        if (e.depot == depotId) aDepotIndex = a.depotLines.indexOf(e);
      });
      b.depotLines.forEach((e) => {
        if (e.depot == depotId) bDepotIndex = b.depotLines.indexOf(e);
      });
      return (
        (a.depotLines[aDepotIndex].delivered || 0) /
          (a.depotLines[aDepotIndex].ordered || 1) -
        (b.depotLines[bDepotIndex].delivered || 0) /
          (b.depotLines[bDepotIndex].ordered || 1)
      );
    });
  };

  return (
    <>
      <div id="receive-container" className="module-container">
        <div id="receive-nav-top" className="module-nav nav-top">
          <div className="module-nav-button top">
            <AbstractButton
              id="receive-nav-back-button"
              text="Back"
              icon={<BackIcon className="module-nav-button-logo" />}
              activeColor={ForegroundColorSet.Blue}
              background={BackgroundColorSet.Black1}
              specialClass={null}
              disabled={false}
              actionCallback={handleHomePress}
            />
          </div>
          <div className="module-nav-button top">
            <AbstractButton
              id="receive-nav-sort-button"
              text="Filter"
              specialClass={null}
              icon={<SortIcon className="module-nav-button-logo" />}
              activeColor={ForegroundColorSet.Black3}
              background={BackgroundColorSet.Black1}
              disabled={true}
              actionCallback={handleEnquirePress}
            />
          </div>
          <div className="module-nav-button top">
            <AbstractButton
              id="receive-nav-options-button"
              text="+&nbsp;Line"
              specialClass={null}
              icon={<InsertLineIcon className="module-nav-button-logo" />}
              activeColor={ForegroundColorSet.Black3}
              background={BackgroundColorSet.Black1}
              disabled={false}
              actionCallback={function (): unknown {
                throw new Error("Function not implemented.");
              }}
            />
          </div>
          <div className="module-nav-button top">
            <AbstractButton
              id="receive-nav-save-button"
              text="Save"
              specialClass={null}
              icon={<FinishIcon className="module-nav-button-logo" />}
              disabled={true}
              activeColor={ForegroundColorSet.Blue}
              background={BackgroundColorSet.Black1}
              actionCallback={function (): unknown {
                throw new Error("Function not implemented.");
              }}
            />
          </div>
        </div>
        <div id="receive-tab-container" className="search-results">
          <TabList tabs={tabList} twoOptions={true} isDisabled={false} />
        </div>
        <div id="receive-search-results" className={`receive-search-results`}>
          <ScrollableCardList
            fetchMoreResults={() => fetchMoreSearchResults(10)}
            initialItems={searchResults}
            isFetching={isFetching}
            loadsLazily={true}
            renderItem={(po) => (
              <PoCard
                key={po.orderNumber}
                poClicked={() => {
                  if (po.orderNumber[-1] === " ") {
                    toast.error(
                      "PO number invalid, this order cannot be opened in Parts App",
                      {
                        position: "bottom-center",
                        autoClose: 3000,
                        theme: "colored",
                        transition: Slide,
                      }
                    );
                    return;
                  }
                  setIsLoadingLines(true);
                  loadAdditionalPOInfo(po)
                    .then((response) => {
                      po = response;
                      if (po.lockedBy)
                      {
                          toast.error("Could not open "+ (po.isIDT ? "IDT" : "purchase order") + ": record locked", {
                            position: "bottom-center",
                            autoClose: 3000,
                            theme: "colored",
                            transition: Slide,
                          });
                          return;
                      }
                      if (response.isIDT)
                      {
                        response.lines.map((l) => {
                          l.depotLines.map((dl) => {
                            const idtReceived = dl.invoiced ?? 0;
                            dl.delivered = idtReceived;
                            return dl;
                          });
                          return l;
                        });
                      }
                      addPurchaseLock(po.orderNumber)
                        .then((lock: PoLock) => {
                          po.lines = sortAndMapLines(po);
                          po.lockedById = lock.userId;
                          navigate("/receive/purchaseorder", {
                            state: { order: response, depotId: response.isIDT ? response.lines[0].depotLines.filter(e => 
                              e.ordered != null && e.ordered != 0
                            )[0].depot : depotId },
                          });
                        })
                        .catch((e) => {
                          toast.error("Could not open "+ (po.isIDT ? "IDT" : "Purchase Order") + ": " + e, {
                            position: "bottom-center",
                            autoClose: 3000,
                            theme: "colored",
                            transition: Slide,
                          });
                        })
                        .finally(() => {
                          setIsLoadingLines(false);
                        });
                    })
                    .catch((e) => {
                      toast.error("Could not open "+ (po.isIDT ? "IDT" : "Purchase Order") + ": " + e, {
                        position: "bottom-center",
                        autoClose: 3000,
                        theme: "colored",
                        transition: Slide,
                      });
                    })
                    .finally(() => {
                      setIsLoadingLines(false);
                    });
                }}
                po={po}
              />
            )}
          />
        </div>
      </div>
      <div
        id="receive-search-input-wrapper"
        className="module-search search-input"
      >
        <input
          id="search-term-input-text"
          className="module-search-term-input-text"
          type="text"
          placeholder="&#x1F50D; Search..."
          onKeyDown={checkForReturn}
          onChange={changeSearchTerm}
          onBlur={() => doSearch(searchParams)}
          autoCorrect="off"
          autoCapitalize="none"
          autoComplete="off"
          spellCheck="false"
        />
        <button
          id="receive-search-barcode-button"
          className="module-search-barcode-button greyed-out"
        >
          <ScanIcon className="module-search-barcode-button-logo" />
        </button>
      </div>
      <div
        id="enquire-search-input-curtain"
        className="search-bar-safe-area-curtain"
      />
      <ToastContainer />
      <NavBar activePage={"receive"} />

      {isLoadingLines ? (
        <LoadingMask
          text="loading purchase order lines..."
          icon={loadingspinner}
        />
      ) : null}
    </>
  );
};

export default Receive;
