import React from "react";
import "./NavButton.css";
import ForegroundColorSet from "../../utilities/enums/ForegroundColorSet";
import BackgroundColorSet from "../../utilities/enums/BackgroundColorSet";

interface AbstractButtonProperties {
  id: string;
  text: string;
  icon: React.ReactNode;
  disabled: boolean;
  activeColor: ForegroundColorSet;
  background: BackgroundColorSet;
  specialClass: string | null;
  actionCallback(): unknown;
}

const AbstractButton = (props: AbstractButtonProperties) => {
  const id = props.id;
  const color = !props.disabled ? props.activeColor : ForegroundColorSet.Black3;

  return (
    <button
      disabled={props.disabled}
      onClick={props.actionCallback}
      id={id}
      className={`${props.specialClass == undefined ? "nav-button" : props.specialClass} ${color} ${props.background}`}
    >
      <span
        className={`nav-button-icon-surround`}
        data-disabled={props.disabled}
      >
        {props.icon}
      </span>
      <span className="nav-button-label">{props.text}</span>
    </button>
  );
};

export default AbstractButton;
