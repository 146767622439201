import React, { useEffect, useRef, useState } from "react";
import AbstractButton from "../components/AbstractButton";
import ForegroundColorSet from "../../utilities/enums/ForegroundColorSet";
import BackgroundColorSet from "../../utilities/enums/BackgroundColorSet";
import { ReactComponent as CancelIcon } from "../../assets/icons/backspace-20-regular.svg";
import BarcodeCameraScanner from "../components/BarcodeCameraScanner";
import PartsAppPart from "../../interfaces/PartsAppPart";
import Toggle from "react-toggle";
import { useNavigate } from "react-router-dom";
import BarcodeBluetoothScanner from "../components/BarcodeBluetoothScanner";

interface BarcodeSearchModalProps {
  barcodeSearchEndpoint: string;
  depot: string;
  onCancelCallback(): void;
  onBarcodeEntered: (barcode: string) => void;
  onPartSelected: (part: PartsAppPart) => void;
  useDeviceCameraByDefault: boolean;
  foundParts: PartsAppPart[];
}

const BarcodeSearchModal = (props: BarcodeSearchModalProps) => {
  const navigate = useNavigate();
  const [barcode, setBarcode] = useState("");
  const [useDeviceCamera, setUseDeviceCamera] = useState(
    props.useDeviceCameraByDefault
  );
  const [lastInputTime, setLastInputTime] = useState(0);
  const barcodeScannerThreshold = 200; // Adjust this value based on your scanner's input speed.
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - lastInputTime;

    setBarcode(value);
    setLastInputTime(currentTime);

    if (timeDifference < barcodeScannerThreshold) {
      if (timer.current) clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        props.onBarcodeEntered(value);
        setBarcode("");
      }, barcodeScannerThreshold);
    }
  };

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!useDeviceCamera) inputRef.current?.focus();
  }, [useDeviceCamera]);

  const handleDeviceCameraToggle = () => {
    if (useDeviceCamera) {
      setUseDeviceCamera(!useDeviceCamera);
      navigate("/enquire?switchscanmode=true");
      window.location.reload();
    } else {
      setUseDeviceCamera(!useDeviceCamera);
    }
  };

  return (
    <div id="barcode-search-modal">
      <span className="modal-header-text">Scan Barcode</span>
      <span className="modal-toggle-container">
        <span className="device-camera-toggle-label">Use Device Camera:</span>
        <Toggle
          id="device-camera-toggle"
          icons={false}
          className="large-toggle"
          defaultChecked={useDeviceCamera}
          onChange={handleDeviceCameraToggle}
        />
      </span>

      {!useDeviceCamera ? (
        <BarcodeBluetoothScanner
          onSelect={props.onPartSelected}
          inputRef={inputRef}
          barcode={barcode}
          handleInputChange={handleInputChange}
          foundParts={props.foundParts}
        />
      ) : (
        <BarcodeCameraScanner
          barcodeSearchEndpoint={props.barcodeSearchEndpoint}
          depotId={props.depot}
          shouldBeScanning={useDeviceCamera}
          onSelect={props.onPartSelected}
        />
      )}
      <span
        className={
          `filter-modal-button-span` + (useDeviceCamera ? ` camera-mode` : ``)
        }
      >
        <AbstractButton
          id="barcode-cancel-button"
          specialClass="modal-button"
          text="Cancel"
          icon={<CancelIcon width="100%" height="100%" />}
          disabled={false}
          activeColor={ForegroundColorSet.Red}
          background={BackgroundColorSet.Black1}
          actionCallback={props.onCancelCallback}
        />
      </span>
    </div>
  );
};

export default BarcodeSearchModal;
