import { PartsAppPartOrdered } from "../../interfaces/PartsAppPart";
import React from "react";

export interface PartCardProperities {
  myDepotName: string;
  partClicked(part: PartsAppPartOrdered): void;
  part: PartsAppPartOrdered;
  isRecent: boolean;
}

const PartCard = (props: PartCardProperities) => {
  return (
    <div
      id={props.part.partNumber + "-part-card"}
      key={props.part.partNumber}
      onClick={() => props.partClicked(props.part)}
      className={`parts-list-item `}
    >
      <div className={`part-item-header`}>
        <span className="part-name">{props.part.partNumber}</span>
        <span className="part-description">{props.part.description}</span>
      </div>
      {props.part.existsAtDepot ? (
        props.isRecent ? 
        <div className="part-item-values">
          <div className="part-item-value">
            <div className="part-item-value-content gold-foreground">
               Recent part from depot: 
            </div>
            <div
              key={props.part.partNumber}
              className="part-item-value-content gold-foreground depot-string"
            >
              {props.part.depot}
            </div>
          </div>
        </div>
        :
        <div className="part-item-values">
          <div className="part-item-value">
            <div className="part-item-value-label">Free:</div>
            <div className="part-item-value-content">
              {props.part.freeStock}
            </div>
          </div>
          <div className="part-item-value">
            <div className="part-item-value-label">Orders:</div>
            <div className="part-item-value-content">{props.part.ordered}</div>
          </div>
          <div className="part-item-value">
            <div className="part-item-value-label">Bin:</div>
            <div className="part-item-value-content">{props.part.bin}</div>
          </div>
        </div>
      ) : (
        <div className="part-item-values">
          <div className="part-item-value">
            <div className="part-item-value-content gold-foreground">
              <span className="part-item-value-label">
                No record at this depot. &nbsp;
              </span>
              Part exists at: &nbsp;
            </div>
            <div
              key={props.part.partNumber}
              className="part-item-value-content gold-foreground depot-string"
            >
              {props.part.depotString}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PartCard;
