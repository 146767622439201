import React from "react";
import "./Utilities.css";

interface LoadingMaskProperties {
    text: string;
    icon: string;
}

const LoadingMask = (props: LoadingMaskProperties) => {
    return (
        <div id="loading-mask" className="loading-mask">
            <img className="loading-mask-icon" src={props.icon}/> 
            <div className="loading-mask-text" id="loading-mask-text"> {props.text} </div>
        </div>
    );
}

export default LoadingMask;
