import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Depot from "../../interfaces/Depot";
import { SearchFilterCategoryValues } from "../../interfaces/SearchFilterCategoryValues";
import { PartsAppPartOrdered } from "../../interfaces/PartsAppPart";

export interface SystemState {
  depots: Depot[];
  searchFilterCategoryValues: SearchFilterCategoryValues;
  recentParts: PartsAppPartOrdered[];
}

const defaultSearchFilterCategoryValues: SearchFilterCategoryValues = {
  prefixes: [],
  classCodes: [],
  productGroups: [],
};

const initialState: SystemState = {
  depots: [],
  searchFilterCategoryValues: defaultSearchFilterCategoryValues,
  recentParts: []
};

export const systemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    setDepots: (state, action: PayloadAction<{ data: Depot[] }>) => {
      state.depots = action.payload.data;
    },
    setSearchFilterCategoryValues: (state, action: PayloadAction<SearchFilterCategoryValues>) => {
      state.searchFilterCategoryValues = action.payload;
    },
    setRecentParts: (state, action: PayloadAction<PartsAppPartOrdered[]>) => {
      state.recentParts = action.payload.map(e => {
        e.depotString = "";
        return e;
      });
    },
    clearSystem: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setDepots, setSearchFilterCategoryValues, setRecentParts, clearSystem } = systemSlice.actions;

export default systemSlice.reducer;
