import PartsAppPurchaseOrder, {
  DeferralStatus,
  PartsAppPurchaseOrderLine,
} from "../../../interfaces/Po";
import React, { useEffect, useState } from "react";
import AbstractButton from "../../components/AbstractButton";
import ForegroundColorSet from "../../../utilities/enums/ForegroundColorSet";
import BackgroundColorSet from "../../../utilities/enums/BackgroundColorSet";
import { ReactComponent as CancelIcon } from "../../../assets/icons/backspace-20-regular.svg";
import { ReactComponent as OkayIcon } from "../../../assets/icons/checkbox-checked-regular.svg";
import ReceiveDoneStatic from "../../../assets/box_close_endframe.png";
import ReceiveProgress from "../../../assets/box_loop_lowres.gif";
import ReceiveDone from "../../../assets/box_close_lowres.gif";
import edit from "../../../assets/icons/edit-bluepencil.svg";
import { apiFetch } from "../../../utilities/AuthenticatedFetch";
import { useNavigate } from "react-router-dom";
import PartsAppPurchaseOrderLock from "../../../interfaces/PoLock";
import { Slide, ToastContainer, toast } from "react-toastify";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";

type ReceiveProcessStatus =
  | "not-started"
  | "in-progress"
  | "done"
  | "error"
  | "final";

const FinishPage = (props: {
  order: PartsAppPurchaseOrder;
  showFinishPage: boolean;
  editIconClicked(line: PartsAppPurchaseOrderLine): void;
  depotId: string;
  userId: string;
  onCancel(): void;
}) => {
  const navigate = useNavigate();
  const userEmail 
   = useSelector((state: RootState) => state.root.auth.email);

  const [receiveStatus, setReceiveStatus] =
    useState<ReceiveProcessStatus>("not-started");
  const [receiveSubStatus, setReceiveSubStatus] =
    useState<DeferralStatus>("NotStarted");

  const [transferGoods, setTransferGoods] = useState<boolean>(false);

  const [canSave, setCanSave] = useState<boolean>(false);
  let intervalId: NodeJS.Timeout | null = null;

  useEffect(() => {
    if (props.showFinishPage === false) {
      setCanSave(false);
      return;
    }
    props.order.lines.map((e) => {
      const variance =
        e.flattenedSingleLine == undefined
          ? 0
          : e.flattenedSingleLine?.received || 0;
      if (variance > 0) {
        setCanSave(true);
      }
    });
    setTransferGoods(
        props.order.lines.filter( e =>
         !(e.customerStop || e.customerCreditLimit) &&
            e.depotLines.filter((f) => f.totalAllocation > 0).length > 0 &&
            (e.flattenedSingleLine?.received ?? 0) > 0
        ).length > 0 
    );
  }, [props.showFinishPage]);

  const savePurchaseOrder = () => {
    setReceiveStatus("in-progress");

    const order = props.order;
    order.contactEmail = userEmail;
    const lines = props.order.lines.map((e) => {
      const depotLineIndex = e.depotLines.findIndex(
        (f) => f.depot == props.depotId
      );
      e.depotLines[depotLineIndex].binLocation =
        e.flattenedSingleLine == undefined
          ? ""
          : e.flattenedSingleLine.binLocation;
      e.depotLines[depotLineIndex].depot =
        e.flattenedSingleLine == undefined ? "" : e.flattenedSingleLine.depot;
      e.depotLines[depotLineIndex].ordered =
        e.flattenedSingleLine == undefined ? 0 : e.flattenedSingleLine.ordered;
      e.depotLines[depotLineIndex].delivered =
        e.flattenedSingleLine == undefined
          ? 0
          : e.flattenedSingleLine.delivered;
      e.depotLines[depotLineIndex].received =
        e.flattenedSingleLine == undefined ? 0 : e.flattenedSingleLine.received;
      e.unitCost = (e.unitCost ?? 0) * 100;
      e.netCost = (e.netCost ?? 0) * 100;
      return e;
    });

    order.lines = lines.sort(
      (a, b) => (a.lineNumber || 0) - (b.lineNumber || 0)
    );

    order.transferGoods = transferGoods;

    apiFetch<PartsAppPurchaseOrder>(
      "/partsapp/receive/CreateOrderDeferralHeader",
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(order),
      }
    )
      .then((v) => {
        apiFetch<PartsAppPurchaseOrder>("/partsapp/receive/ExecuteGRN", {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(v),
        });
        return v;
      })
      .then((v) => {
        intervalId = setInterval(
          () => pollReceiveCompletion(v.deferralID),
          2000
        );
      })
      .catch((err) => {
        let message: string;
        if (err instanceof Error) {
          message = err.message;
        } else {
          message = String(err);
        }

        setReceiveStatus("not-started");

        toast.error("Unable to process GRN: " + message, {
          position: "bottom-center",
          autoClose: 3000,
          theme: "colored",
          transition: Slide,
        });
      });
  };

  const pollReceiveCompletion = (deferralId: number) => {
    apiFetch<PartsAppPurchaseOrder>(
      "/partsapp/receive/LoadOrderStatus/" + deferralId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((value) => {
      setReceiveSubStatus(value.deferralStatus);
      if (value.errorMessage && value.errorMessage != "") {
        toast.error("Unable to process GRN: " + value.errorMessage, {
          position: "bottom-center",
          autoClose: 3000,
          theme: "colored",
          transition: Slide,
        });
        if (intervalId) {
          clearInterval(intervalId);
          intervalId = null;
        }
        setReceiveStatus("error");
        props.order.lines.map((e) => {
          e.unitCost = (e.unitCost ?? 0) / 100;
          e.netCost = (e.netCost ?? 0) / 100;
          return e;
        });
      } else if (value.deferralStatus == "IsDone") {
        if (intervalId) {
          clearInterval(intervalId);
          intervalId = null;
        }
        setTimeout(() => {
          setReceiveStatus("done");
          setTimeout(() => setReceiveStatus("final"), 2500);
        }, 5000);
      }
    });

    return false;
  };

  function deletePurchaseLock() {
    apiFetch<PartsAppPurchaseOrderLock>(
      "/partsapp/receive/DeleteLockedPurchaseOrder/" + props.userId,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then(() => {
      navigate("/receive");
    });
  }

  function renderSubStatus() {
    switch (receiveSubStatus) {
      case "Started":
        return "Validating Order...";
      case "InProgress":
        return "Processing...";
      default:
        break;
    }
  }

  return (
    <div
      className={
        "finish-page " + (props.showFinishPage ? "modal-open" : "modal-closed")
      }
    >
      {receiveStatus == "final" ||
      receiveStatus == "in-progress" ||
      receiveStatus == "done" ? (
        <div id="main-menu-modal-mask" className="modal-mask"></div>
      ) : null}
      <div
        id="main-menu-modal-mask"
        onClick={props.onCancel}
        className="modal-mask"
      ></div>
      <div className="modal-page finish-page">
        {receiveStatus == "done" || receiveStatus == "final" ? (
          <>
            <div className="info-page-header">
              <p id="finish-page-title">GRN successfully received</p>
            </div>
            <img
              className="receive-loading-gif"
              src={receiveStatus == "done" ? ReceiveDone : ReceiveDoneStatic}
            />
            <span className="modal-button-span">
              <AbstractButton
                id="depot-okay-button"
                text="Back"
                specialClass="modal-button"
                icon={<OkayIcon width="100%" height="100%" />}
                disabled={false}
                activeColor={ForegroundColorSet.Green}
                background={BackgroundColorSet.Black1}
                actionCallback={() => {
                  deletePurchaseLock();
                }}
              />
            </span>
          </>
        ) : receiveStatus == "in-progress" ? (
          <>
            <div className="info-page-header">
              <p id="finish-page-title">Processing GRN</p>
            </div>
            <img className="receive-loading-gif" src={ReceiveProgress} />
            <span className="info-page-header">{renderSubStatus()}</span>
            <span className="modal-button-span">
              <AbstractButton
                id="depot-okay-button"
                text="Back"
                specialClass="modal-button"
                icon={<OkayIcon width="100%" height="100%" />}
                disabled={true}
                activeColor={ForegroundColorSet.Green}
                background={BackgroundColorSet.Black1}
                actionCallback={() => {
                  deletePurchaseLock();
                }}
              />
            </span>
          </>
        ) : (
          <>
            <div className="info-page-header">
              <p id="finish-page-title">GRN</p>
            </div>
            <hr className="modal-header-underline"></hr>
            <div className="info-page-section">
              All quantities received will be updated on the PO in Gold
            </div>
            <div className="hr-container">
              <span className="hr-label">Variances</span>
              <hr className="hr-line" />
            </div>
            <div>
              <div className="scrollable-line-list">
                <div className="finish-grn-grid-container">
                  <div className="finish-grn-grid-header finish-grn-grid-item">
                    Part
                  </div>
                  <div className="finish-grn-grid-header finish-grn-grid-item">
                    Var
                  </div>
                  <div className="finish-grn-grid-header finish-grn-grid-item">
                    Edit
                  </div>
                  {props.order.lines.map((e, i) => {
                    const variance =
                      e.flattenedSingleLine == undefined
                        ? 0
                        : e.flattenedSingleLine?.received || 0;
                    const varianceWithSign =
                      variance >= 0 ? "+" + variance : "-" + variance;
                    const finalVariance =
                      variance == 0 ? "-" : varianceWithSign;
                    const key = i + "-" + e.partNumber;
                    return (
                      <React.Fragment key={key}>
                        <div className="finish-grn-grid-header finish-grn-grid-item">
                          {e.partNumber}
                        </div>
                        <div
                          className={
                            "finish-grn-grid-header finish-grn-grid-item " +
                            (finalVariance === "-"
                              ? "black4-foreground"
                              : " green-foreground")
                          }
                        >
                          {finalVariance}
                        </div>
                        <div className="finish-grn-grid-header finish-grn-grid-item">
                          <img
                            src={edit}
                            onClick={() => props.editIconClicked(e)}
                            className="finish-edit-pencil"
                            alt=""
                          />
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
            {props.order.lines.filter(
              (e) =>
                (e.customerStop || e.customerCreditLimit) &&
                e.depotLines.filter((f) => f.delivered != f.ordered).length > 0
            ).length > 0 ? (
              <div className="info-page-section ">
                A customer on this order is on Total Stop
                <br />
                or over their Credit Limit.
                <br />
                To transfer lines on this order, please use Gold.
              </div>
            ) : (
              <span
                className={
                  "checkbox-label " +
                  (props.order.hasAllocations ? null : "black4-foreground")
                }
              >
                Transfer for customer requirements
                <input
                  type="checkbox"
                  className="checkbox-style"
                  readOnly
                  checked={transferGoods}
                  disabled={
                    (props.order.lines.filter( e =>
                    !(e.customerStop || e.customerCreditLimit) &&
                        e.depotLines.filter((f) => f.totalAllocation > 0).length > 0 &&
                        (e.flattenedSingleLine?.received ?? 0) > 0
                    ).length == 0) || props.order.isIDT
                  }
                  data-disabled={
                    props.order.lines.filter( e =>
                    !(e.customerStop || e.customerCreditLimit) &&
                        e.depotLines.filter((f) => f.totalAllocation > 0).length > 0 &&
                        (e.flattenedSingleLine?.received ?? 0) > 0
                    ).length == 0 
                  }
                  onChange={() => setTransferGoods(!transferGoods)}
                ></input>
              </span>
            )}
            <span className="modal-button-span">
              <AbstractButton
                id="cancel-button"
                specialClass="modal-button"
                text="Cancel"
                icon={<CancelIcon width="100%" height="100%" />}
                disabled={false}
                activeColor={ForegroundColorSet.Red}
                actionCallback={() => props.onCancel()}
                background={BackgroundColorSet.Black1}
              />
              <AbstractButton
                id="depot-okay-button"
                text="Save"
                specialClass="modal-button"
                icon={<OkayIcon width="100%" height="100%" />}
                disabled={!canSave}
                activeColor={ForegroundColorSet.Green}
                background={BackgroundColorSet.Black1}
                actionCallback={() => {
                  savePurchaseOrder();
                }}
              />
            </span>
          </>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default FinishPage;
