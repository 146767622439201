import { useNavigate } from "react-router-dom";
import React from "react";
import "../Home.css";

interface HomeNavButtonProperties {
  id: string;
  text: string;
  route?: string; //button will be disabled if this is not set
  icon: React.ReactNode;
}

const HomeNavButton = (props: HomeNavButtonProperties) => {
  const id = props.id + "-menu-button";
  const navigate = useNavigate();

  function navigateTo() {
    if (props.route != undefined) {
      navigate(props.route);
    }
  }

  return (
    <button
      disabled={props.route == undefined}
      onClick={navigateTo}
      id={id}
      className="main-menu-button"
    >
      <div className="main-menu-button-content">
        <span className="main-menu-button-text">{props.text}</span>
        <span
          className="main-menu-button-icon-surround"
          data-disabled={props.route == undefined}
        >
          {props.icon}
        </span>
      </div>
    </button>
  );
};

export default HomeNavButton;
