import React, { useEffect, useState } from "react";
import "./SearchAndFilter.css";
import AbstractButton from "../../components/AbstractButton";
import { ReactComponent as CancelIcon } from "../../../assets/icons/backspace-20-regular.svg";
import { ReactComponent as OkayIcon } from "../../../assets/icons/checkbox-checked-sync-regular.svg";
import ForegroundColorSet from "../../../utilities/enums/ForegroundColorSet";
import BackgroundColorSet from "../../../utilities/enums/BackgroundColorSet";
import EditSortBy from "./EditSortBy";
import PartSearch, {
  Inclusivity,
  OrderDirection,
} from "../../../interfaces/PartSearch";
import FilterItem from "./FilterItem";
import EditPrefixes from "./EditPrefixes";
import EditClassCodes from "./EditClassCodes";
import EditProductGroups from "./EditProductGroups";
import InclusivitySelector from "./InclusivitySelector";

interface SearchAndFilterProps {
  searchData: PartSearch;
  visibilityClassName: string;
  onShowTrigger: number;
  onCancelCallback(): void;
  onOkayCallback(Data: PartSearch): void;
}

const SearchAndFilter = (props: SearchAndFilterProps) => {
  const [searchData, setSearchData] = useState(props.searchData);

  //Sort by Model pop section
  const [sortByMenuVisible, setSortByMenuVisible] = useState(false);
  const [prefixesMenuVisible, setPrefixesMenuVisible] = useState(false);
  const [productGroupsMenuVisible, setProductGroupsMenuVisible] =
    useState(false);
  const [classCodesMenuVisible, setClassCodesMenuVisible] = useState(false);

  const handleSubMenuBack = (search: PartSearch) => {
    setSearchData(search);
    setSortByMenuVisible(false);
    setClassCodesMenuVisible(false);
    setProductGroupsMenuVisible(false);
    setPrefixesMenuVisible(false);
  };

  const handleMemoChange = (val: Inclusivity) => {
    setSearchData({
      ...searchData,
      Memo: val,
    });
  };

  const handleSerialNumberChange = (val: Inclusivity) => {
    setSearchData({
      ...searchData,
      SerialNumbered: val,
    });
  };

  const cancelClicked = () => {
    setSearchData(props.searchData);
    props.onCancelCallback();
  };

  const okayClicked = () => {
    props.onOkayCallback(searchData);
  };

  const showOtherDepotsYesClicked = () => {
    setSearchData({
      ...searchData,
      ShowAllDepots: true,
    });
  };

  const showOtherDepotsNoClicked = () => {
    setSearchData({
      ...searchData,
      ShowAllDepots: false,
    });
  };

  useEffect(() => {
    if (props.onShowTrigger > 0) {
      if (document.getElementById("all-dep-yes")) {
        (document.getElementById("all-dep-yes") as HTMLInputElement).checked =
          searchData.ShowAllDepots;
      }
      if (document.getElementById("all-dep-no")) {
        (document.getElementById("all-dep-no") as HTMLInputElement).checked =
          !searchData.ShowAllDepots;
      }
      if (document.getElementById("Memo-only")) {
        (document.getElementById("Memo-only") as HTMLInputElement).checked =
          searchData.Memo == Inclusivity.Only;
      }
      if (document.getElementById("Memo-hide")) {
        (document.getElementById("Memo-hide") as HTMLInputElement).checked =
          searchData.Memo == Inclusivity.Hidden;
      }
      if (document.getElementById("Serial-only")) {
        (document.getElementById("Serial-only") as HTMLInputElement).checked =
          searchData.SerialNumbered == Inclusivity.Only;
      }
      if (document.getElementById("Serial-hide")) {
        (document.getElementById("Serial-hide") as HTMLInputElement).checked =
          searchData.SerialNumbered == Inclusivity.Hidden;
      }
    }
  }, [props.onShowTrigger]);

  return (
    <>
      <div id="search-and-filter-modal" className={props.visibilityClassName}>
        <div className={`${props.visibilityClassName} filter-modal-contents`}>
          <div className="flex-items">
            <label>
              <h1 className="sort-and-filter-header">Sort & Filter</h1>
            </label>
          </div>
          <div className="flex-items">
            <hr></hr>
          </div>
          <div className="filter-block-container">
            <div className="left-filter-items">
              <FilterItem
                label="Sort By"
                searchData={searchData}
                labelText={
                  searchData.SmartSearch
                    ? "SmartSearch"
                    : searchData.Order.split(/(?=[A-Z])/).join(" ") +
                      ", " +
                      (searchData.OrderDirection == OrderDirection.Ascending
                        ? "A-Z"
                        : "Z-A")
                }
                onClick={() => setSortByMenuVisible(true)}
              />

              <FilterItem
                label="Prefixes"
                searchData={searchData}
                labelText={searchData.Prefixes.join(", ")}
                onClick={() => setPrefixesMenuVisible(true)}
              />

              <FilterItem
                label="Product Grps"
                searchData={searchData}
                labelText={searchData.ProductGroups.join(", ")}
                onClick={() => setProductGroupsMenuVisible(true)}
              />

              <FilterItem
                label="Class Codes"
                searchData={searchData}
                labelText={searchData.ClassCodes.join(", ")}
                onClick={() => setClassCodesMenuVisible(true)}
              />
            </div>
            <br />
            <div className="right-filter-items">
              <table className="filter-option-table">
                <tbody>
                  <InclusivitySelector
                    label="Memo:"
                    callback={(i) => handleMemoChange(i)}
                  />
                  <InclusivitySelector
                    label="Serial #:"
                    callback={(i) => handleSerialNumberChange(i)}
                  />
                  <tr className="filter-option-row">
                    <td className="filter-option-label-cell">
                      Show All Depots:
                    </td>
                    <td className="filter-option-checkbox-cell">
                      <span className="checkbox-label">Yes</span>
                      <input
                        id="all-dep-yes"
                        type="radio"
                        className="checkbox-style"
                        name="showOnly"
                        defaultChecked
                        onClick={showOtherDepotsYesClicked}
                      ></input>
                    </td>
                    <td className="filter-option-checkbox-cell">
                      <span className="checkbox-label">&nbsp; No</span>
                      <input
                        id="all-dep-no"
                        type="radio"
                        className="checkbox-style-red"
                        name="showOnly"
                        onClick={showOtherDepotsNoClicked}
                      ></input>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <span className="filter-modal-button-span">
              <AbstractButton
                id="cancel-button"
                text="Cancel"
                icon={<CancelIcon width="100%" height="100%" />}
                disabled={false}
                activeColor={ForegroundColorSet.Red}
                background={BackgroundColorSet.Black1}
                actionCallback={cancelClicked}
                specialClass="modal-button"
              />
              <AbstractButton
                id="filter-okay-button"
                text="Apply"
                icon={<OkayIcon width="100%" height="100%" />}
                disabled={false}
                activeColor={ForegroundColorSet.Green}
                background={BackgroundColorSet.Black1}
                actionCallback={okayClicked}
                specialClass="modal-button"
              />
            </span>
          </div>
        </div>
        <EditSortBy
          visible={sortByMenuVisible}
          onCancelCallback={(search: PartSearch) => handleSubMenuBack(search)}
          searchData={searchData}
        />
        <EditPrefixes
          visible={prefixesMenuVisible}
          onCancelCallback={(search: PartSearch) => handleSubMenuBack(search)}
          searchData={searchData}
        />
        <EditClassCodes
          visible={classCodesMenuVisible}
          onCancelCallback={(search) => handleSubMenuBack(search)}
          searchData={searchData}
        />
        <EditProductGroups
          visible={productGroupsMenuVisible}
          onCancelCallback={(search: PartSearch) => handleSubMenuBack(search)}
          searchData={searchData}
        />
      </div>
    </>
  );
};

export default SearchAndFilter;
