import React, { ChangeEvent, useEffect, useState } from "react";
import Toggle from "react-toggle";
import { ReactComponent as CancelIcon } from "../../../assets/icons/backspace-20-regular.svg";
import { ReactComponent as OkayIcon } from "../../../assets/icons/checkbox-checked-regular.svg";
import { Preferences, defaultPrefs } from "../../../interfaces/Preferences";
import AbstractButton from "../../components/AbstractButton";
import ForegroundColorSet from "../../../utilities/enums/ForegroundColorSet";
import BackgroundColorSet from "../../../utilities/enums/BackgroundColorSet";

interface SettingsDialogueProps {
  visibilityClassName: string;
  currentPreferences: Preferences;
  onCancelCallback(): void;
  onOkayCallback(prefs: Preferences, successMessage: string): void;
}

const SettingsDialogue = (props: SettingsDialogueProps) => {
  const [preferences, setPreferences] = useState<Preferences>(defaultPrefs);

  const handleDeviceCameraToggle = (event: ChangeEvent<HTMLInputElement>) => {
    setPreferences({
      ...preferences,
      preferExternalScanner: event.target.checked,
    });
  };

  const handleOkayClick = () => {
    props.onOkayCallback(preferences, "successfully updated preferences");
  };

  useEffect(() => {
    setPreferences(props.currentPreferences);
  }, [props.currentPreferences.preferExternalScanner]);

  return (
    <div
      id="home-header-change-depot-modal"
      className={props.visibilityClassName}
    >
      <span className="modal-header-text"> Settings </span>
      <span className="modal-toggle-container">
        <span className="device-camera-toggle-label">
          Default to Camera for Barcodes:
        </span>
        <Toggle
          id="device-camera-toggle"
          icons={false}
          className="large-toggle"
          defaultChecked={preferences.preferExternalScanner}
          checked={preferences.preferExternalScanner}
          onChange={handleDeviceCameraToggle}
        />
      </span>
      <span className="modal-button-span">
        <AbstractButton
          id="cancel-button"
          specialClass="modal-button"
          text="Cancel"
          icon={<CancelIcon width="100%" height="100%" />}
          disabled={false}
          activeColor={ForegroundColorSet.Red}
          background={BackgroundColorSet.Black1}
          actionCallback={props.onCancelCallback}
        />
        <AbstractButton
          id="settings-okay-button"
          text="Change"
          specialClass="modal-button"
          icon={<OkayIcon width="100%" height="100%" />}
          disabled={false}
          activeColor={ForegroundColorSet.Green}
          background={BackgroundColorSet.Black1}
          actionCallback={handleOkayClick}
        />
      </span>
    </div>
  );
};

export default SettingsDialogue;
