enum BackgroundColorSet {
  Black1 = "black1-background",
  Black2 = "black2-background",
  Black3 = "black3-background",
  Black4 = "black4-background",
  Gold = "gold-background",
  Green = "green-background",
  Blue = "blue-background",
  Red = "red-background",
  White = "white-background",
}

export default BackgroundColorSet;
