import React, { useEffect, useState } from "react";
import {
  defaultPartOrdered,
  PartsAppPartOrdered,
} from "../../../interfaces/PartsAppPart";
import { apiFetch } from "../../../utilities/AuthenticatedFetch";
import TabList, { TabProps } from "../../components/TabList";
import "./PartDetails.css";
import PartDetailsBasicInfo from "./PartDetailsBasicInfo";
import PartDetailsDepotInfo from "./PartDetailsDepotInfo";
import PartDetailsGroupInfo from "./PartDetailsGroupInfo";
import { toast, Slide } from "react-toastify";
import LoadingMask from "../../../utilities/LoadingMask";
import loadingspinner from "../../../assets/spinning-loading.gif";
import BinLocationModal from "./UpdateBinLocationModal";
import { IbString } from "../../../interfaces/IbcosObjects";
import { BinLocationUpdate } from "../../../interfaces/BinUpdate";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { setRecentParts } from "../../../store/slices/systemSlice";
import Depot from "../../../interfaces/Depot";
import { Feature, PartsAppFeature } from "../../../store/slices/authSlice";

interface PartDetailProps {
  part: PartsAppPartOrdered;
  canSeeCosts: boolean;
  canChangeBinLocation: boolean;
  showBinTrigger: number;
  isUsingRecentParts: boolean;
  failedToLoadCallback(): void;
  hideOptionsMenu(): void;
  showOptionsMenu(): void;
  onUpdatePart(part: PartsAppPartOrdered): void;
  allDepots: Depot[];
  visible: boolean;
}

const PartDetails = (props: PartDetailProps) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [isViewingOffline, setIsViewingOffline] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [binLocationUpdating, setBinLocationUpdating] = useState(false);
  const [availableAtDepot, setAvailableAtDepot] = useState(true);
  const userPrefsId = useSelector((state: RootState) => state.root.auth.userPreferenceId);
  const canAddRecentParts = useSelector((state: RootState) => state.root.auth.features.filter((e: PartsAppFeature) => e.featureId == Feature.Receive).length > 0);
  const dispatch = useDispatch();

  const [thisPart, setThisPart] =
    useState<PartsAppPartOrdered>(defaultPartOrdered);

  const [changeBinVisible, setChangeBinVisible] = useState(false);

  const changeBinModalClasses = changeBinVisible
    ? "modal-open"
    : "modal-closed";

  const onCancelBinModal = () => {
    setChangeBinVisible(false);
  };

  const onOkBinModal = (newBin: string) => {
    updateBinLocation(newBin);
    setChangeBinVisible(false);
  };

  const tabList: TabProps[] = [
    {
      index: 0,
      label: "Part",
      callback: () => setSelectedTab(0),
      enabled: true
    },
    {
      index: 1,
      label: "Depot",
      callback: () => {availableAtDepot ? setSelectedTab(1) : null},
      enabled: availableAtDepot
    },
    {
      index: 2,
      label: "Group",
      callback: () => setSelectedTab(2),
      enabled: true
    },
  ];

  const updateBinLocation = async (newBin: string) => {
    setBinLocationUpdating(true);
    const requestData: BinLocationUpdate = {
      partNumber: thisPart.partNumber,
      depotId: thisPart.depot,
      bin: newBin.toUpperCase(),
    };
    const requestOptions: RequestInit = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestData),
    };
    // Send the request and wait for the response
    try {
      const response = await apiFetch<IbString>(
        "/partsapp/enquire/UpdateBinLocation",
        requestOptions
      );
      setThisPart({ ...thisPart, bin: response.content });
      toast.success("Bin location successfully updated", {
        position: "bottom-center",
        autoClose: 1500,
        theme: "colored",
        transition: Slide,
      });
    } catch (err) {
      toast.error("" + err, {
        position: "bottom-center",
        autoClose: 3000,
        theme: "colored",
        transition: Slide,
      });
    } finally {
      setBinLocationUpdating(false);
    }
  };
  
  const addToRecentParts = async (part: PartsAppPartOrdered | undefined) => {
    if(part === undefined || !canAddRecentParts || !part.existsAtDepot)
    {
      return;
    }
    const requestOptions: RequestInit = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({userId: userPrefsId, part: part, limit: 20}),
    };
    // Send the request and wait for the response
    try {
      const response = await apiFetch<PartsAppPartOrdered[]>(
        "/partsapp/prefs/recent-part",
        requestOptions
      );
      dispatch(setRecentParts(response));
    } catch (err) {
      //Fail silently!
    }
  };

  const fetchData = async (part: PartsAppPartOrdered | null) => {
    setAvailableAtDepot(true);
    const partToCheck = part ?? props.part;
    if (!partToCheck.existsAtDepot)
    {
      setAvailableAtDepot(false);
      const firstDepotShortName : string = partToCheck.wholeGroupInfo ? partToCheck.wholeGroupInfo[0].depotName : "";
      partToCheck.depot = props.allDepots.filter(e => e.shortName == firstDepotShortName)[0].id;
    }
    setDetailsLoading(true);
    const requestOptions: RequestInit = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(partToCheck),
    };
    // Send the request and wait for the response
    try {
      const response = await apiFetch<PartsAppPartOrdered>(
        "/partsapp/enquire/LoadAdditionalPartInformation",
        requestOptions
      );
      response.rangeStart = props.part.rangeStart;
      response.wholeGroupInfo = props.part.wholeGroupInfo;
      response.depotString = props.part.depotString;
      response.existsAtDepot = props.part.existsAtDepot;
      setThisPart(response);
      setIsViewingOffline(false);
      if(props.part.existsAtDepot)
        props.showOptionsMenu();
      else
        props.hideOptionsMenu();
      return response;
    } catch (err) {
      if(!props.isUsingRecentParts) {
        toast.error("Error Loading Part Details:  " + err, {
          position: "bottom-center",
          autoClose: 3000,
          theme: "colored",
          transition: Slide,
        });
        props.failedToLoadCallback();
      } else {
        toast.error("Error Loading Part Details, you are viewing a cached version of the part. The version of the part you are viewing may be out of date." , {
          position: "bottom-center",
          autoClose: 8000,
          theme: "colored",
          transition: Slide,
        });
        setIsViewingOffline(true);
        setThisPart(props.part);
        props.hideOptionsMenu();
      }
    } finally {
      setSelectedTab(0);
      setDetailsLoading(false);
    }
  };

  useEffect(() => {
    if (props.part && props.part.partNumber != "" && props.visible) {
      fetchData(null)
      .then(addToRecentParts);
    }
  }, [props.part, props.visible]);

  useEffect(() => {
    if (props.showBinTrigger > 0)
      setChangeBinVisible(true);
  }, [props.showBinTrigger])

  useEffect(() => {
    props.onUpdatePart(thisPart);
  }, [thisPart])

  const tabRender = () => {
    switch (selectedTab) {
      case 0:
        return (
          <PartDetailsBasicInfo
            availableAtDepot={availableAtDepot}
            canSeeCosts={props.canSeeCosts}
            canChangeBinLocation={props.canChangeBinLocation}
            binLocationUpdating={binLocationUpdating}
            onEditBinClicked={() => setChangeBinVisible(true)}
            isViewingOffline={isViewingOffline}
            part={thisPart}
            otherPartClicked={function (partNumber: string): void {
              const newPart = { ...thisPart, partNumber: partNumber };
              fetchData(newPart).then(addToRecentParts);
            }}
          />
        );
      case 1:
        return (
          <PartDetailsDepotInfo
            canChangeBinLocation={props.canChangeBinLocation}
            isViewingOffline={isViewingOffline}
            binLocationUpdating={binLocationUpdating}
            onEditBinClicked={() => setChangeBinVisible(true)}
            part={thisPart}
          />
        );
      case 2:
        return <PartDetailsGroupInfo part={thisPart} />;
    }
  };

  return (
    <>
      <div
        id="part-details"
        className={(process.env.REACT_APP_BUILD_ENV?.toUpperCase() != "PLA" ? `part-details-container ` : `part-details-container-pla `) + (
          props.visible ? "slide-in" : "slide-out")
        }
      >
        {detailsLoading ? (
          <LoadingMask text="Loading Part Details..." icon={loadingspinner} />
        ) : (
          <>
            <TabList tabs={tabList} twoOptions={false} isDisabled={false} /> {tabRender()}
          </>
        )}
      </div>
      {changeBinVisible ? (
        <div
          id="change-depot-modal-mask"
          onClick={onCancelBinModal}
          className="modal-mask"
        ></div>
      ) : null}
      <BinLocationModal
        visible={changeBinVisible}
        part={thisPart}
        visibilityClassName={changeBinModalClasses}
        onCancelCallback={onCancelBinModal}
        onOkayCallback={onOkBinModal}
      />
    </>
  );
};

export default PartDetails;
