import { FlattenedPoLine } from "../../../interfaces/Po";
import { ReactComponent as PencilIcon } from "../../../assets/icons/edit-bluepencil.svg";
import { ReactComponent as CancelIcon } from "../../../assets/icons/backspace-20-regular.svg";
import { ReactComponent as OkayIcon } from "../../../assets/icons/checkbox-checked-sync-regular.svg";
import React, { useState, useEffect } from "react";

const InfoPage = (props: {
  lineForDepot: FlattenedPoLine | null;
  showInfoPage: boolean;
  updateCost: (lineForDepot: FlattenedPoLine) => void;
  toggleInfoPage: () => void;
}) => {
  const { lineForDepot } = props;

  interface Surcharges {
    surDisc1?: number;
    surDisc2?: number;
    surDisc3?: number;
    surDisc4?: number;
  }

  const surcharges: Surcharges = {
    surDisc1: lineForDepot?.surDisc1,
    surDisc2: lineForDepot?.surDisc2,
    surDisc3: lineForDepot?.surDisc3,
    surDisc4: lineForDepot?.surDisc4,
  };

  const [lineReceived, setLineReceived] = useState(false);
  const [prevCosts, setPrevCosts] = useState({
    unit: lineForDepot?.unitCost,
    nett: lineForDepot?.netCost,
  });

  const formatCurrency = (value: number): string => {
    if (value !== undefined) {
      return value
        .toLocaleString("en-US", {
          style: "currency",
          currency: "GBP",
        })
        .replace("£", "")
        .replace(",", "");
    }
    return "";
  };

  const [unitCostInput, setUnitCostInput] = useState<string>("");

  const [nettCostInput, setNettCostInput] = useState<string>("");

  useEffect(() => {
    if (lineForDepot?.unitCost && lineForDepot.netCost) {
      setUnitCostInput(formatCurrency(lineForDepot?.unitCost));
      setNettCostInput(formatCurrency(lineForDepot?.netCost));
    } else {
      setUnitCostInput(formatCurrency(0));
      setNettCostInput(formatCurrency(0));
    }
    setLineReceived((lineForDepot?.delivered ?? 0) > 0);
  }, [lineForDepot]);

  const fillCosts = () => {
    if (lineForDepot?.unitCost && lineForDepot.netCost) {
      setUnitCostInput(formatCurrency(lineForDepot.unitCost));
      setNettCostInput(formatCurrency(lineForDepot.netCost));
    }
  };

  useEffect(() => {
    fillCosts();
    setPrevCosts({ nett: lineForDepot?.netCost, unit: lineForDepot?.unitCost });
  }, [lineForDepot]);

  const validateInput = (
    inputValue: string,
    setInput: (value: string) => void
  ) => {
    const cleanedValue = inputValue.replace(/[^0-9,.]/g, "");
    setInput(cleanedValue);
  };

  const applySurcharges = (value: number) => {
    let retVal = value;
    for (let i = 1; i <= 4; i++) {
      const surcharge = surcharges[`surDisc${i}` as keyof Surcharges];
      const isMinus = surcharge?.toString().includes("-");
      if (surcharge) {
        const diff =
          retVal *
          (isMinus
            ? eval(surcharge.toString().replace("-", "")) / 10000
            : surcharge / 10000);
        // retval * (surcharge)
        if (isMinus) retVal = Math.round((retVal - diff) * 100) / 100;
        else retVal = Math.round((retVal + diff) * 100) / 100;
        // *100)/100 is used to get 2 decimal points
      }
    }
    return retVal;
  };

  const formatInput = (inputValue: string, costType: string) => {
    const numericValue = parseFloat(inputValue.replace(/[^0-9.]/g, ""));
    if (!isNaN(numericValue)) {
      const formattedValue = formatCurrency(numericValue);

      if (lineForDepot) {
        switch (costType) {
          case "unit":
            setUnitCostInput(formattedValue);
            setNettCostInput(formatCurrency(applySurcharges(numericValue)));
            break;
          case "nett":
            setNettCostInput(formattedValue);
            break;
          default:
            break;
        }
        props.updateCost(lineForDepot);
      }
    }
  };

  const saveCostsEdit = () => {
    if (lineForDepot) {
      lineForDepot.unitCost = parseFloat(unitCostInput.replace(/[^0-9.]/g, ""));
      lineForDepot.netCost = parseFloat(nettCostInput.replace(/[^0-9.]/g, ""));
      props.updateCost(lineForDepot);
      setPrevCosts({ nett: lineForDepot.netCost, unit: lineForDepot.unitCost });
    }
    props.toggleInfoPage();
  };

  const cancelCostEdit = () => {
    if (prevCosts["unit"] !== undefined && prevCosts["nett"] !== undefined) {
      setUnitCostInput(formatCurrency(prevCosts["unit"]));
      setNettCostInput(formatCurrency(prevCosts["nett"]));
    }
    props.toggleInfoPage();
  };

  return (
    <div
      id={"info-page"}
      className={
        "info-page " + (props.showInfoPage ? "modal-open" : "modal-closed")
      }
    >
      <div
        id="info-page-modal-mask"
        onClick={() => {
          props.toggleInfoPage();
        }}
        className="modal-mask"
      ></div>
      <div className="modal-page">
        <div className="modal-page-scrollable info-page-scrollable">
          <div className="info-page-header">
            <p id="info-page-title">{lineForDepot?.partNumber}</p>
          </div>
          <div>
            <p id="info-page-description">{lineForDepot?.partDescription}</p>
            <p id="info-page-received">
              Received: {lineForDepot?.delivered}/{lineForDepot?.ordered}
            </p>
          </div>
          <div className="info-page-section">
            <p className="info-page-label">Location</p>
            <p className="info-page-bordered-value" id="info-page-location">
              {lineForDepot?.location == undefined ||
              lineForDepot?.location == ""
                ? "\u00A0"
                : lineForDepot?.location}
            </p>
            <p className="info-page-label">Cost Price</p>
            <div className="info-page-bordered-value info-page-input-container">
              {!lineReceived ? (
                <PencilIcon className="pencil-icon info-page-pencil" />
              ) : null}
              <input
                type={!lineReceived ? "number" : "text"}
                className="info-page-bordered-value info-page-cost-input"
                name="nett-cost-input"
                id="info-page-unit-cost"
                value={unitCostInput}
                onInput={(e) => {
                  const inputValue = (e.target as HTMLInputElement).value;
                  validateInput(inputValue, setUnitCostInput);
                }}
                onBlur={() => {
                  formatInput(unitCostInput, "unit");
                }}
                disabled={lineReceived}
              />
            </div>
            <p className="info-page-label" id="info-page-net-cost-label">
              Nett Cost
            </p>
            <div className="info-page-bordered-value info-page-input-container">
              {!lineReceived ? (
                <PencilIcon className="pencil-icon info-page-pencil" />
              ) : null}
              <input
                type={!lineReceived ? "number" : "text"}
                className="info-page-bordered-value info-page-cost-input"
                name="nett-cost-input"
                id="info-page-net-cost"
                value={nettCostInput}
                onInput={(e) => {
                  const inputValue = (e.target as HTMLInputElement).value;
                  validateInput(inputValue, setNettCostInput);
                }}
                onBlur={() => {
                  formatInput(nettCostInput, "nett");
                }}
                disabled={lineReceived}
              />
            </div>
          </div>
          {lineReceived ? (
            <h5 className="red-foreground info-page-message">
              {" "}
              Goods Received/Invoiced and Accrual Posted - Values cannot be
              changed{" "}
            </h5>
          ) : null}
          <div className="info-page-surcharge-section">
            <p className="info-page-label">Discount</p>
            <div className="info-page-bordered-value info-page-input-container">
              <p
                className="info-page-bordered-value info-page-cost-input"
                id="info-page-discount"
              >
                {lineForDepot?.discount
                  ? (lineForDepot?.discount / 100).toFixed(2) +
                    "%"
                  : "0%"}
              </p>
            </div>
          </div>
          <div className="info-page-surcharge-section">
            <p className="info-page-label">Surcharges</p>
            <div className="info-page-surcharge-grid">
              {[1, 2, 3, 4].map((index) => (
                <div key={index} className="surcharge-item">
                  <p className="info-page-surchage-label">{index}</p>
                  <p
                    className="info-page-bordered-value"
                    id={`info-page-surcharge-${index}`}
                  >
                    {Math.floor(
                      surcharges[`surDisc${index}` as keyof Surcharges] || 0
                    ) / 100}
                    %
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div
            className="info-page-section info-page-cost-buttons"
            id="cost-control-buttons"
          >
            <button
              className="info-page-bordered-value info-page-cost-button info-page-cancel-button"
              onClick={cancelCostEdit}
            >
              <CancelIcon className="info-page-cancel-icon" />
              {lineReceived ? "Back" : "Cancel Edit"}
            </button>
            {!lineReceived ? (
              <button
                className="info-page-bordered-value info-page-cost-button info-page-save-button"
                onClick={saveCostsEdit}
              >
                <OkayIcon className="info-page-save-icon" />
                Save Cost
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoPage;
