import { createBrowserHistory } from "history";
import { store } from "../store/store";
import { isNullOrUndefined } from "./Tools";

interface ApiResponse<T> {
  data: T;
  message: string;
}

const checkIfOnline = async () => {
  return await fetch("https://www.google.com", {
    method: "GET",
    mode: "no-cors",
  })
    .then(() => true)
    .catch((err) => {
      console.log(err);
      return false;
    });
};

function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export async function apiFetch<T>(
  url: string,
  options: RequestInit
): Promise<T> {
  const baseUrl = localStorage.getItem("url");
  const token = store.getState().root.auth.token;

  if (!baseUrl) {
    throw new Error("Base URL not found in local storage.");
  }

  if (token) {
    options.headers = {
      ...options.headers,
      Authorization: token,
    };
  }

  const fullUrl = `${baseUrl}${url}`;

  try {
    const response = await fetch(fullUrl, options);

    if (!response.ok) {
      if (response.status === 401) {
        handleUnauthorized();
      }
      const userId: string = store.getState().root.auth.id;
      if (isNullOrUndefined(userId) || userId === "")
      {
        handleUnauthorized();
      }
      if (response.status === 404) {
        throw new Error("Endpoint Unavailable");
      }
      else {
        // Handle other non-ok statuses and propagate the error message from the API
        const errorData: ApiResponse<T> = await response.json();
        throw new Error(errorData.message || "API Error");
      }
    }

    const responseData: ApiResponse<T> = await response.json();
    return responseData.data;
  } catch (err : unknown) {
    let message = err;
    
    if (err instanceof Error)
    {
      message = err.message;
    }

    if (
      isString(message) && (
      message.includes("Failed to fetch") ||
      message.includes("Load failed"))
    )
      if (await checkIfOnline())
        throw new Error(
          "Incorrect URL or service offline. Please check the URL and try again."
        );
      else throw new Error("You appear to be offline.");
    else if (
      isString(message) && ( 
      message.includes("Unexpected token") ||
      message.includes("string did not match the expected pattern"))
    )
      throw new Error("URL format invalid");
    else if (isString(message))
      throw new Error(message);
    else throw err;
  }
}

const history = createBrowserHistory();

function handleUnauthorized() {
  localStorage.setItem("FORCED_OUT", "true");
  history.push("/login");
  window.location.reload();
}
