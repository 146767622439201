import React from "react";
import AbstractButton from "../../components/AbstractButton";
import ForegroundColorSet from "../../../utilities/enums/ForegroundColorSet";
import BackgroundColorSet from "../../../utilities/enums/BackgroundColorSet";
import { ReactComponent as FinishIcon } from "../../../assets/icons/checkbox-checked-regular.svg";

const AllocationWarning = (props: {
  showAllocationsWarning: boolean; 
  onCancel(): void;
}) => {

  return (
    <div
      className={
        "finish-page " + (props.showAllocationsWarning ? "modal-open" : "modal-closed")
      }
    >
      <div id="main-menu-modal-mask" className="modal-mask"></div>
      <div className="modal-page finish-page">
            <div className="info-page-header">
              <p id="finish-page-title">Warning: Auto Allocations</p>
            </div>
            <hr className="modal-header-underline"></hr>
            <div className="info-page-section">
              You have received an insufficient quantity to satisfy the multiple POS/WSJ to which this line has been allocated.
            </div>
            <div className="info-page-section">
              Parts App will allocate parts according to the default behavior in gold.
            </div>
            <div className="info-page-section">
              For alternative allocation options, please use Gold.
            </div>
            <span className="modal-button-span">
              <AbstractButton
                id="cancel-button"
                specialClass="modal-button"
                text="Okay"
                icon={<FinishIcon width="100%" height="100%" />}
                disabled={false}
                activeColor={ForegroundColorSet.Blue}
                actionCallback={() => props.onCancel()}
                background={BackgroundColorSet.Black1}
              />
             
            </span>
              
      </div>
    </div>
  );
};

export default AllocationWarning;
