import React, { useState, useEffect, useRef } from "react";
import loading from "../../assets/spinning-loading.gif";
import { ListItem } from "../../interfaces/ScrollableList";
import "./PartsList.css";

interface ScrollableListProps<T extends ListItem> {
  fetchMoreResults(): Promise<T[]>;
  renderItem: (item: T) => React.ReactNode;
  initialItems: T[];
  isFetching: boolean;
  loadsLazily: boolean;
}

const ScrollableCardList = <T extends ListItem>(
  props: ScrollableListProps<T>
) => {
  const [listItems, setListItems] = useState(props.initialItems);
  const scrollableDiv = document.getElementById(
    "scrollable-list"
  ) as HTMLElement;
  let isFetchingMore = false;

  useEffect(() => {
    if (scrollableDiv) {
      const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = scrollableDiv;
        if (scrollTop > (scrollHeight - clientHeight) * 0.9) {
          fetchMoreListItems();
        }
      };
      scrollableDiv.addEventListener("scroll", handleScroll);
      return () => scrollableDiv.removeEventListener("scroll", handleScroll);
    }
  }, [fetchMoreListItems]);

  const listItemsRef = useRef(listItems);

  useEffect(() => {
    listItemsRef.current = listItems;
  }, [listItems]);

  useEffect(() => {
    setListItems(props.initialItems);
  }, [props.initialItems]);

  function fetchMoreListItems() {
    if (props.loadsLazily && !isFetchingMore) {
      isFetchingMore = true;
      const response = props.fetchMoreResults();
      response.then((e) => {
        isFetchingMore = false;
        return setListItems((current: T[]) => {
          return current.concat(e).sort((a, b) => {
            if (a.rangeStart == b.rangeStart) {
              return 0;
            }
            return a.rangeStart < b.rangeStart ? -1 : 1;
          });
        });
      });
    }
  }

  return (
    <>
      <div id="scrollable-list" className="parts-list-container">
        {listItems.map((listItem: T) => props.renderItem(listItem))}

        <div className="fetching-items-container">
          {props.isFetching && "Fetching more list items..." && (
            <img
              src={loading}
              className="fetching-items-logo"
              alt="Loading"
            ></img>
          )}
        </div>
      </div>
    </>
  );
};

export default ScrollableCardList;
