import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setAuth,
  clearAuth,
  PartsAppFeature,
  Feature,
} from "../../store/slices/authSlice";
import LoadingSpinner from "../../utilities/LoadingSpinner";
import {
  setDepots,
  clearSystem,
  setSearchFilterCategoryValues,
  setRecentParts,
} from "../../store/slices/systemSlice";
import ibcos_logo from "../../assets/logo.svg";
import ibcos_dev_logo from "../../assets/dev_logo.svg";
import ibcos_test_logo from "../../assets/test_logo.svg";
import "./Login.css";

const USER_NAME = "username";
const URL = "url";
const SESSION_EXPIRED = "Session Expired";

const userAgent = navigator.userAgent.toLowerCase();
const isSafari =
  userAgent.indexOf("safari") != -1 && userAgent.indexOf("chrome") == -1;
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appVersionNumber = "2.1.48";

  const [envBanner, setEnvBanner] = useState("");

  useEffect(() => {
    switch (process.env.REACT_APP_BUILD_ENV?.toUpperCase()) {
      case "PRODUCTION":
        setEnvBanner(ibcos_logo);
        break;
      case "DEVELOPMENT":
        setEnvBanner(ibcos_dev_logo);
        break;
      case "TESTING":
        setEnvBanner(ibcos_test_logo);
        break;
      default:
        setEnvBanner(ibcos_logo);
        break;
    }
  }, []);

  const [username, setUsername] = useState(() => {
    const username = localStorage.getItem(USER_NAME);
    return username || "";
  });

  const [url, setUrl] = useState(() => {
    const url = localStorage.getItem(URL);
    return url || "";
  });

  const [password, setPassword] = useState("");
  const [loginResponseMessage, setLoginResponseMessage] = useState(
    localStorage.getItem("FORCED_OUT") === "true" ? SESSION_EXPIRED : ""
  );
  const [isTryingToLogIn, setIsTryingToLogIn] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    dispatch(clearAuth());
    dispatch(clearSystem());
    localStorage.setItem("FORCED_OUT", "false");
  }, []);

  const changeUsername = (event: { target: { value: string } }) => {
    setUsername(event.target.value);
  };
  const changePassword = (event: { target: { value: string } }) => {
    setPassword(event.target.value);
    if (loginResponseMessage == SESSION_EXPIRED) {
      setLoginResponseMessage("");
    }
  };
  const changeUrl = (event: { target: { value: string } }) => {
    setUrl(event.target.value);
  };

  const checkIfOnline = async () => {
    return await fetch("https://www.google.com", {
      method: "GET",
      mode: "no-cors",
    })
      .then(() => true)
      .catch(() => {
        return false;
      });
  };

  const getAllDepots = async (token: string) => {
    return await fetch(url + "/partsapp/auth/depots", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          const responseObj = await response.text();
          return Promise.reject(responseObj);
        }
      })
      .then((depotData) => {
        dispatch(setDepots(depotData));
        return null;
      })
      .catch((err) => {
        console.log(err);
        setLoginResponseMessage("Unable to get depot information");
        return err;
      });
  };

  const getSearchFilterCategoryValues = async (token: string) => {
    return await fetch(
      url + "/partsapp/enquire/search-filter-category-values",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    )
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          const responseObj = await response.text();
          return Promise.reject(responseObj);
        }
      })
      .then((data) => {
        dispatch(setSearchFilterCategoryValues(data));
        return null;
      })
      .catch((err) => {
        console.log(err);
        setLoginResponseMessage("Unable to get Search Filter Category Values");
        return err;
      });
  };

  const getRecentParts = async (
    token: string,
    userPrefsId: number,
    features: PartsAppFeature[]
  ) => {
    if (features.filter((e) => e.featureId == Feature.Receive).length == 0)
      return;
    return await fetch(url + "/partsapp/auth/recent-parts/" + userPrefsId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          const responseObj = await response.text();
          return Promise.reject(responseObj);
        }
      })
      .then((data) => {
        dispatch(setRecentParts(data.data));
        return null;
      })
      .catch((err) => {
        console.log(err);
        setLoginResponseMessage("Unable to get Recent Parts ");
        return err;
      });
  };

  const handleSubmit = (event: { preventDefault: () => void }) => {
    setIsTryingToLogIn(true);

    if (
      !(
        url.toLowerCase().includes("http://") ||
        url.toLowerCase().includes("https://")
      )
    ) {
      setLoginResponseMessage(
        "URL format invalid. Please add 'https://' to the start of the URL."
      );
      setIsTryingToLogIn(false);
      return;
    }

    fetch(url + "/partsapp/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
        applicationId: process.env.REACT_APP_BUILD_ENV?.toUpperCase() != "PLA" ? "4f579c72-d997-48b5-b668-355d45a08308" : "c26d0eca-2473-447e-895d-b47788ca4fec",
        isMobile: true,
      }),
    })
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 403) {
            const loginResponse = process.env.REACT_APP_BUILD_ENV?.toUpperCase() != "PLA" ? "Invalid Parts App License" : "Invalid Parts Lookup License";
            setLoginResponseMessage(loginResponse);
            return Promise.reject();
          }
          const responseObj = await response.json();
          return Promise.reject(responseObj);
        }
      })
      .then(async (data) => {
        const depotErr = await getAllDepots(data.token);
        if (depotErr) {
          return Promise.reject(depotErr);
        }
        return data;
      })
      .then(async (data) => {
        const sfcvErr = await getSearchFilterCategoryValues(data.token);
        if (sfcvErr) {
          return Promise.reject(sfcvErr);
        }
        localStorage.setItem(URL, url);
        localStorage.setItem(USER_NAME, username);
        return data;
      })
      .then(async (data) => {
        data.features = data.features ? data.features : [];
        const sfcvErr = await getRecentParts(
          data.token,
          data.userPreferenceId,
          data.features
        );
        if (sfcvErr) {
          return Promise.reject(sfcvErr);
        }
        localStorage.setItem(URL, url);
        localStorage.setItem(USER_NAME, username);
        dispatch(setAuth(data));
        navigate("/");
      })
      .catch(async (err) => {
        if (!err) {
          return;
        }

        if (isSafari) {
          if (
            err.message.includes("Unexpected token") ||
            err.message.includes("Load failed")
          ) {
            if (
              (await checkIfOnline()) ||
              err.message.includes("string did not match the expected pattern")
            ) {
              setLoginResponseMessage(
                "Incorrect URL or service offline. Please check the URL and try again."
              );
            } else setLoginResponseMessage("You appear to be offline.");
          } else if (
            (await checkIfOnline()) &&
            err.message.includes("string did not match the expected pattern")
          ) {
            setLoginResponseMessage(
              "Incorrect URL or service offline. Please check the URL and try again."
            );
          } else setLoginResponseMessage(err.message);
        } else {
          if (
            err.message.includes("Failed to fetch") ||
            err.message.includes("Load failed")
          ) {
            if (await checkIfOnline())
              setLoginResponseMessage(
                "Incorrect URL or service offline. Please check the URL and try again."
              );
            else if (
              err.message.includes("Unexpected token") ||
              err.message.includes("string did not match the expected pattern")
            )
              setLoginResponseMessage("URL format invalid");
            else setLoginResponseMessage("You appear to be offline.");
          } else setLoginResponseMessage(err.message);
        }
      })
      .finally(() => {
        setIsTryingToLogIn(false);
      });
    event.preventDefault();
  };

  useEffect(() => {
    if ((username == "" || password == "" || url == "") && !firstLoad) {
      setLoginResponseMessage("");
    }
    setFirstLoad(false);
  }, [username, password, url]);

  const checkKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == "Enter") {
      const nextElement = e.currentTarget.parentElement?.nextElementSibling;
      if (nextElement) {
        const nextInput = nextElement.querySelector("input");
        if (nextInput) nextInput.focus();
        const nextButton = nextElement.querySelector("button");
        if (nextButton) handleSubmit(e);
      }
    }
  };

  return (
    <div className="Login">
      <img src={envBanner} className="App-logo" alt="logo" />
      <div className="Login-Item">
        <input
          id="login-username-input-text"
          className="Text-Input"
          type="text"
          value={username}
          placeholder="Username"
          onKeyDown={checkKey}
          onChange={changeUsername}
        />
      </div>
      <div className="Login-Item">
        <input
          id="login-password-input-text"
          className="Text-Input"
          type="password"
          placeholder="Password"
          onKeyDown={checkKey}
          onChange={changePassword}
        />
      </div>
      <div className="Login-Item">
        <input
          id="login-url-input-text"
          className="Text-Input"
          type="text"
          style={{ textTransform: "lowercase" }}
          value={url}
          placeholder="URL"
          onKeyDown={checkKey}
          onChange={changeUrl}
        />
      </div>
      <div className="Login-Item">
        <button
          id="login-submit-button"
          disabled={
            isTryingToLogIn || username == "" || password == "" || url == ""
          }
          className="Button-Input"
          onClick={handleSubmit}
        >
          Login
        </button>
      </div>
      <div className="Login-Item">
        {!isTryingToLogIn ? (
          <span id="login-error-message-text" className="Error-Label">
            {loginResponseMessage}
          </span>
        ) : (
          <span
            id="login-loading-spinner-container"
            className="Loading-Spinner"
          >
            <LoadingSpinner />
          </span>
        )}
      </div>
      <span id="version-number">Version: {appVersionNumber}</span>
    </div>
  );
};

export default Login;
