import React from "react";
import { useSelector } from "react-redux";
import { clearAuth } from "../../../store/slices/authSlice";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../store/store";

interface LogoutButtonProps {
  isLoggingOut(): void;
}

const URL = "url";

const LogoutButton = (props: LogoutButtonProps) => {
  const token = useSelector((state: RootState) => state.root.auth.token);
  const navigate = useNavigate();

  const logout = () => {
    props.isLoggingOut();
    const url = localStorage.getItem(URL);
    fetch(url + "/partsapp/auth/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).finally(() => {
      clearAuth();
      navigate("/login");
    });
  };

  return (
    <button
      id="home-header-logout-button"
      className="home-header-hamburger-button"
      onClick={logout}
    >
      Logout
    </button>
  );
};

export default LogoutButton;
