import { ListItem } from "./ScrollableList";

export interface PurchaseOrderSearchResults {
  data: PartsAppPurchaseOrder[];
}

export interface PurchaseOrderSearchResult {
  data: PartsAppPurchaseOrder;
}

export type DeferralStatus =
  | "NotStarted"
  | "Started"
  | "InProgress"
  | "IsDone"
  | "SKGRN_Error"
  | "AllocateParts_Error"
  | "SerialNumber_Error"
  | "Misc_Error";

export type PmfPartType =
  | "Normal"
  | "SpecialPrice"
  | "Memo"
  | "Serial"
  | "Batch";

export interface PartsAppPurchaseOrder extends ListItem {
  deferralID: number;
  deferralStatus: DeferralStatus;
  errorMessage: string;
  orderNumber: string;
  description: string;
  linesReceived: number;
  linesDelivered: number;
  linesTotal: number;
  orderValue: number;
  reference: string;
  allocatedItems: number;
  multiDepotOrder: boolean;
  lockedBy: string;
  lines: PartsAppPurchaseOrderLine[];
  date: Date;
  transferGoods: boolean;
  hasAllocations: boolean;
  isIDT: boolean;
  contactEmail: string;
}

export interface PartsAppPurchaseOrderLine extends ListItem {
  lineNumber?: number;
  location: string;
  unitCost?: number;
  netCost?: number;
  discount?: number;
  surDisc1?: number;
  surDisc2?: number;
  surDisc3?: number;
  surDisc4?: number;
  partNumber: string;
  partDescription: string;
  lineValue: number;
  depotLines: DepotLines[];
  flattenedSingleLine: FlattenedPoLine | undefined;
  expandTrigger: boolean;
  partType: PmfPartType;
  customerStop: boolean;
  customerCreditLimit: boolean;
  customerStopLimitAccNo: string;
}

export interface DepotLines {
  invoiced: number;
  depot: string;
  delivered?: number;
  received?: number;
  ordered?: number;
  finished?: number;
  binLocation: string;
  totalAllocation: number;
  totalPows: number;
  serialLines: SerialInputs[];
}

export interface SerialInputs {
  serialNumber: string;
}

export const defaultPartsAppPurchaseOrder: PartsAppPurchaseOrder = {
  orderNumber: "",
  description: "",
  linesReceived: 0,
  linesDelivered: 0,
  linesTotal: 0,
  orderValue: 0.0,
  date: new Date(),
  allocatedItems: 0,
  rangeStart: 0,
  index: 0,
  reference: "",
  multiDepotOrder: false,
  lockedBy: "",
  lines: [],
  deferralID: 0,
  deferralStatus: "NotStarted",
  errorMessage: "",
  hasAllocations: false,
  transferGoods: false,
  isIDT: false,
  contactEmail: ""
};

export const defaultPartsAppPurchaseOrderLine: PartsAppPurchaseOrderLine = {
  location: "",
  partNumber: "",
  partDescription: "",
  lineValue: 0,
  depotLines: [],
  expandTrigger: false,
  partType: "Normal",
  rangeStart: 0,
  index: 0,
  flattenedSingleLine: undefined,
  customerStop: false,
  customerCreditLimit: false,
  customerStopLimitAccNo: "",
};

export interface PoOrdered extends PartsAppPurchaseOrder {
  lockedById: string;
  rangeStart: number;
}

export interface PoLock {
  userId: string;
  orderNumber: string;
}

export interface FlattenedPoLine extends PartsAppPurchaseOrderLine {
  depot: string;
  binLocation: string;
  received: number;
  delivered: number;
  ordered: number;
  submitted: boolean;
  totalAllocation: number;
  totalPows: number;
  serialLines: SerialInputs[];
}

export const flattenLine = (
  line: PartsAppPurchaseOrderLine,
  depotId: string
): FlattenedPoLine => {
  const depotInfo = line.depotLines.filter(
    (l: DepotLines) => l.depot == depotId
  )[0];
  return {
    ...line,
    depot: depotInfo.depot,
    binLocation: depotInfo.binLocation,
    delivered: depotInfo.delivered ?? 0,
    ordered: depotInfo.ordered ?? 0,
    received: depotInfo.received ?? 0,
    submitted: false,
    totalAllocation: depotInfo.totalAllocation ?? 0,
    totalPows: depotInfo.totalPows ?? 0,
    serialLines: depotInfo.serialLines ?? undefined,
  };
};

export default PartsAppPurchaseOrder;
