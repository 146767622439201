import {
  PartsAppPurchaseOrderLine,
  FlattenedPoLine,
  flattenLine,
} from "../../../interfaces/Po";
import { ReactComponent as SerialIcon } from "../../../assets/icons/serial-28-regular.svg";
import { ReactComponent as BatchIcon } from "../../../assets/icons/batch-28-regular.svg";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info-28-regular.svg";
import { ReactComponent as SubtractIcon } from "../../../assets/icons/subtract-circle-32-regular.svg";
import { ReactComponent as AddIcon } from "../../../assets/icons/add-circle-32-regular.svg";
import { ReactComponent as SubmitIcon } from "../../../assets/icons/box-checkmark-24-regular.svg";
import { ReactComponent as CancelIcon } from "../../../assets/icons/backspace-20-regular.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit-whitepencil.svg";
import React, { useEffect, useState, useRef } from "react";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";

export interface PoLinesCardProperties {
  poLine: PartsAppPurchaseOrderLine;
  depotId: string;
  canUseSerialFeatures: boolean;
  canSeeUnitCostForIDTs: boolean;
  isIDT: boolean;
  showSerialsPage: () => void;
  showAllocationsWarning: () => void;
  toggleInfoPage: () => void;
}

const PoLinesCard = (props: PoLinesCardProperties) => {
  const canSeeCosts = useSelector(
    (state: RootState) => state.root.auth.permissions.canViewCostPrice
  );

  const pressTimer = useRef<NodeJS.Timeout | null>(null);

  const lineForDepot: FlattenedPoLine = flattenLine(
    props.poLine,
    props.depotId
  );

  const [submitted, setSubmitted] = useState(false);
  const [amount, setAmount] = useState("0");
  const [received, setReceived] = useState(0);

  useEffect(() => {
    lineForDepot.received = 0;
    props.poLine.flattenedSingleLine = lineForDepot;
    setReceived(props.poLine.flattenedSingleLine.delivered);
  }, []);

  useEffect(() => {
    if (props.poLine.expandTrigger) {
      document
        .getElementById("part-card" + props.poLine.lineNumber)
        ?.querySelector("summary")
        ?.click();
      closeAllCards("part-card" + props.poLine.lineNumber);
      props.poLine.expandTrigger = false;
      if (props.poLine.flattenedSingleLine != undefined) {
        props.poLine.flattenedSingleLine.submitted = submitted;
      }
    }
  }, [props.poLine.expandTrigger]);

  const editOrderValue = (inr: number) => {
    const input = document.querySelector<HTMLInputElement>(
      `#${CSS.escape(lineForDepot.partNumber.toString())}` + `-controls input`
    );
    if (input) {
      const val = parseInt(input.value);
      if (val + inr > lineForDepot.ordered - received)
        input.value = (lineForDepot.ordered - received).toString();
      else if (val + inr < 0) input.value = "0";
      else input.value = (val + inr).toString();
      setAmount(input.value);
    }
  };

  const handleMinusButtonDown = () => {
    if (pressTimer.current) {
      clearTimeout(pressTimer.current);
    }
    pressTimer.current = setTimeout(() => {
      editOrderValue(-amount);
    }, 700);
  };

  const handlePlusButtonDown = () => {
    if (pressTimer.current) {
      clearTimeout(pressTimer.current);
    }
    pressTimer.current = setTimeout(() => {
      editOrderValue(lineForDepot.ordered - parseInt(amount));
    }, 700);
  };

  const handleButtonUp = (incrementValue: number) => {
    if (pressTimer.current) {
      clearTimeout(pressTimer.current);
      editOrderValue(incrementValue);
    }
  };

  const checkInput = () => {
    const input = document.querySelector<HTMLInputElement>(
      `#${CSS.escape(lineForDepot.partNumber.toString())}` + `-controls input`
    );
    if (input) {
      const val = parseInt(input.value);

      if (val > lineForDepot.ordered - received)
        input.value = (lineForDepot.ordered - received).toString();
      else if (val < 0 || !val) input.value = "0";
      else input.value = val.toFixed();
      setAmount(input.value);
    }
  };

  const animateDetailsContent = () => {
    const detailsContent = document
      .getElementById("part-card" + props.poLine.lineNumber)
      ?.querySelector(".order-lines-values");

    if (detailsContent) {
      detailsContent.classList.add("animate");
      setTimeout(() => {
        detailsContent.classList.remove("animate");
      }, 300); // Adjust the duration as needed
    }
  };

  const closeAllCards = (id?: string) => {
    // id for card not be closed, null to close all cards
    const listOfCards = [
      ...document.getElementsByClassName("order-lines-list-item"),
    ];
    listOfCards.forEach((e) => {
      if (id) {
        if (e.id != id) {
          setTimeout(() => {
            e.removeAttribute("open");
          }, 1);
        }
      } else e.removeAttribute("open");
    });
  };

  function changeAmount(e: React.ChangeEvent<HTMLInputElement>): void {
    if (
      (parseInt(e.target.value) && !e.target.value.includes(".")) ||
      e.target.value == ""
    )
      setAmount(e.target.value);
  }

  return (
    <>
      <details
        id={"part-card" + props.poLine.lineNumber}
        key={props.poLine.lineNumber}
        className={
          `order-lines-list-item ` +
          (received == lineForDepot.ordered ? `order-lines-transparent` : ``)
        }
      >
        <summary
          className={`purchase-order-lines-header`}
          onClick={() => {
            animateDetailsContent();
            closeAllCards("part-card" + props.poLine.lineNumber);
          }}
        >
          <span
            className={
              (lineForDepot.partType == "Serial" ||
                lineForDepot.partType == "Batch") &&
              props.canUseSerialFeatures
                ? "line-name serial-name"
                : "line-name"
            }
          >
            {lineForDepot.partType == "Serial" ? (
              <SerialIcon
                className="serial-icon-svg"
                id={lineForDepot.partNumber + "-serial-icon"}
              ></SerialIcon>
            ) : null}
            {lineForDepot.partType == "Batch" ? (
              <BatchIcon
                className="serial-icon-svg"
                id={lineForDepot.partNumber + "-serial-icon"}
              ></BatchIcon>
            ) : null}
            <p>{lineForDepot.partNumber}</p>
            {canSeeCosts && !props.isIDT ? (
              <InfoIcon
                className="info-icon-svg"
                id={lineForDepot.partNumber + "-info-icon"}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  //closeAllCards();
                  props.toggleInfoPage();
                }}
              />
            ) : null}
          </span>
          <span className="part-description">
            <p>{lineForDepot.partDescription}</p>
          </span>
        </summary>
        <div className={`order-lines-values`}>
          <div className="purchase-order-lines-value">
            <div className="part-item-value-content">
              {lineForDepot.partNumber}
            </div>
          </div>
          <div className="purchase-order-lines-value">
            <div className="part-item-value-content">
              Location: {lineForDepot.location} 
            </div>
          </div>
        {props.isIDT && props.canSeeUnitCostForIDTs ?
          <div className="purchase-order-lines-value">
            <div className="part-item-value-content">
              Unit Cost: {lineForDepot.unitCost}
            </div>
          </div> : null }
          <div className="purchase-order-lines-value">
            <div
              className={
                "part-item-value-content " +
                (!submitted
                  ? "blue-hi-foreground"
                  : received == lineForDepot.ordered
                  ? "green-hi-foreground"
                  : "red-hi-foreground")
              }
            >
              Received: {received}/{lineForDepot.ordered}
            </div>
          </div>
          {!submitted ? (
            <span
              id={lineForDepot.partNumber + "-controls"}
              className="purchase-order-lines-controls"
            >
              <button
                id={lineForDepot.partNumber + "-controls-cancel"}
                type="button"
                title="Cancel"
                className="red-background white-foreground purchase-order-lines-button"
                onClick={() => {
                  setAmount(received.toString());
                  setSubmitted(!submitted);
                }}
              >
                <CancelIcon />
              </button>
              <button
                id={lineForDepot.partNumber + "-controls-subtract"}
                type="button"
                title="Subtract"
                className="purchase-order-lines-blue-button purchase-order-lines-button"
                onMouseDown={(e) => {
                  e.preventDefault();
                  handleMinusButtonDown();
                }}
                onMouseUp={(e) => {
                  e.preventDefault();
                  handleButtonUp(-1);
                }}
                onTouchStart={() => {
                  handleMinusButtonDown();
                }}
                onTouchEnd={(e) => {
                  e.preventDefault();
                  handleButtonUp(-1);
                }}
                onContextMenu={(e) => e.preventDefault()}
              >
                <SubtractIcon />
              </button>
              <input
                id={lineForDepot.partNumber + "-controls-input"}
                title={lineForDepot.partNumber + "-input-field"}
                type="number"
                inputMode="decimal"
                value={amount}
                onChange={(e) => changeAmount(e)}
                onBlur={checkInput}
              />
              <button
                type="button"
                id={lineForDepot.partNumber + "-controls-plus"}
                title="Add"
                className="purchase-order-lines-blue-button purchase-order-lines-button"
                onMouseDown={(e) => {
                  e.preventDefault();
                  handlePlusButtonDown();
                }}
                onMouseUp={(e) => {
                  e.preventDefault();
                  handleButtonUp(1);
                }}
                onTouchStart={() => {
                  handlePlusButtonDown();
                }}
                onTouchEnd={(e) => {
                  e.preventDefault();
                  handleButtonUp(1);
                }}
                onContextMenu={(e) => e.preventDefault()}
              >
                <AddIcon />
              </button>
              <button
                id={lineForDepot.partNumber + "-controls-submit"}
                type="button"
                title="Submit"
                className="purchase-order-lines-submit-button purchase-order-lines-button"
                onClick={() => {
                  setSubmitted(!submitted);
                  lineForDepot.received = parseInt(amount);
                  setAmount(amount);
                  setReceived(lineForDepot.delivered + lineForDepot.received);
                  props.poLine.flattenedSingleLine = lineForDepot;
                  if (
                    lineForDepot.received < lineForDepot.totalAllocation &&
                    lineForDepot.totalPows > 1
                  ) {
                    props.showAllocationsWarning();
                  }
                  if (
                    props.canUseSerialFeatures &&
                    !props.isIDT &&
                    lineForDepot.partType == "Serial" &&
                    lineForDepot.received > 0
                  ) {
                    props.showSerialsPage();
                  }
                }}
              >
                <SubmitIcon />
              </button>
            </span>
          ) : (
            <span
              id={lineForDepot.partNumber + "-controls"}
              className="purchase-order-lines-controls"
            >
              {lineForDepot.partType == "Serial" &&
              props.canUseSerialFeatures &&
              lineForDepot.ordered != lineForDepot.delivered ? (
                <button
                  id="serial-part-button"
                  className="purchase-order-lines-blue-button purchase-order-lines-button"
                  onClick={() => {if (!props.isIDT) props.showSerialsPage()}}
                >
                  #
                </button>
              ) : (
                <span className="purchase-order-lines-controls-spacer" />
              )}
              <span className="purchase-order-lines-controls-spacer" />
              <span className="purchase-order-lines-controls-spacer" />
              <button
                id={lineForDepot.partNumber + "-controls-edit"}
                type="button"
                title="Submit"
                className="purchase-order-lines-edit-button lone-edit-button"
                onClick={() => {
                  setReceived(lineForDepot.delivered);
                  setSubmitted(!submitted);
                }}
              >
                <EditIcon />
              </button>
            </span>
          )}
        </div>
      </details>
    </>
  );
};

export default PoLinesCard;
