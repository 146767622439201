enum ForegroundColorSet {
  Black1 = "black1-foreground",
  Black2 = "black2-foreground",
  Black3 = "black3-foreground",
  Black4 = "black4-foreground",
  Gold = "gold-foreground",
  Green = "green-foreground",
  Blue = "blue-foreground",
  Red = "red-foreground",
  White = "white-foreground",
}

export default ForegroundColorSet;
