import React, { ChangeEvent, useEffect, useState } from "react";
import Depot from "../../../interfaces/Depot";
import BackgroundColorSet from "../../../utilities/enums/BackgroundColorSet";
import ForegroundColorSet from "../../../utilities/enums/ForegroundColorSet";
import AbstractButton from "../../components/AbstractButton";
import { ReactComponent as CancelIcon } from "../../../assets/icons/backspace-20-regular.svg";
import { ReactComponent as OkayIcon } from "../../../assets/icons/checkbox-checked-regular.svg";
import "../Home.css";
import "./Hamburger.css";
import { Preferences, defaultPrefs } from "../../../interfaces/Preferences";

interface ChangeDepotProps {
  depots: Array<Depot>;
  visibilityClassName: string;
  currentPreferences: Preferences;
  onCancelCallback(): void;
  onOkayCallback(preferences: Preferences, successMessage: string): void;
}

const DepotChangeDialogue = (props: ChangeDepotProps) => {
  const [preferences, setPreferences] = useState<Preferences>(defaultPrefs);

  const selectedDepotChanged = (event: ChangeEvent<HTMLSelectElement>) => {
    setPreferences({ ...preferences, defaultDepotId: event.target.value });
  };

  const okayClicked = () => {
    props.onOkayCallback(
      preferences,
      "Successfully updated depot to " + preferences.defaultDepotId
    );
  };
  useEffect(() => {
    setPreferences(props.currentPreferences);
  }, [props.currentPreferences]);

  return (
    <div
      id="home-header-change-depot-modal"
      className={props.visibilityClassName}
    >
      <span className="modal-header-text"> Change Depot </span>
      <hr className="modal-header-underline" />
      <select
        id="depot-select"
        value={preferences.defaultDepotId}
        onChange={selectedDepotChanged}
        className="modal-selector"
      >
        {props.depots.map((e: Depot) => (
          <option key={e.id} value={e.id}>
            Depot {e.id} - {e.shortName}
          </option>
        ))}
      </select>
      <span className="modal-button-span">
        <AbstractButton
          id="cancel-button"
          specialClass="modal-button"
          text="Cancel"
          icon={<CancelIcon width="100%" height="100%" />}
          disabled={false}
          activeColor={ForegroundColorSet.Red}
          background={BackgroundColorSet.Black1}
          actionCallback={props.onCancelCallback}
        />
        <AbstractButton
          id="depot-okay-button"
          text="Change"
          specialClass="modal-button"
          icon={<OkayIcon width="100%" height="100%" id="depot-okay-svg" />}
          disabled={false}
          activeColor={ForegroundColorSet.Green}
          background={BackgroundColorSet.Black1}
          actionCallback={okayClicked}
        />
      </span>
    </div>
  );
};

export default DepotChangeDialogue;
