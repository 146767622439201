import React from "react";
import {
  PartsAppPartOrdered,
  PartsAppPartWholeGroupInfo,
} from "../../../interfaces/PartsAppPart";
import "./PartDetails.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { Feature, PartsAppFeature } from "../../../store/slices/authSlice";

interface PartDetailPageProps {
  part: PartsAppPartOrdered;
}

const PartDetailsDepotInfo = (props: PartDetailPageProps) => {
  const canSeeExtraGroupData = useSelector((state: RootState) => state.root.auth.features.filter((e: PartsAppFeature) => e.featureId == Feature.Receive).length > 0);
  return (
    <div id="part-details-basic-info" className={process.env.REACT_APP_BUILD_ENV?.toUpperCase() != "PLA" ? `part-details-content` : `part-details-content-pla`}>
      <div className="part-details-header">
        <span
          id="part-details-part-number"
          className="part-details-part-number"
        >
          {props.part.partNumber}
        </span>
      </div>
      <div className="part-details-description">{props.part.description}</div>
      <div className="hr-container">
        <span className="hr-label">Group</span>
        <hr className="hr-line" />
      </div>
      <div className="part-details-group-depot-table container">
        <table className="part-details-group-depot-table">
          <tr className="part-details-group-depot-table header">
            <th>Depot</th>
            <th>Bin</th>
            <th>QoH</th>
            <th>Free</th>
            <th>WIP</th>
            {canSeeExtraGroupData ? 
            <><th>Van Stk</th>
            <th>Sugg.</th>
            <th>Emerg.</th>
            <th>Min</th>
            <th>Max</th></> : null}
          </tr>
          <tr className="part-details-group-depot-table row">
            <td className="part-details-group-depot-table heading">Group</td>
            <td className="part-details-group-depot-table field"></td>
            <td className="part-details-group-depot-table field">
              {props.part.wholeGroupInfo
                ?.map((e: PartsAppPartWholeGroupInfo) => e.qoH)
                .reduce((x: number, y: number) => x + (isNaN(y) ? 0 : y), 0)}
            </td>
            <td className="part-details-group-depot-table field">
              {props.part.wholeGroupInfo
                ?.map((e: PartsAppPartWholeGroupInfo) => e.free)
                .reduce((x: number, y: number) => x + (isNaN(y) ? 0 : y), 0)}
            </td>
            <td className="part-details-group-depot-table field">
              {props.part.wholeGroupInfo
                ?.map((e: PartsAppPartWholeGroupInfo) => e.wip)
                .reduce((x: number, y: number) => x + (isNaN(y) ? 0 : y), 0)}
            </td>

            {canSeeExtraGroupData ? <>
            <td className="part-details-group-depot-table field">
              {props.part.wholeGroupInfo
                ?.map((e: PartsAppPartWholeGroupInfo) => e.van)
                .reduce((x: number, y: number) => x + (isNaN(y) ? 0 : y), 0)}
            </td>
            <td className="part-details-group-depot-table field">
              {props.part.wholeGroupInfo
                ?.map((e: PartsAppPartWholeGroupInfo) => e.suggested)
                .reduce((x: number, y: number) => x + (isNaN(y) ? 0 : y), 0)}
            </td>
            <td className="part-details-group-depot-table field">
              {props.part.wholeGroupInfo
                ?.map((e: PartsAppPartWholeGroupInfo) => e.emergency)
                .reduce((x: number, y: number) => x + (isNaN(y) ? 0 : y), 0)}
            </td>
            <td className="part-details-group-depot-table field">
              {props.part.wholeGroupInfo
                ?.map((e: PartsAppPartWholeGroupInfo) => e.min)
                .reduce((x: number, y: number) => x + (isNaN(y) ? 0 : y), 0)}
            </td>
            <td className="part-details-group-depot-table field">
              {props.part.wholeGroupInfo
                ?.map((e: PartsAppPartWholeGroupInfo) => e.max)
                .reduce((x: number, y: number) => x + (isNaN(y) ? 0 : y), 0)}
            </td></> : null
            }
          </tr>
          {props.part.wholeGroupInfo?.map(
            (e: PartsAppPartWholeGroupInfo, index: number) => {
              return (
                <tr className="part-details-group-depot-table row" key={index}>
                  <td className="part-details-group-depot-table heading">
                    {e.depotName}
                  </td>
                  <td className="part-details-group-depot-table field">
                    {e.bin}
                  </td>
                  <td className="part-details-group-depot-table field">
                    {e.qoH}
                  </td>
                  <td className="part-details-group-depot-table field">
                    {e.free}
                  </td>
                  <td className="part-details-group-depot-table field">
                    {e.wip}
                  </td>
                  {canSeeExtraGroupData?<>
                  <td className="part-details-group-depot-table field">
                    {e.van}
                  </td>
                  <td className="part-details-group-depot-table field">
                    {e.suggested}
                  </td>
                  <td className="part-details-group-depot-table field">
                    {e.emergency}
                  </td>
                  <td className="part-details-group-depot-table field">
                    {e.min}
                  </td>
                  <td className="part-details-group-depot-table field">
                    {e.max}
                  </td>
                  </>:null}
                </tr>
              );
            }
          )}
        </table>
      </div>
      <div className="hr-container">
        <span className="hr-label">Orders</span>
        <hr className="hr-line" />
      </div>
      <table className="part-details-table">
        <tr>
          <td className="part-details-2col-field">
            <b>Ordered</b>:&nbsp;
            {props.part.wholeGroupInfo
              ?.map((e: PartsAppPartWholeGroupInfo) => e.onOrder)
              .reduce((x: number, y: number) => x + (isNaN(y) ? 0 : y), 0)}
          </td>
          <td className="part-details-2col-field">
            <b>Req. for PO</b>:&nbsp;
            {props.part.wholeGroupInfo
              ?.map((e: PartsAppPartWholeGroupInfo) => e.reqForPO)
              .reduce((x: number, y: number) => x + (isNaN(y) ? 0 : y), 0)}
          </td>
        </tr>
        <tr>
          <td className="part-details-2col-field">
            <b>Req. for vans</b>:&nbsp;
            {props.part.wholeGroupInfo
              ?.map((e: PartsAppPartWholeGroupInfo) => e.reqForVans)
              .reduce((x: number, y: number) => x + (isNaN(y) ? 0 : y), 0)}
          </td>
          <td className="part-details-2col-field">
            <b>Allocated</b>:&nbsp;
            {props.part.wholeGroupInfo
              ?.map((e: PartsAppPartWholeGroupInfo) => e.allocated)
              .reduce((x: number, y: number) => x + (isNaN(y) ? 0 : y), 0)}
          </td>
        </tr>
        <tr>
          <td className="part-details-2col-field">
            <b>Req. not alloc</b>:&nbsp;
            {props.part.wholeGroupInfo
              ?.map((e: PartsAppPartWholeGroupInfo) => e.reqNotAlloc)
              .reduce((x: number, y: number) => x + (isNaN(y) ? 0 : y), 0)}
          </td>
          <td className="part-details-2col-field">
            <b>Revd. no inv</b>:&nbsp;
            {props.part.wholeGroupInfo
              ?.map((e: PartsAppPartWholeGroupInfo) => e.receivedNoInvoice)
              .reduce((x: number, y: number) => x + (isNaN(y) ? 0 : y), 0)}
          </td>
        </tr>
      </table>
    </div>
  );
};

export default PartDetailsDepotInfo;
