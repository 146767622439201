import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import PartsAppPartOrdered from "../../interfaces/PartsAppPart";

export interface AuthState {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  defaultDepotId: string;
  preferExternalScanner: boolean;
  userId: string;
  userPreferenceId: number;
  token: string;
  companyId: number;
  permissions: PartsAppPermissions;
  features: PartsAppFeature[];
}

export interface PartsAppPermissions {
  stockQuantityAdjustmentAllowed: boolean;
  canViewCostPrice: boolean;
  canUpdateBinLocation: boolean;
}

export interface PartsAppFeature {
 featureId: Feature 
 created: Date
 notes: string
}

export enum Feature {
  Receive = 1,
  SerialReceive = 2,
  IDTReceive = 3
}

const initialState: AuthState = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  defaultDepotId: "",
  preferExternalScanner: false,
  userId: "",
  userPreferenceId: 0,
  token: "",
  companyId: 0,
  permissions: {
    stockQuantityAdjustmentAllowed: false,
    canViewCostPrice: false,
    canUpdateBinLocation: false,
  },
  features:[]
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (_state, action: PayloadAction<AuthState>) => {
      return action.payload;
    },
    setPreferences: (
      state,
      action: PayloadAction<{
        defaultDepotId: string;
        preferExternalScanner: boolean;
        recentParts: PartsAppPartOrdered[];
      }>
    ) => {
      state.defaultDepotId = action.payload.defaultDepotId;
      state.preferExternalScanner = action.payload.preferExternalScanner;
    },
    clearAuth: () => {
      return initialState;
    },
  },
});

export const { setAuth, clearAuth, setPreferences } = authSlice.actions;

export default authSlice.reducer;
