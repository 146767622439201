import React, { RefObject } from "react";
import "./BarcodeCameraScanner.css";
import PartsAppPart from "../../interfaces/PartsAppPart";

interface BarcodeBluetoothScannerProps {
  inputRef: RefObject<HTMLInputElement>;
  barcode: string;
  handleInputChange(e: React.ChangeEvent<HTMLInputElement>): void;
  onSelect(part: PartsAppPart): void;
  foundParts: PartsAppPart[];
}

const BarcodeBluetoothScanner = (props: BarcodeBluetoothScannerProps) => {
  const handlePartClick = (part: PartsAppPart) => {
    if (part.depot) {
      props.onSelect(part);
    }
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      props.handleInputChange(
        e as unknown as React.ChangeEvent<HTMLInputElement>
      );
    }
  };

  return (
    <>
      <div className="barcode-page-wrapper">
        <input
          id="barcode-input-box"
          type="text"
          className="barcode-input-box"
          ref={props.inputRef}
          value={props.barcode}
          onChange={props.handleInputChange}
          onKeyDown={handleInputKeyDown}
          inputMode="none"
        />
      </div>
      <div className="barcode-microcard-collection">
        {props.foundParts.length > 0
          ? props.foundParts
              .filter(
                (part: PartsAppPart, index, array) =>
                  array.findIndex(
                    (p: PartsAppPart) => p.partNumber == part.partNumber
                  ) == index
              )
              .map((part: PartsAppPart) => {
                return (
                  <>
                    <div
                      id={"part-microcard-" + part.partNumber + "-header"}
                      key={part.index + "-" + part.partNumber}
                      className={
                        "barcode-microcard" +
                        (part.depot ? "" : " parts-list-item-transparent")
                      }
                      onClick={() => handlePartClick(part)}
                    >
                      <span
                        style={{
                          fontWeight: "bold",
                          paddingRight: "3px",
                          fontSize: "1.2em",
                        }}
                      >
                        {part.partNumber}
                      </span>
                      {part.depot ? (
                        <span>{part.description}</span>
                      ) : (
                        <span className="gold-foreground">
                          <i>UNAVAILABLE AT DEPOT</i>
                        </span>
                      )}
                    </div>
                  </>
                );
              })
          : "No Parts Found"}
      </div>
    </>
  );
};

export default BarcodeBluetoothScanner;
