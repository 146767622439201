import React from "react";
import "./HeadedValueBox.css";

interface HeadedValueBoxProps {
    title: string;
    value: number;
}

const HeadedValueBox = (props: HeadedValueBoxProps) => {
    return (
        <div className="headed-value-box">
            <div className="headed-value-box-header">{props.title}</div>
            <div className="headed-value-box-value">{(Math.round(props.value * 100) / 100).toFixed(2)}</div>
        </div>
    );
}
export default HeadedValueBox;