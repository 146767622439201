import React, { useState, KeyboardEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ScanIcon } from "../../assets/icons/barcode-scanner.svg";
import { ReactComponent as SaveIcon } from "../../assets/icons/save-icon.svg";
import { ReactComponent as OptionsIcon } from "../../assets/icons/options-edit.svg";
import { ReactComponent as SortIcon } from "../../assets/icons/sort-filter.svg";
import { ReactComponent as BackIcon } from "../../assets/icons/back-chevron.svg";
import { ToastContainer, toast, Slide } from "react-toastify";
import ForegroundColorSet from "../../utilities/enums/ForegroundColorSet";
import BackgroundColorSet from "../../utilities/enums/BackgroundColorSet";
import AbstractButton from "../components/AbstractButton";
import "../home/Home.css";
import PartSearch, {
  BarcodeSearchRequestModel,
  Inclusivity,
  OrderDirection,
  PartSearchOrderOption,
} from "../../interfaces/PartSearch";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import PartsAppPart, {
  defaultPartOrdered,
  PartsAppPartOrdered,
  PartsAppPartWholeGroupInfo,
} from "../../interfaces/PartsAppPart";
import { apiFetch } from "../../utilities/AuthenticatedFetch";
import SearchAndFilter from "./sortandfilter/SearchAndFilter";
import PartDetails from "./partdetails/PartDetails";
import BarcodeSearchModal from "./BarcodeSearchModal";
import ScrollableCardList from "../components/ScrollableCardList";
import PartCard from "./PartCard";
import Depot from "../../interfaces/Depot";
import NavBar from "../components/NavBar";

const SEARCH_ENDPOINT = "/partsapp/enquire/search";
const BARCODE_SEARCH_ENDPOINT = "/partsapp/enquire/barcode-search";

let rangeStart = 0;
let rangeEnd = 10;

const Enquire = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const switchScanMode = queryParams.get("switchscanmode") === "true";
  const selectedPartNum = queryParams.get("selectedpartnum");

  const useExternalScannerDefault = useSelector(
    (state: RootState) => state.root.auth.preferExternalScanner
  );

  const [useExternalScanner, setUseExternalScanner] = useState(
    useExternalScannerDefault
  );
  const navigate = useNavigate();

  const [foundParts, setFoundParts] = useState<PartsAppPartOrdered[]>([]);
  const [showBinTrigger, setShowBinTrigger] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [isShowingRecent, setIsShowingRecent] = useState(true);
  const [parts, setParts] = useState<PartsAppPartOrdered[]>([]);
  const [searchParams, setSearchParams] = useState(
    createDefaultPartSearch("", "")
  );
  const [searchAndFilterMenuVisible, setSearchAndFilterMenuVisible] =
    useState(false);
  const [showSearchAndFilterMenuTrigger, setShowSearchAndFilterMenuTrigger] =
    useState(0);

  const [optionMenuVisible, setOptionMenuVisible] = useState(false);

  const [lastSearchReturnedStuff, setLastSearchReturnedStuff] = useState(true);
  const depotId = useSelector(
    (state: RootState) => state.root.auth.defaultDepotId
  );
  const canSeeCosts = useSelector(
    (state: RootState) => state.root.auth.permissions.canViewCostPrice
  );
  const canChangeBinLocation = useSelector(
    (state: RootState) => state.root.auth.permissions.canUpdateBinLocation
  );
  const recentParts = useSelector(
    (state: RootState) => state.root.system.recentParts
  );

  const [enableCanChangeBinOption, setEnableCanChangeBinOption] =
    useState(false);
  const [showPartDetails, setShowPartDetails] = useState(false);
  const [selectedPart, setSelectedPart] =
    useState<PartsAppPartOrdered>(defaultPartOrdered);

  const [barcodeSearchModalVisible, setBarcodeSearchModalVisible] =
    useState(false);

  const myDepot = useSelector(
    (state: RootState) => state.root.auth.defaultDepotId
  );
  const allDepots = useSelector((state: RootState) => state.root.system.depots);
  const myDepotName = allDepots.filter((e: Depot) => e.id == myDepot)[0]
    .shortName;

  useEffect(() => {
    apiFetch<boolean>("/partsapp/auth/authed", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Here we are checking for the existance of query string parameters.
    // Due to some weirdness with Quagga in chrome, we need to refresh the page
    // after each barcode scan. This is to stop the page holding onto the camera feed.
    if (switchScanMode === true) {
      setBarcodeSearchModalVisible(true);
      setUseExternalScanner(false);
    } else if (selectedPartNum !== null && selectedPartNum !== "") {
      const part: PartsAppPartOrdered = defaultPartOrdered;
      part.partNumber = selectedPartNum;
      part.depot = depotId;
      setSelectedPart(part as PartsAppPartOrdered);
      setShowPartDetails(true);
      setBarcodeSearchModalVisible(false);
    }

    setParts(
      recentParts.map((e: PartsAppPartOrdered) => {
        const existsAtDepot =
          (e.wholeGroupInfo &&
            e.wholeGroupInfo.filter((e) => e.depotName == myDepotName).length >
              0) ||
          false;

        const numDepots: number = calculateNumDepots(existsAtDepot, e);

        return {
          ...e,
          existsAtDepot: existsAtDepot,
          depotString: numDepots + " other depot(s)",
        };
      })
    );
    setEnableCanChangeBinOption(canChangeBinLocation);
  }, []);

  const SearchAndFilterClasses = searchAndFilterMenuVisible
    ? "modal-open"
    : "modal-closed";

  const OptionMenuClasses = optionMenuVisible ? "modal-open" : "modal-closed";

  const updatePreference = (data: PartSearch) => {
    setSearchParams(data);
    setSearchAndFilterMenuVisible(false);
    doSearch(data);
  };

  const cancelFilterButtonClicked = () => {
    setSearchAndFilterMenuVisible(false);
  };

  const changeSearchTerm = (event: { target: { value: string } }) => {
    const term = event.target.value;
    setSearchTerm(term);
  };

  const checkForReturn = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.key === "Go" || e.key === "Next") {
      const searchBar = document.getElementById(
        "search-term-input-text"
      ) as HTMLElement;
      searchBar.blur();
    }
  };

  function doSearch(data: PartSearch) {
    setParts([]);
    let term = searchTerm;
    term = term.trim();
    rangeStart = 0;
    rangeEnd = 10;
    data.SearchTerm = term;
    data.Depot = depotId;

    setSearchParams(data);

    const results = fetchSearchResults(data, 0, 10);

    results.then((e) => {
      setParts(e);
      rangeStart = 10;
      rangeEnd = 20;
    });
  }

  function fetchMoreSearchResults(amount: number) {
    if (!lastSearchReturnedStuff || isShowingRecent) {
      return Promise.resolve([]);
    }
    const myStart = rangeStart;
    const myEnd = rangeEnd;
    rangeStart = rangeEnd;
    rangeEnd = rangeEnd + amount;

    return fetchSearchResults(searchParams, myStart, myEnd);
  }

  async function fetchBySingleBarcode(barcode: string) {
    //setBarcodeSearchModalVisible(false);
    setIsFetching(true);
    const requestParams: BarcodeSearchRequestModel = {
      BarcodesToFind: [barcode],
      Depot: depotId,
    };
    const requestOptions: RequestInit = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestParams),
    };
    try {
      const response = await apiFetch<PartsAppPart[]>(
        BARCODE_SEARCH_ENDPOINT,
        requestOptions
      );
      //don't load more on scroll
      setLastSearchReturnedStuff(false);
      if (response && response.length > 0) {
        const rangeResponse = response as PartsAppPartOrdered[];
        setFoundParts(rangeResponse);
      } else setFoundParts({} as PartsAppPartOrdered[]); // Return empty list if none found
      // Return the parsed response data as an array of PartsAppPart objects
      setIsFetching(false);
    } catch (error: unknown) {
      let message = error;
      if (error instanceof Error) {
        message = error.message;
      }
      toast.error("Barcode Search Failed:  " + message, {
        position: "bottom-center",
        autoClose: 3000,
        theme: "colored",
        transition: Slide,
      });
      return [];
    }
  }

  function calculateNumDepots(existsAtDepot: boolean, part: PartsAppPart) {
    const partDepots =
      (existsAtDepot
        ? []
        : part.wholeGroupInfo?.map(
            (d: PartsAppPartWholeGroupInfo) => d.depotName
          )) || [];

    const numDepots: number = !existsAtDepot
      ? allDepots.filter((d: Depot) => partDepots.includes(d.shortName)).length
      : 0;
    return numDepots;
  }

  // Define the function to fetch the search results
  async function fetchSearchResults(
    searchParams: PartSearch,
    start: number,
    end: number
  ): Promise<PartsAppPartOrdered[]> {
    setIsFetching(true);
    // Build the request options
    searchParams.RangeStart = start;
    searchParams.RangeEnd = end;
    searchParams.SearchTerm = searchParams.SearchTerm?.toUpperCase();
    const requestOptions: RequestInit = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(searchParams),
    };
    // Send the request and wait for the response
    try {
      const response = await apiFetch<PartsAppPart[]>(
        SEARCH_ENDPOINT,
        requestOptions
      );

      // Parse the response body as JSON
      setLastSearchReturnedStuff(response.length > 0);

      // Return the parsed response data as an array of PartsAppPart objects
      setIsFetching(false);
      const rangeResponse = response as PartsAppPartOrdered[];
      setIsShowingRecent(false);
      return rangeResponse.map((e) => {
        e.rangeStart = start;

        const existsAtDepot =
          (e.wholeGroupInfo &&
            e.wholeGroupInfo.filter((e) => e.depotName == myDepotName).length >
              0) ||
          false;

        const thisDepotValues = e.wholeGroupInfo && (e.wholeGroupInfo.filter((e) => e.depotName == myDepotName))[0];

        e.ordered = thisDepotValues?.onOrder ?? 0;
        e.freeStock = thisDepotValues?.free ?? 0;

        e.existsAtDepot = existsAtDepot;

        const numDepots: number = calculateNumDepots(existsAtDepot, e);

        e.depotString = numDepots + " other depot(s)";

        return e;
      }) as PartsAppPartOrdered[];
    } catch (error: unknown) {
      let message: string;
      if (error instanceof Error) {
        message = error.message;
      } else {
        message = String(error);
      }
      if (message.includes("appear to be offline")) {
        setIsFetching(false);
        setIsShowingRecent(true);
      }
      toast.error("Part Search Failed:  " + message, {
        position: "bottom-center",
        autoClose: 3000,
        theme: "colored",
        transition: Slide,
      });
      return recentParts.map((e: PartsAppPartOrdered) => {
        const existsAtDepot =
          (e.wholeGroupInfo &&
            e.wholeGroupInfo.filter((e) => e.depotName == myDepotName).length >
              0) ||
          false;

        e.existsAtDepot = existsAtDepot;

        const numDepots: number = calculateNumDepots(existsAtDepot, e);

        e.depotString = numDepots + " other depot(s)";

        return e;
      });
    }
  }

  function createDefaultPartSearch(
    searchTerm: string,
    depot: string
  ): PartSearch {
    return {
      RangeStart: 0,
      RangeEnd: 10,
      SmartSearch: true,
      Order: PartSearchOrderOption.PartNumber,
      OrderDirection: OrderDirection.Ascending,
      SearchTerm: searchTerm,
      Depot: depot,
      Prefixes: [],
      ProductGroups: [],
      ClassCodes: [],
      OnOrder: Inclusivity.Shown,
      SerialNumbered: Inclusivity.Shown,
      Memo: Inclusivity.Shown,
      Supersessions: Inclusivity.Shown,
      ShowAllDepots: true,
    };
  }

  const handleBarcodePress = (event: { preventDefault: () => void }) => {
    setBarcodeSearchModalVisible(true);
    //navigate("/barcode");
    event.preventDefault();
  };

  const handleBackPress = () => {
    if (showPartDetails) {
      setShowPartDetails(false);
    } else {
      navigate("/");
    }
  };

  const showSearchAndFilterMenu = () => {
    setShowSearchAndFilterMenuTrigger(showSearchAndFilterMenuTrigger + 1);
    setSearchAndFilterMenuVisible(!searchAndFilterMenuVisible);
  };

  const showOptionMenu = () => {
    setOptionMenuVisible(!optionMenuVisible);
  };

  return (
    <>
      {searchAndFilterMenuVisible ? (
        <div id="main-menu-modal-mask" className="modal-mask"></div>
      ) : null}

      <div id="enquire-container" className="module-container">
        <div id="enquire-nav-top" className="module-nav nav-top">
          <div className="module-nav-button top">
            <AbstractButton
              id="enquire-nav-back-button"
              text="Back"
              icon={<BackIcon className="module-nav-button-logo" />}
              disabled={searchAndFilterMenuVisible}
              activeColor={ForegroundColorSet.Blue}
              background={BackgroundColorSet.Black1}
              specialClass={null}
              actionCallback={handleBackPress}
            />
          </div>
          <div className="module-nav-button top">
            <AbstractButton
              id="enquire-nav-sort-button"
              text="Filter"
              specialClass={null}
              icon={<SortIcon className="module-nav-button-logo" />}
              disabled={showPartDetails}
              activeColor={
                searchAndFilterMenuVisible
                  ? ForegroundColorSet.White
                  : ForegroundColorSet.Blue
              }
              background={BackgroundColorSet.Black1}
              actionCallback={showSearchAndFilterMenu}
            />
          </div>
          <div className="module-nav-button top">
            <AbstractButton
              id="enquire-nav-save-button"
              text="Save"
              specialClass={null}
              icon={<SaveIcon className="module-nav-button-logo" />}
              disabled={true}
              activeColor={ForegroundColorSet.Blue}
              background={BackgroundColorSet.Black1}
              actionCallback={function (): unknown {
                throw new Error("Function not implemented.");
              }}
            />
          </div>
          <div className="module-nav-button top">
            <AbstractButton
              id="enquire-nav-options-button"
              text="Options"
              specialClass={null}
              icon={<OptionsIcon className="module-nav-button-logo" />}
              disabled={!showPartDetails || process.env.REACT_APP_BUILD_ENV?.toUpperCase() == "PLA" }
              activeColor={
                optionMenuVisible
                  ? ForegroundColorSet.White
                  : ForegroundColorSet.Blue
              }
              background={BackgroundColorSet.Black1}
              actionCallback={showOptionMenu}
            />
          </div>
        </div>
        <div id="enquire-search-results" className={process.env.REACT_APP_BUILD_ENV?.toUpperCase() != "PLA" ? `search-results` : `search-results-pla`}>
          <span className="parts-list-description">
            {isShowingRecent ? "Recently Viewed:" : "Search Results:"}
          </span>
          <ScrollableCardList
            fetchMoreResults={() => fetchMoreSearchResults(10)}
            initialItems={parts}
            isFetching={isFetching}
            renderItem={(part) => (
              <PartCard
                key={parts.indexOf(part) + "-" + part.partNumber}
                myDepotName={myDepotName}
                partClicked={(part) => {
                  setSelectedPart(part);
                  setShowPartDetails(true);
                }}
                part={part}
                isRecent={isShowingRecent}
              />
            )}
            loadsLazily={true}
          />
        </div>
      </div>
      <div
        id="enquire-search-input-wrapper"
        className={"search-input " + (process.env.REACT_APP_BUILD_ENV?.toUpperCase() != "PLA" ? "module-search" : "module-search-pla")}
      >
        <input
          id="search-term-input-text"
          className="module-search-term-input-text"
          type="text"
          value={searchTerm}
          placeholder="&#x1F50D; Search..."
          onKeyDown={checkForReturn}
          onChange={changeSearchTerm}
          onBlur={() => doSearch(searchParams)}
          autoCorrect="off"
          autoCapitalize="none"
          autoComplete="off"
          spellCheck="false"
        />
        {process.env.REACT_APP_BUILD_ENV?.toUpperCase() != "PLA" ?
        <button
          id="enquire-search-barcode-button"
          className="module-search-barcode-button"
          onClick={handleBarcodePress}
        >
          <ScanIcon className="module-search-barcode-button-logo" />
        </button> : null }
      </div>
      <div
        id="enquire-search-input-curtain"
        className="search-bar-safe-area-curtain"
      />
      {process.env.REACT_APP_BUILD_ENV?.toUpperCase() != "PLA" ? 
      <NavBar activePage={"enquire"} /> : null }

      <ToastContainer />
      {searchAndFilterMenuVisible ? (
        <div id="main-menu-modal-mask" className="modal-mask"></div>
      ) : null}
      <div className="module-container">
        <PartDetails
          failedToLoadCallback={() => setShowPartDetails(false)}
          visible={showPartDetails}
          part={selectedPart}
          canSeeCosts={canSeeCosts}
          canChangeBinLocation={canChangeBinLocation}
          showBinTrigger={showBinTrigger}
          isUsingRecentParts={isShowingRecent}
          showOptionsMenu={() => {
            if (canChangeBinLocation) setEnableCanChangeBinOption(true);
          }}
          onUpdatePart={(newPart: PartsAppPartOrdered) => {
            setParts(
              parts.map((e) => {
                if (
                  e.partNumber == newPart.partNumber &&
                  e.depot == newPart.depot
                ) {
                  e.bin = newPart.bin;
                }
                return e;
              })
            );
          }}
          hideOptionsMenu={() => setEnableCanChangeBinOption(false)}
          allDepots={allDepots}
        />
      </div>
      <SearchAndFilter
        onShowTrigger={showSearchAndFilterMenuTrigger}
        onOkayCallback={updatePreference}
        onCancelCallback={cancelFilterButtonClicked}
        visibilityClassName={SearchAndFilterClasses}
        searchData={searchParams}
      />

      {barcodeSearchModalVisible ? (
        <>
          <div
            id="enquire-option-modal-mask"
            onClick={() => {
              setBarcodeSearchModalVisible(false);
              navigate("/enquire");
              window.location.reload();
            }}
            className="modal-mask"
          ></div>
          <BarcodeSearchModal
            barcodeSearchEndpoint={BARCODE_SEARCH_ENDPOINT}
            useDeviceCameraByDefault={useExternalScanner}
            depot={depotId}
            onCancelCallback={() => {
              navigate("/enquire");
              window.location.reload();
            }}
            onBarcodeEntered={fetchBySingleBarcode}
            onPartSelected={(part: PartsAppPart) => {
              const orderedPart = part as PartsAppPartOrdered;
              orderedPart.existsAtDepot = true;
              setSelectedPart(orderedPart);
              setShowPartDetails(true);
              setBarcodeSearchModalVisible(false);
            }}
            foundParts={foundParts}
          />
        </>
      ) : null}
      {optionMenuVisible ? (
        <div
          id="enquire-option-modal-mask"
          onClick={() => setOptionMenuVisible(false)}
          className="modal-mask"
        ></div>
      ) : null}

      <div className="option-menu-header-wrapper">
        <div id="enquire-options-hamburger-menu" className={OptionMenuClasses}>
          <button
            id="part-options-change-bin-button"
            className="home-header-hamburger-button"
            onClick={() => {
              setShowBinTrigger((t) => t + 1);
              setOptionMenuVisible(false);
            }}
            disabled={!enableCanChangeBinOption}
          >
            Change Bin Location
          </button>
        </div>
      </div>
    </>
  );
};

export default Enquire;
