import React from "react";
import AbstractButton from "../../components/AbstractButton";
import ForegroundColorSet from "../../../utilities/enums/ForegroundColorSet";
import BackgroundColorSet from "../../../utilities/enums/BackgroundColorSet";
import { ReactComponent as ContinueIcon } from "../../../assets/icons/checkbox-checked-regular.svg";
import { ReactComponent as BackIcon } from "../../../assets/icons/back-chevron.svg";

const GrnStopCredWarning = (props: {
  showStopCredWarning: boolean;
  problemPart: string;
  problemCustomer: string;
  isStop: boolean;
  onContinue(): void;
  onCancel(): void;
}) => {

  return (
    <div
      className={
        "finish-page " + (props.showStopCredWarning ? "modal-open" : "modal-closed")
      }
    >
      <div id="main-menu-modal-mask" className="modal-mask"></div>
      <div className="modal-page finish-page">
            <div className="info-page-header">
              <p id="finish-page-title">Warning</p>
            </div>
            <hr className="modal-header-underline"></hr>
            <div className="info-page-section">
             This Purchase order contains a line for Part which is allocated to a customer who is {props.isStop ? "On Stop" : "Over their Credit Limit"}.
            </div>
            <div className="info-page-section">
              Part number: {props.problemPart}
            </div>
            <div className="info-page-section">
              Customer number: {props.problemCustomer}
            </div>
            <div className="info-page-section">
              <b>You will not be able to allocate any parts on this order to meet customer requirements.</b>
            </div>
            <div className="info-page-section">
              For alternative allocation options, please use Gold.
            </div>
            <span className="modal-button-span">
              <AbstractButton
                id="cancel-button"
                specialClass="modal-button"
                text="Back"
                icon={<BackIcon width="100%" height="100%" />}
                disabled={false}
                activeColor={ForegroundColorSet.Red}
                actionCallback={() => props.onCancel()}
                background={BackgroundColorSet.Black1}
              />
              <AbstractButton
                id="cancel-button"
                specialClass="modal-button"
                text="Continue"
                icon={<ContinueIcon width="100%" height="100%" />}
                disabled={false}
                activeColor={ForegroundColorSet.Green}
                actionCallback={() => props.onContinue()}
                background={BackgroundColorSet.Black1}
              />
             
            </span>
              
      </div>
    </div>
  );
};

export default GrnStopCredWarning;
