import React, { createRef, useEffect, useState } from "react";
import ibcos_logo from "../../../assets/logo.svg";
import ibcos_dev_logo from "../../../assets/dev_logo.svg";
import ibcos_test_logo from "../../../assets/test_logo.svg";
import safari_burger_icon from "../../../assets/icons/safari_burger_icon.svg";
import safari_close_icon from "../../../assets/icons/safari_close_icon.svg";
import { ReactComponent as ScanIcon } from "../../../assets/icons/barcode-scanner.svg";
import "../Home.css";
import "./Hamburger.css";
import LoadingMask from "../../../utilities/LoadingMask";
import loadingspinner from "../../../assets/spinning-loading.gif";
import LogoutButton from "./LogoutButton";
import DepotChangeDialogue from "./DepotChangeDialogue";
import Depot from "../../../interfaces/Depot";
import { useSelector } from "react-redux";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { AuthState, setPreferences } from "../../../store/slices/authSlice";
import { RootState } from "../../../store/store";
import { apiFetch } from "../../../utilities/AuthenticatedFetch";
import SettingsDialogue from "./SettingsDialogue";
import { Preferences } from "../../../interfaces/Preferences";

interface HomeHeaderProps {
  Depots: Array<Depot>;
}

const HomeHeader = (props: HomeHeaderProps) => {
  const [hamburgerMenuVisible, setHamburgerMenuVisible] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isChangingDepot, setIsChangingDepot] = useState(false);
  const [changeDepotVisible, setChangeDepotVisible] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [envBanner, setEnvBanner] = useState("");

  const dispatch = useDispatch();

  const initialAuthState = useSelector((state: RootState) => state.root.auth);

  const hamburger = createRef<HTMLDivElement>();
  const hamburgerClasses = hamburgerMenuVisible ? "modal-open" : "modal-closed";

  const changeDepotClasses = changeDepotVisible ? "modal-open" : "modal-closed";
  const settingsClasses = showSettings ? "modal-open" : "modal-closed";

  const userAgent = navigator.userAgent.toLowerCase();
  const isSafari =
    userAgent.indexOf("safari") != -1 && userAgent.indexOf("chrome") == -1;

  useEffect(() => {
    switch (process.env.REACT_APP_BUILD_ENV?.toUpperCase()) {
      case "PRODUCTION":
        setEnvBanner(ibcos_logo);
        break;
      case "DEVELOPMENT":
        setEnvBanner(ibcos_dev_logo);
        break;
      case "TESTING":
        setEnvBanner(ibcos_test_logo);
        break;
      default:
        setEnvBanner(ibcos_logo);
        break;
    }
  }, []);

  const changeDepotClicked = () => {
    setChangeDepotVisible(true);
    showHamburgerMenu();
  };

  const settingsClicked = () => {
    setShowSettings(true);
    showHamburgerMenu();
  };

  const settingsCancelledClicked = () => {
    setShowSettings(false);
  };

  const changeDepotCancelClicked = () => {
    setChangeDepotVisible(false);
  };

  const changePrefsOkayClicked = (
    preferences: Preferences,
    successMessage: string
  ) => {
    setIsChangingDepot(true);
    apiFetch<AuthState>("/partsapp/prefs", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(preferences),
    })
      .then(() => {
        toast.success(successMessage, {
          position: "bottom-center",
          autoClose: 2000,
          theme: "colored",
          transition: Slide,
        });
        dispatch(setPreferences(preferences));
      })
      .catch((err) => {
        toast.error("Unable to update preferences: " + err.message, {
          position: "bottom-center",
          autoClose: 10000,
          theme: "colored",
          transition: Slide,
        });
        console.log(err);
      })
      .finally(() => {
        setIsChangingDepot(false);
        setChangeDepotVisible(false);
        setShowSettings(false);
      });
  };

  const showHamburgerMenu = () => {
    if (isSafari) {
      hamburger.current
        ?.getElementsByTagName("img")[0]
        .classList.toggle("open");
      hamburger.current
        ?.getElementsByTagName("img")[0]
        .classList.toggle("closed");
      hamburger.current
        ?.getElementsByTagName("img")[1]
        .classList.toggle("open");
      hamburger.current
        ?.getElementsByTagName("img")[1]
        .classList.toggle("closed");
    } else {
      const list = hamburger.current
        ?.getElementsByTagName("svg")[0]
        .getElementsByClassName("home-header-hamburger-line");

      hamburger.current
        ?.getElementsByTagName("svg")[0]
        .classList.toggle("open");
      hamburger.current
        ?.getElementsByTagName("svg")[0]
        .classList.toggle("closed");

      if (list) {
        for (const item of list) {
          item.classList.toggle("open");
          item.classList.toggle("closed");
        }
      }
    }

    setHamburgerMenuVisible(!hamburgerMenuVisible);
  };

  return (
    <>
      {showSettings ? (
        <div
          id="settings-modal-mask"
          onClick={settingsCancelledClicked}
          className="modal-mask"
        ></div>
      ) : null}
      <SettingsDialogue
        visibilityClassName={settingsClasses}
        onOkayCallback={changePrefsOkayClicked}
        onCancelCallback={settingsCancelledClicked}
        currentPreferences={{
          ...initialAuthState,
          id: initialAuthState.userPreferenceId,
          userId: initialAuthState.id,
        }}
      />
      {changeDepotVisible ? (
        <div
          id="change-depot-modal-mask"
          onClick={changeDepotCancelClicked}
          className="modal-mask"
        ></div>
      ) : null}
      <DepotChangeDialogue
        onOkayCallback={changePrefsOkayClicked}
        onCancelCallback={changeDepotCancelClicked}
        visibilityClassName={changeDepotClasses}
        depots={props.Depots}
        currentPreferences={{
          ...initialAuthState,
          id: initialAuthState.userPreferenceId,
          userId: initialAuthState.id,
        }}
      />
      {isLoggingOut ? (
        <LoadingMask text="logging you out..." icon={loadingspinner} />
      ) : null}
      {isChangingDepot ? (
        <LoadingMask text="updating preferences..." icon={loadingspinner} />
      ) : null}
      <div className="home-header-wrapper">
        <div id="home-header-hamburger-menu" className={hamburgerClasses}>
          {process.env.REACT_APP_BUILD_ENV?.toUpperCase() != "PLA" ?
          <button
            id="home-header-settings-button"
            onClick={settingsClicked}
            className="home-header-hamburger-button"
          >
            Settings
          </button> : null }
          <button
            id="home-header-change-depot-button"
            className="home-header-hamburger-button"
            onClick={changeDepotClicked}
          >
            Change Depot
          </button>
          <LogoutButton isLoggingOut={() => setIsLoggingOut(true)} />
        </div>
        <div className="home-header">
          <div
            className="home-header-hamburger-icon-button"
            ref={hamburger}
            onClick={showHamburgerMenu}
          >
            {isSafari ? (
              <>
                <img
                  className="home-header-hamburger-icon-svg safari closed"
                  src={safari_burger_icon}
                  alt="burger icon"
                />
                <img
                  className="home-header-hamburger-icon-svg safari open"
                  src={safari_close_icon}
                  alt="burger icon"
                />
              </>
            ) : (
              <svg
                className="home-header-hamburger-icon-svg closed"
                width="1em"
                height="1em"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="25 25 450 400"
              >
                <path
                  id="hamburger-top-line"
                  className="home-header-hamburger-line hamburger-top-line closed"
                  strokeWidth="50"
                  strokeLinecap="round"
                  stroke="currentColor"
                  fill="none"
                  d="M50,50 L450,50"
                />
                <path
                  id="hamburger-middle-line"
                  className="home-header-hamburger-line hamburger-middle-line closed"
                  strokeWidth="50"
                  strokeLinecap="round"
                  stroke="currentColor"
                  fill="none"
                  d="M50,225 L450,225"
                />
                <path
                  id="hamburger-bottom-line"
                  className="home-header-hamburger-line hamburger-bottom-line closed"
                  strokeWidth="50"
                  strokeLinecap="round"
                  stroke="currentColor"
                  fill="none"
                  d="M50,400 L450,400"
                />
              </svg>
            )}
          </div>
          <div className="home-header">
            <div
              id="home-header-hamburger-icon-button"
              className="home-header-hamburger-icon-button"
              ref={hamburger}
              onClick={showHamburgerMenu}
            >
              {isSafari ? (
                <>
                  <img
                    className="home-header-hamburger-icon-svg safari closed"
                    src={safari_burger_icon}
                    alt="burger icon"
                  />
                  <img
                    className="home-header-hamburger-icon-svg safari open"
                    src={safari_close_icon}
                    alt="burger icon"
                  />
                </>
              ) : (
                <svg
                  className="home-header-hamburger-icon-svg closed"
                  width="1em"
                  height="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="25 25 450 400"
                >
                  <path
                    id="hamburger-top-line"
                    className="home-header-hamburger-line hamburger-top-line closed"
                    strokeWidth="50"
                    strokeLinecap="round"
                    stroke="#fff"
                    fill="none"
                    d="M50,50 L450,50"
                  />
                  <path
                    id="hamburger-middle-line"
                    className="home-header-hamburger-line hamburger-middle-line closed"
                    strokeWidth="50"
                    strokeLinecap="round"
                    stroke="#fff"
                    fill="none"
                    d="M50,225 L450,225"
                  />
                  <path
                    id="hamburger-bottom-line"
                    className="home-header-hamburger-line hamburger-bottom-line closed"
                    strokeWidth="50"
                    strokeLinecap="round"
                    stroke="#fff"
                    fill="none"
                    d="M50,400 L450,400"
                  />
                </svg>
              )}
            </div>
            <img
              className="home-header-ibcos-logo"
              src={envBanner}
              alt="ibcos logo"
            />
            <div className="home-header-scan-icon-button">
              <ScanIcon className="home-header-scan-icon-svg" />
            </div>
          </div>
        </div>
        <ToastContainer />
        {hamburgerMenuVisible ? (
          <div
            id="main-menu-modal-mask"
            onClick={showHamburgerMenu}
            className="modal-mask"
          ></div>
        ) : null}
      </div>
    </>
  );
};

export default HomeHeader;
