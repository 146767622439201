import { Inclusivity, OrderDirection } from "./PartSearch";

export enum PartsAppPoTransType {
  PurchaseOrder = "PurchaseOrder",
  IDT = "IDT",
  Any = "Any"
}

export interface PurchaseOrderSearch {
  RangeStart: number;
  RangeEnd: number;
  OrderDirection: OrderDirection;
  SearchTerm?: string;
  Depot: string;
  PartiallyReceived: Inclusivity;
  ExactMatch: boolean;
  TransactionType: PartsAppPoTransType;
}

export const defaultPurchaseOrderSearch: PurchaseOrderSearch = {
  RangeStart: 0,
  RangeEnd: 0,
  OrderDirection: OrderDirection.Descending,
  SearchTerm: "",
  Depot: "",
  PartiallyReceived: Inclusivity.Only,
  ExactMatch: false,
  TransactionType: PartsAppPoTransType.PurchaseOrder
};

export default PurchaseOrderSearch;