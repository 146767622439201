import React from "react";
import "./App.css";
import "react-toggle/style.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./modules/login/Login";
import Home from "./modules/home/Home";
import Enquire from "./modules/enquire/Enquire";
import Receive from "./modules/receive/Receive";
import "./modules/Modules.css";
import PurchaseOrder from "./modules/receive/PurchaseOrder/PurchaseOrder";

const App = () => {
  //global animation canceller on resize
  let resizeTimer: NodeJS.Timeout;
  window.addEventListener("resize", () => {
    document.body.classList.add("resize-animation-stopper");
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      document.body.classList.remove("resize-animation-stopper");
    }, 400);
  });

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/enquire" element={<Enquire />} />
          {process.env.REACT_APP_BUILD_ENV?.toUpperCase() != "PLA" ?
          <>
            <Route path="/receive" element={<Receive />} />
            <Route path="/receive/purchaseorder" element={<PurchaseOrder />} />
          </> : null }
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
