import React, { useState, useEffect } from "react";
import "./EditSortBy.css";
import AbstractButton from "../../components/AbstractButton";
import ForegroundColorSet from "../../../utilities/enums/ForegroundColorSet";
import BackgroundColorSet from "../../../utilities/enums/BackgroundColorSet";
import { ReactComponent as BackIcon } from "../../../assets/icons/checkbox-checked-regular.svg";
import { FilterEditorProps } from "../../../interfaces/FilterEditorProps";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { PartClassCode } from "../../../interfaces/SearchFilterCategoryValues";

const EditClassCodes = (props: FilterEditorProps) => {
  const [localSearchData, setLocalSearchData] = useState(props.searchData);

  const myClassCodes = useSelector(
    (state: RootState) =>
      state.root.system.searchFilterCategoryValues.classCodes
  );

  const [classCodes, setClassCodes] = useState<PartClassCode[]>(myClassCodes);
  const [searchText, setSearchText] = useState("");

  const handleSearchTextChange = (event: { target: { value: string } }) => {
    setSearchText(event.target.value);
    if (event.target.value == "") {
      setClassCodes(myClassCodes);
    } else {
      setClassCodes(
        myClassCodes.filter(
          (e: PartClassCode) =>
            e.clcod.toLowerCase().includes(event.target.value.toLowerCase()) ||
            (e.cldes &&
              e.cldes.toLowerCase().includes(event.target.value.toLowerCase()))
        )
      );
    }
  };

  useEffect(() => {
    setLocalSearchData(props.searchData);
  }, [props.searchData]);

  useEffect(() => {
    setClassCodes(myClassCodes);
  }, [myClassCodes]);

  const handleBack = () => {
    props.onCancelCallback(localSearchData);
  };

  const handleCheckboxChange = (
    e: PartClassCode,
    ev: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!ev.target.checked) {
      setLocalSearchData({
        ...localSearchData,
        ClassCodes: localSearchData.ClassCodes.filter(
          (p: string) => p != e.clcod
        ),
      });
    } else {
      setLocalSearchData({
        ...localSearchData,
        ClassCodes: [...localSearchData.ClassCodes, e.clcod],
      });
    }
  };

  const handleClearClick = () => {
    setLocalSearchData({
      ...localSearchData,
      ClassCodes: [],
    });
  };

  return (
    <div
      id="edit-sortby-modal"
      className={`filter-modal-contents ${
        props.visible ? "slide-in" : "slide-out-top"
      }`}
    >
      <div className="flex-items">
        <h1 className="sort-and-filter-header">Class Codes</h1>
      </div>

      <div className="flex-items">
        <hr></hr>
      </div>
      <div className="filter-options-search-container">
        <input
          type="text"
          className="filter-search-box"
          id="class-code-search-box"
          value={searchText}
          placeholder="Search codes..."
          onChange={handleSearchTextChange}
        />
        <button
          id="prefix-clear-checkboxes-button"
          className="clear-selection-button"
          onClick={handleClearClick}
        >
          CLEAR
        </button>
      </div>
      <div className="flex-items">
        <hr></hr>
      </div>
      <div className="filter-options-list-table-container">
        <table id="class-code-container" className="filter-options-list-table">
          <tbody>
            {classCodes.map((e: PartClassCode) => (
              <tr className="filter-options-row" key={e.clcod}>
                <td className="filter-option-label-cell-extended filter-option-label class-code-selection">
                  {e.clcod} - {e.cldes}
                </td>
                <td className="filter-option-value-cell">
                  <input
                    type="checkbox"
                    id={"class-" + (e.cldes ? e.cldes.replace(/\s/g, "") : "")}
                    checked={
                      localSearchData.ClassCodes.filter((f) => f == e.clcod)
                        .length == 1
                    }
                    className="checkbox-style"
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                      handleCheckboxChange(e, ev)
                    }
                  ></input>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex-items">
        <hr></hr>
      </div>

      <div>
        <span className="filter-modal-button-span">
          <AbstractButton
            id="clcod-cancel-button"
            text="Done"
            icon={<BackIcon width="100%" height="100%" />}
            disabled={false}
            activeColor={ForegroundColorSet.Green}
            background={BackgroundColorSet.Black1}
            actionCallback={handleBack}
            specialClass="modal-button"
          />
        </span>
      </div>
    </div>
  );
};

export default EditClassCodes;
