import React, { useState, KeyboardEvent, useEffect } from "react";
import PartsAppPurchaseOrder, {
  FlattenedPoLine,
  PartsAppPurchaseOrderLine,
  SerialInputs,
  defaultPartsAppPurchaseOrder,
  defaultPartsAppPurchaseOrderLine,
  flattenLine,
} from "../../../interfaces/Po";
import { useLocation, useNavigate } from "react-router-dom";
import AbstractButton from "../../components/AbstractButton";
import { ReactComponent as InsertLineIcon } from "../../../assets/icons/window-bullet-list-add-regular.svg";
import { ReactComponent as SortIcon } from "../../../assets/icons/sort-filter.svg";
import { ReactComponent as BackIcon } from "../../../assets/icons/back-chevron.svg";
import { ReactComponent as FinishIcon } from "../../../assets/icons/checkbox-checked-regular.svg";
import { ReactComponent as ScanIcon } from "../../../assets/icons/barcode-scanner.svg";
import ForegroundColorSet from "../../../utilities/enums/ForegroundColorSet";
import BackgroundColorSet from "../../../utilities/enums/BackgroundColorSet";
import TabList, { TabProps } from "../../components/TabList";
import NavBar from "../../components/NavBar";
import ScrollableCardList from "../../components/ScrollableCardList";
import PoLinesCard from "./PoLinesCard";
import PartsAppPurchaseOrderLock from "../../../interfaces/PoLock";
import { apiFetch } from "../../../utilities/AuthenticatedFetch";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import InfoPage from "./InfoPage";
import FinishPage from "./FinishPage";
import { Feature, PartsAppFeature } from "../../../store/slices/authSlice";
import SerialsPage from "./SerialsPage";
import AllocationWarning from "./AllocationWarning";
import GrnStopCredWarning from "./GrnStopCredWarning";

interface PurchaseOrderPageProps {
  order: PartsAppPurchaseOrder;
  depotId: string;
}

const PurchaseOrder = () => {
  const location = useLocation();
  const [order, setOrder] = useState(defaultPartsAppPurchaseOrder);
  const [filteredLines, setFilteredLines] = useState(
    defaultPartsAppPurchaseOrder
  );
  const [searchTerm, setSearchTerm] = useState("");

  const canUseSerialFeatures = useSelector(
    (state: RootState) =>
      state.root.auth.features.filter(
        (e: PartsAppFeature) => e.featureId == Feature.SerialReceive
      ).length > 0
  );
  
  const canSeeUnitCostsForIDT = useSelector(
    (state: RootState) =>
      state.root.auth.permissions.canViewCostPrice
  );

  const [depotId, setDepotId] = useState("1");
  const userId = useSelector((state: RootState) => state.root.auth.id);
  const [selectedLineForInfo, setSelectedLineForInfo] =
    useState<FlattenedPoLine | null>(null);
  const [showInfoPage, setShowInfoPage] = useState(false);
  const [showFinishPage, setShowFinishPage] = useState(false);
  const [showSerialsPage, setShowSerialsPage] = useState(false);
  const [showAllocationWarning, setShowAllocationWarning] = useState(false);
  const [showGrnStopCredWarning, setShowGrnStopCredWarning] = useState(false);
  const [lineForSerialInput, setLineForSerialInput] =
    useState<PartsAppPurchaseOrderLine>(defaultPartsAppPurchaseOrderLine);

  const [problemPart, setProblemPart] = useState<string>("");
  const [problemCustomer, setProblemCustomer] = useState<string>("");
  const [isStop, setIsStop] = useState<boolean>(false);

  useEffect(() => {
    const state = location.state as PurchaseOrderPageProps;
    if (state && state.order) {
      setOrder(state.order);
      setFilteredLines(state.order);
    }

    if (state && state.depotId) {
      setDepotId(state.depotId);
    }
    const stoppedLines = state.order.lines.filter(
      (e) =>
        e.customerStop &&
        e.depotLines.filter((f) => f.delivered != f.ordered).length > 0
    );
    if (stoppedLines.length > 0) {
      setIsStop(true);
      setProblemCustomer(stoppedLines[0].customerStopLimitAccNo);
      setProblemPart(stoppedLines[0].partNumber);
      setShowGrnStopCredWarning(true);
      return;
    }
    const credLines = state.order.lines.filter(
      (e) =>
        e.customerCreditLimit &&
        e.depotLines.filter((f) => f.delivered != f.ordered).length > 0
    );

    if (credLines.length > 0) {
      setIsStop(false);
      setProblemCustomer(credLines[0].customerStopLimitAccNo);
      setProblemPart(credLines[0].partNumber);
      setShowGrnStopCredWarning(true);
    }
  }, [location]);

  useEffect(() => {
    if (filteredLines.orderNumber != "") {
      setFilteredLines(filteredLines);
    }
  }, [filteredLines]);

  const navigate = useNavigate();

  const handleBackPress = () => {
    deletePurchaseLock();
  };

  function deletePurchaseLock() {
    apiFetch<PartsAppPurchaseOrderLock>(
      "/partsapp/receive/DeleteLockedPurchaseOrder/" + userId,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).finally(() => {
      navigate("/receive");
    });
  }

  const tabList: TabProps[] = [
    {
      index: 0,
      label: "Items",
      callback: () => {
        return;
      },
      enabled: true,
    },
    {
      index: 1,
      label: "GRN options",
      callback: () => {
        return;
      },
      enabled: false,
    },
  ];

  const scroll = (line: PartsAppPurchaseOrderLine) => {
    const section = document.querySelector("#part-card" + line.lineNumber);
    section?.scrollIntoView({ behavior: "auto", block: "start" });
  };

  const updateOrderSerials = (
    lineNumber: number | undefined,
    localSerials: SerialInputs[]
  ) => {
    const lineIndex = order.lines.findIndex(
      (line) => line.lineNumber === lineNumber
    );
    order.lines[lineIndex].depotLines.filter(
      (d) => d.depot == depotId
    )[0].serialLines = localSerials;
    setOrder(order);
  };

  const updateCost = (line: FlattenedPoLine) => {
    const indexLineToUpdate = order.lines.findIndex(
      (item) => item.partNumber === line.partNumber
    );
    order.lines[indexLineToUpdate].unitCost = line.unitCost;
    order.lines[indexLineToUpdate].netCost = line.netCost;
    setOrder(order);
  };

  const changeSearchTerm = (event: { target: { value: string } }) => {
    const term = event.target.value;
    setSearchTerm(term);
  };

  const checkForReturn = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.key === "Go" || e.key === "Next") {
      const searchBar = document.getElementById(
        "search-term-input-text"
      ) as HTMLElement;
      searchBar.blur();
    }
  };

  const toggleInfoPage = (line: FlattenedPoLine | null) => {
    setShowInfoPage(!showInfoPage);
    setSelectedLineForInfo(line);
  };

  const toggleSerialsPage = (line: PartsAppPurchaseOrderLine) => {
    setLineForSerialInput(line);
    setShowSerialsPage(!showSerialsPage);
  };

  function doSearch() {
    const newLines = { ...order };
    newLines.lines = newLines.lines.filter((e) => {
      return (
        e.partNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
        e.partDescription.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
    setFilteredLines(newLines);
  }

  return (
    <>
      <FinishPage
        userId={userId}
        order={order}
        showFinishPage={showFinishPage}
        onCancel={() => setShowFinishPage(false)}
        depotId={depotId}
        editIconClicked={function (line: PartsAppPurchaseOrderLine): void {
          scroll(line);
          order.lines.filter(
            (e) => e.lineNumber == line.lineNumber
          )[0].expandTrigger = true;
          setShowFinishPage(false);
        }}
      />
      <SerialsPage
        showSerialsPage={showSerialsPage}
        line={lineForSerialInput}
        depotId={depotId}
        onCancel={() => setShowSerialsPage(false)}
        onSave={updateOrderSerials}
      />
      <AllocationWarning
        showAllocationsWarning={showAllocationWarning}
        onCancel={() => setShowAllocationWarning(false)}
      />
      <GrnStopCredWarning
        showStopCredWarning={showGrnStopCredWarning}
        problemPart={problemPart}
        problemCustomer={problemCustomer}
        isStop={isStop}
        onCancel={function (): void {
          handleBackPress();
        }}
        onContinue={function (): void {
          setShowGrnStopCredWarning(false);
        }}
      />
      <InfoPage
        lineForDepot={selectedLineForInfo}
        showInfoPage={showInfoPage}
        toggleInfoPage={() => {
          toggleInfoPage(null);
        }}
        updateCost={updateCost}
      />
      <div id="receive-container" className="module-container">
        <div id="receive-nav-top" className="module-nav nav-top">
          <div className="module-nav-button top">
            <AbstractButton
              id="receive-nav-back-button"
              text="Leave"
              icon={<BackIcon className="module-nav-button-logo" />}
              activeColor={ForegroundColorSet.Blue}
              background={BackgroundColorSet.Black1}
              specialClass={null}
              disabled={false}
              actionCallback={() => handleBackPress()}
            />
          </div>
          <div className="module-nav-button top">
            <AbstractButton
              id="receive-nav-sort-button"
              text="Filter"
              specialClass={null}
              icon={<SortIcon className="module-nav-button-logo" />}
              activeColor={ForegroundColorSet.Black3}
              background={BackgroundColorSet.Black1}
              disabled={true}
              actionCallback={function (): unknown {
                throw new Error("Function not implemented.");
              }}
            />
          </div>
          <div className="module-nav-button top">
            <AbstractButton
              id="receive-nav-options-button"
              text="+&nbsp;Line"
              specialClass={null}
              icon={<InsertLineIcon className="module-nav-button-logo" />}
              activeColor={ForegroundColorSet.Black3}
              background={BackgroundColorSet.Black1}
              disabled={false}
              actionCallback={function (): unknown {
                throw new Error("Function not implemented.");
              }}
            />
          </div>
          <div className="module-nav-button top">
            <AbstractButton
              id="receive-nav-save-button"
              text="Apply"
              specialClass={null}
              icon={<FinishIcon className="module-nav-button-logo" />}
              disabled={false}
              activeColor={ForegroundColorSet.Green}
              background={BackgroundColorSet.Black1}
              actionCallback={() => setShowFinishPage(true)}
            />
          </div>
        </div>
        <div id="receive-tab-container" className="search-results">
          <TabList tabs={tabList} twoOptions={true} isDisabled={false} />
          <div className="purchase-order-page-header-line">
            <div className="purchase-order-page-header-po">
              {order.isIDT ? "IDT":"PO"}#{order.orderNumber}&nbsp;
            </div>
            <div className="purchase-order-page-header-desc">
              {order.description}&nbsp;
            </div>
            <div className="purchase-order-page-header-lines">
              {order.linesDelivered}/{order.linesTotal}
            </div>
          </div>
        </div>
        <div
          id="receive-search-results"
          className={`receive-search-results pos-lines`}
        >
          <ScrollableCardList
            fetchMoreResults={() => {
              throw new Error("Function not implemented.");
            }}
            renderItem={(line) => (
              <PoLinesCard
                key={order.lines.indexOf(line) + "-" + line.partNumber}
                poLine={line}
                canUseSerialFeatures={canUseSerialFeatures}
                depotId={depotId}
                isIDT={order.isIDT}
                toggleInfoPage={() => {
                  toggleInfoPage(flattenLine(line, depotId));
                }}
                canSeeUnitCostForIDTs={canSeeUnitCostsForIDT}
                showSerialsPage={() => {
                  if (canUseSerialFeatures && line.flattenedSingleLine) {
                    if (
                      // initialise serial array
                      line.depotLines.filter((d) => d.depot === depotId)[0]
                        .serialLines == undefined ||
                      line.depotLines.filter((d) => d.depot === depotId)[0]
                        .serialLines.length != line.flattenedSingleLine.received
                    ) {
                      line.depotLines.filter(
                        (d) => d.depot === depotId
                      )[0].serialLines = Array(
                        line.flattenedSingleLine.received
                      )
                        .fill("")
                        .map(() => ({ serialNumber: "" }));
                    }
                    toggleSerialsPage(line);
                  }
                }}
                showAllocationsWarning={() => {
                  setShowAllocationWarning(true);
                }}
              />
            )}
            initialItems={filteredLines.lines ?? []}
            isFetching={false}
            loadsLazily={false}
          />
        </div>
      </div>
      <div
        id="receive-search-input-wrapper"
        className="module-search search-input"
      >
        <input
          id="search-term-input-text"
          className="module-search-term-input-text"
          type="text"
          placeholder="&#x1F50D; Search..."
          onKeyDown={checkForReturn}
          onChange={changeSearchTerm}
          onBlur={doSearch}
          autoCorrect="off"
          autoCapitalize="none"
          autoComplete="off"
          spellCheck="false"
        />
        <button
          id="receive-search-barcode-button"
          className="module-search-barcode-button greyed-out"
        >
          <ScanIcon className="module-search-barcode-button-logo" />
        </button>
      </div>
      <div
        id="enquire-search-input-curtain"
        className="search-bar-safe-area-curtain"
      />
      <NavBar activePage={"receive"}></NavBar>
    </>
  );
};

export default PurchaseOrder;
