import React, { useEffect, useState } from "react";
import { PartsAppPartOrdered } from "../../../interfaces/PartsAppPart";
import edit from "../../../assets/icons/edit-bluepencil.svg";
import editgrey from "../../../assets/icons/edit-greypencil.svg";
import "./PartDetails.css";

interface PartDetailPageProps {
  part: PartsAppPartOrdered;
  canChangeBinLocation: boolean;
  binLocationUpdating: boolean;
  isViewingOffline: boolean;
  onEditBinClicked(): void;
}

const PartDetailsDepotInfo = (props: PartDetailPageProps) => {
  const ConvertDate = (date: Date): string => {
    if (new Date(date).getFullYear() == 1 || new Date(date).getFullYear() == 0)
      return "";
    else return new Date(date).toLocaleDateString("en-GB");
  };
  const DaysYearsAgo = (date: Date): string => {
    const now = new Date();
    const timeDiff = now.getTime() - date.getTime();
    const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));
    const yearsDiff = Number((timeDiff / (1000 * 3600 * 24 * 365)).toFixed(1));

    if (daysDiff < 365) {
      return `(${daysDiff} days)`;
    } else {
      return `(${yearsDiff} years)`;
    }
  };

  const intToMonth = (monthInt: number): string => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return monthNames[monthInt];
  };

  const [year1SalesData, setYear1SalesData] = useState<number[]>([]);
  const [year2SalesData, setYear2SalesData] = useState<number[]>([]);
  const [year3SalesData, setYear3SalesData] = useState<number[]>([]);
  const [year4SalesData, setYear4SalesData] = useState<number[]>([]);

  const [year1HitsData, setYear1HitsData] = useState<number[]>([]);
  const [year2HitsData, setYear2HitsData] = useState<number[]>([]);
  const [year3HitsData, setYear3HitsData] = useState<number[]>([]);
  const [year4HitsData, setYear4HitsData] = useState<number[]>([]);

  useEffect(() => {
    const yearSales = [
      props.part.salesRecord1,
      props.part.salesRecord2,
      props.part.salesRecord3,
      props.part.salesRecord4,
      props.part.salesRecord5,
      props.part.salesRecord6,
      props.part.salesRecord7,
      props.part.salesRecord8,
      props.part.salesRecord9,
      props.part.salesRecord10,
      props.part.salesRecord11,
      props.part.salesRecord12,
      props.part.salesRecord13,
      props.part.salesRecord14,
      props.part.salesRecord15,
      props.part.salesRecord16,
      props.part.salesRecord17,
      props.part.salesRecord18,
      props.part.salesRecord19,
      props.part.salesRecord20,
      props.part.salesRecord21,
      props.part.salesRecord22,
      props.part.salesRecord23,
      props.part.salesRecord24,
      props.part.salesRecord25,
      props.part.salesRecord26,
      props.part.salesRecord27,
      props.part.salesRecord28,
      props.part.salesRecord29,
      props.part.salesRecord30,
      props.part.salesRecord31,
      props.part.salesRecord32,
      props.part.salesRecord33,
      props.part.salesRecord34,
      props.part.salesRecord35,
      props.part.salesRecord36,
    ];

    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    let thisMonth = currentMonth;
    const year1 = [];
    const year2 = [];
    const year3 = [];
    const year4 = [];
    let thisRec = 0;
    //CurrentYear
    while (thisMonth > 0) {
      year1.push(yearSales[thisRec]);
      thisMonth -= 1;
      thisRec += 1;
    }
    thisMonth = 12;
    while (thisMonth > 0) {
      year2.push(yearSales[thisRec]);
      thisMonth -= 1;
      thisRec += 1;
    }
    thisMonth = 12;
    while (thisMonth > 0) {
      year3.push(yearSales[thisRec]);
      thisMonth -= 1;
      thisRec += 1;
    }
    thisMonth = 12;
    while (thisMonth > 0 && thisRec < 36) {
      year4.push(yearSales[thisRec]);
      thisMonth -= 1;
      thisRec += 1;
    }
    year1.reverse();
    year2.reverse();
    year3.reverse();
    year4.reverse();
    setYear1SalesData(year1.reverse());
    setYear2SalesData(year2.reverse());
    setYear3SalesData(year3.reverse());
    setYear4SalesData(year4.reverse());

    const yearHits = [
      props.part.hitsRecord1,
      props.part.hitsRecord2,
      props.part.hitsRecord3,
      props.part.hitsRecord4,
      props.part.hitsRecord5,
      props.part.hitsRecord6,
      props.part.hitsRecord7,
      props.part.hitsRecord8,
      props.part.hitsRecord9,
      props.part.hitsRecord10,
      props.part.hitsRecord11,
      props.part.hitsRecord12,
      props.part.hitsRecord13,
      props.part.hitsRecord14,
      props.part.hitsRecord15,
      props.part.hitsRecord16,
      props.part.hitsRecord17,
      props.part.hitsRecord18,
      props.part.hitsRecord19,
      props.part.hitsRecord20,
      props.part.hitsRecord21,
      props.part.hitsRecord22,
      props.part.hitsRecord23,
      props.part.hitsRecord24,
      props.part.hitsRecord25,
      props.part.hitsRecord26,
      props.part.hitsRecord27,
      props.part.hitsRecord28,
      props.part.hitsRecord29,
      props.part.hitsRecord30,
      props.part.hitsRecord31,
      props.part.hitsRecord32,
      props.part.hitsRecord33,
      props.part.hitsRecord34,
      props.part.hitsRecord35,
      props.part.hitsRecord36,
    ];

    const hitsYear1 = [];
    const hitsYear2 = [];
    const hitsYear3 = [];
    const hitsYear4 = [];

    thisMonth = currentMonth;
    thisRec = 0;
    //CurrentYear
    while (thisMonth > 0) {
      hitsYear1.push(yearHits[thisRec]);
      thisMonth -= 1;
      thisRec += 1;
    }
    thisMonth = 12;
    while (thisMonth > 0) {
      hitsYear2.push(yearHits[thisRec]);
      thisMonth -= 1;
      thisRec += 1;
    }
    thisMonth = 12;
    while (thisMonth > 0) {
      hitsYear3.push(yearHits[thisRec]);
      thisMonth -= 1;
      thisRec += 1;
    }
    thisMonth = 12;
    while (thisMonth > 0 && thisRec < 36) {
      hitsYear4.push(yearHits[thisRec]);
      thisMonth -= 1;
      thisRec += 1;
    }

    hitsYear1.reverse();
    hitsYear2.reverse();
    hitsYear3.reverse();
    hitsYear4.reverse();

    setYear1HitsData(hitsYear1.reverse());
    setYear2HitsData(hitsYear2.reverse());
    setYear3HitsData(hitsYear3.reverse());
    setYear4HitsData(hitsYear4.reverse());
  }, [props.part]);

  return (
    <div id="part-details-basic-info" className={process.env.REACT_APP_BUILD_ENV?.toUpperCase() != "PLA" ? `part-details-content` : `part-details-content-pla`}>
      <div className="part-details-header">
        <span
          id="depot-details-part-number"
          className="part-details-part-number"
        >
          {props.part.partNumber}
        </span>
      </div>
      <div id="depot-details-description" className="part-details-description">
        {props.part.description}
      </div>
      <div
        id="depot-details-bin"
        className={
          `part-details-description bin-details` +
          (props.binLocationUpdating ? ` black4-foreground` : null)
        }
      >
        <b>Bin</b>: {props.part.bin} &nbsp;
        {props.canChangeBinLocation && !props.isViewingOffline && process.env.REACT_APP_BUILD_ENV?.toUpperCase() != "PLA"  ? (
          !props.binLocationUpdating ? (
            <img
              onClick={() => props.onEditBinClicked()}
              src={edit}
              className="details-edit-pencil"
              alt=""
            />
          ) : (
            <img src={editgrey} className="details-edit-pencil" alt="" />
          )
        ) : null}
      </div>
      <div className="hr-container">
        <span className="hr-label">Stock</span>
        <hr className="hr-line" />
      </div>
      <table className="part-details-table">
        <tr>
          <td id="depot-details-stock" className="part-details-2col-field">
            <b>Stock</b>: {props.part.inStock}
          </td>
          <td id="depot-details-free" className="part-details-2col-field">
            <b>Free</b>: {props.part.freeStock} ({props.part.freeStockCollections})
          </td>
        </tr>
        <tr>
          <td id="depot-details-vans" className="part-details-2col-field">
            <b>Vans</b>: {props.part.vanStock}
          </td>
          <td id="depot-details-emergency" className="part-details-2col-field">
            <b>Emergency</b>: {props.part.emergencyStock}
          </td>
        </tr>
        <tr>
          <td id="depot-details-wip" className="part-details-2col-field">
            <b>WiP</b>: {props.part.wipStock} ({props.part.wipStockCredits})
          </td>
          <td id="depot-details-depth" className="part-details-2col-field">
            <b>Depth</b>: {props.part.stockDepth}
          </td>
        </tr>
        <tr>
          <td id="depot-details-pos-wip" className="part-details-2col-field">
            <b>PoS WiP</b>: {props.part.poswip}
          </td>
          <td id="depot-details-wsj-wip" className="part-details-2col-field">
            <b>WSJ WiP</b>: {props.part.wsjwip}
          </td>
        </tr>
        <tr>
          <td id="depot-details-min" className="part-details-2col-field">
            <b>Min</b>: {props.part.minStock}
          </td>
          <td id="depot-details-max" className="part-details-2col-field">
            <b>Max</b>: {props.part.maxStock}
          </td>
        </tr>
      </table>
      <div id="depot-details-check" className="part-details-description">
        <b> Check </b>:{" "}
        {new Date(props.part.stockLastChecked).getFullYear() == 1
          ? ""
          : "Completed"}{" "}
        {ConvertDate(props.part.stockLastChecked)}
      </div>
      <div className="hr-container">
        <span className="hr-label">Orders</span>
        <hr className="hr-line" />
      </div>
      <table className="part-details-table">
        <tr>
          <td id="depot-details-ordered" className="part-details-2col-field">
            <b>Ordered</b>: {props.part.ordered}
          </td>
          <td id="depot-details-req-for-po" className="part-details-2col-field">
            <b>Req. for PO</b>: {props.part.orderReqForPO}
          </td>
        </tr>
        <tr>
          <td
            id="depot-details-req-for-vans"
            className="part-details-2col-field"
          >
            <b>Req. for vans</b>: {props.part.orderReqForVans}
          </td>
          <td id="depot-details-allocated" className="part-details-2col-field">
            <b>Allocated</b>: {props.part.orderAllocated}
          </td>
        </tr>
        <tr>
          <td
            id="depot-details-req-no-alloc"
            className="part-details-2col-field"
          >
            <b>Req. not alloc</b>: {props.part.orderReqNotAlloc}
          </td>
          <td
            id="depot-details-revd-no-inv"
            className="part-details-2col-field"
          >
            <b>Revd. no inv</b>: {props.part.orderReceivedNoInvoice}
          </td>
        </tr>
        <tr>
          <td id="depot-details-sgest-main" className="part-details-2col-field">
            <b>S&lsquo;gest main</b>: {props.part.orderSuggestedMain}
          </td>
          <td id="depot-details-sgest-add" className="part-details-2col-field">
            <b>S&lsquo;gest add&lsquo;nal</b>:{" "}
            {props.part.orderSuggestedAdditional}
          </td>
        </tr>
      </table>
      <div className="hr-container">
        <span className="hr-label">IDT</span>
        <hr className="hr-line" />
      </div>
      <table className="part-details-table">
        <tr>
          <td id="depot-details-idt-in" className="part-details-3col-field">
            <b>IDT in</b>: {props.part.idtIn}
          </td>
          <td id="depot-details-idt-out" className="part-details-3col-field">
            <b>IDT out</b>: {props.part.idtOut}
          </td>
          <td id="depot-details-idt-free" className="part-details-3col-field">
            <b>IDT free</b>: {props.part.idtFree}
          </td>
        </tr>
      </table>
      <div className="hr-container">
        <span id="depot-details-age" className="hr-label">
          Age
        </span>
        <hr className="hr-line" />
      </div>
      <div id="depot-details-last-sold" className="part-details-description">
        <b> Last sold</b>: {ConvertDate(props.part.lastSold)}{" "}
        {DaysYearsAgo(new Date(props.part.lastSold))}
      </div>
      <div
        id="depot-details-last-purchased"
        className="part-details-description"
      >
        <b> Last purchased</b>: {ConvertDate(props.part.lastPurchased)}{" "}
        {DaysYearsAgo(new Date(props.part.lastPurchased))}
      </div>
      <br />
      <div className="hr-container">
        <span className="hr-label">Sales</span>
        <hr className="hr-line" />
      </div>
      <br />
      <div className="part-details-price-table-container">
        <table id="sales-table" className="part-details-table">
          <tr>
            <td className="part-details-price-field">
              <b>SALES</b>
            </td>
            {year1SalesData.map((e: number, index: number) => (
              <td
                key={year1SalesData.length - index - 1}
                className="part-details-price-field"
              >
                <b>{intToMonth(year1SalesData.length - index - 1)}</b>
              </td>
            ))}
            <td className="part-details-price-field"></td>
            {year2SalesData
              .slice(0, year2SalesData.length - year1SalesData.length)
              .map((e: number, index: number) => (
                <td
                  key={year2SalesData.length - index - 1}
                  className="part-details-price-field"
                >
                  <b>{intToMonth(year2SalesData.length - index - 1)}</b>
                </td>
              ))}
          </tr>
          <tr>
            <td className="part-details-price-field">
              {new Date().getFullYear()}
            </td>
            {year1SalesData.map((e: number, index: number) => (
              <td
                key={year1SalesData.length - index - 1}
                className="part-details-price-field"
              >
                <b>{year1SalesData[index]}</b>
              </td>
            ))}
            <td className="part-details-price-field">
              {new Date().getFullYear() - 1}
            </td>
            {year2SalesData
              .slice(0, year2SalesData.length - year1SalesData.length)
              .map((e: number, index: number) => (
                <td
                  key={year2SalesData.length - index - 1}
                  className="part-details-price-field"
                >
                  <b>{year2SalesData[index]}</b>
                </td>
              ))}
          </tr>
          <tr>
            <td className="part-details-price-field">
              {new Date().getFullYear() - 1}
            </td>
            {year2SalesData
              .slice(-year1SalesData.length)
              .map((e: number, index: number) => (
                <td
                  key={year2SalesData.length - index - 1}
                  className="part-details-price-field"
                >
                  <b>{e}</b>
                </td>
              ))}
            <td className="part-details-price-field">
              {new Date().getFullYear() - 2}
            </td>
            {year3SalesData
              .slice(0, year3SalesData.length - year1SalesData.length)
              .map((e: number, index: number) => (
                <td
                  key={year3SalesData.length - index - 1}
                  className="part-details-price-field"
                >
                  <b>{year3SalesData[index]}</b>
                </td>
              ))}
          </tr>
          <tr>
            <td className="part-details-price-field">
              {new Date().getFullYear() - 2}
            </td>
            {year3SalesData
              .slice(-year1SalesData.length)
              .map((e: number, index: number) => (
                <td
                  key={year3SalesData.length - index - 1}
                  className="part-details-price-field"
                >
                  <b>{e}</b>
                </td>
              ))}
            <td className="part-details-price-field">
              {new Date().getFullYear() - 3}
            </td>
            {year4SalesData.map((e: number, index: number) => (
              <td
                key={year4SalesData.length - index - 1}
                className="part-details-price-field"
              >
                <b>{year4SalesData[index]}</b>
              </td>
            ))}
          </tr>
        </table>
      </div>
      <br />
      <div className="part-details-price-table-container">
        <table id="hits-table" className="part-details-table">
          <tr>
            <td className="part-details-price-field">
              <b>HITS</b>
            </td>
            {year1HitsData.map((e: number, index: number) => (
              <td
                key={year1HitsData.length - index - 1}
                className="part-details-price-field"
              >
                <b>{intToMonth(year1HitsData.length - index - 1)}</b>
              </td>
            ))}
            <td className="part-details-price-field"></td>
            {year2HitsData
              .slice(0, year2HitsData.length - year1HitsData.length)
              .map((e: number, index: number) => (
                <td
                  key={year2HitsData.length - index - 1}
                  className="part-details-price-field"
                >
                  <b>{intToMonth(year2HitsData.length - index - 1)}</b>
                </td>
              ))}
          </tr>
          <tr>
            <td className="part-details-price-field">
              {new Date().getFullYear()}
            </td>
            {year1HitsData.map((e: number, index: number) => (
              <td
                key={year1HitsData.length - index - 1}
                className="part-details-price-field"
              >
                <b>{year1HitsData[index]}</b>
              </td>
            ))}
            <td className="part-details-price-field">
              {new Date().getFullYear() - 1}
            </td>
            {year2HitsData
              .slice(0, year2HitsData.length - year1HitsData.length)
              .map((e: number, index: number) => (
                <td
                  key={year2HitsData.length - index - 1}
                  className="part-details-price-field"
                >
                  <b>{year2HitsData[index]}</b>
                </td>
              ))}
          </tr>
          <tr>
            <td className="part-details-price-field">
              {new Date().getFullYear() - 1}
            </td>
            {year2HitsData
              .slice(-year1HitsData.length)
              .map((e: number, index: number) => (
                <td
                  key={year2HitsData.length - index - 1}
                  className="part-details-price-field"
                >
                  <b>{e}</b>
                </td>
              ))}
            <td className="part-details-price-field">
              {new Date().getFullYear() - 2}
            </td>
            {year3HitsData
              .slice(0, year3HitsData.length - year1HitsData.length)
              .map((e: number, index: number) => (
                <td
                  key={year3HitsData.length - index - 1}
                  className="part-details-price-field"
                >
                  <b>{year3HitsData[index]}</b>
                </td>
              ))}
          </tr>
          <tr>
            <td className="part-details-price-field">
              {new Date().getFullYear() - 2}
            </td>
            {year3HitsData
              .slice(-year1HitsData.length)
              .map((e: number, index: number) => (
                <td
                  key={year3HitsData.length - index - 1}
                  className="part-details-price-field"
                >
                  <b>{e}</b>
                </td>
              ))}
            <td className="part-details-price-field">
              {new Date().getFullYear() - 3}
            </td>
            {year4HitsData.map((e: number, index: number) => (
              <td
                key={year4HitsData.length - index - 1}
                className="part-details-price-field"
              >
                <b>{year4HitsData[index]}</b>
              </td>
            ))}
          </tr>
        </table>
      </div>
    </div>
  );
};

export default PartDetailsDepotInfo;
