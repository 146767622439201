import PartsAppPartOrdered from "./PartsAppPart";

export interface Preferences {
  id: number;
  userId: string;
  companyId: number;
  defaultDepotId: string;
  preferExternalScanner: boolean;
  recentParts: PartsAppPartOrdered[];
}

export const defaultPrefs: Preferences = {
  id: 0,
  userId: "",
  companyId: 0,
  defaultDepotId: "",
  preferExternalScanner: false,
  recentParts: [],
};
