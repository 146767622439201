import React, { useState, useEffect } from "react";
import "./EditSortBy.css";
import AbstractButton from "../../components/AbstractButton";
import ForegroundColorSet from "../../../utilities/enums/ForegroundColorSet";
import BackgroundColorSet from "../../../utilities/enums/BackgroundColorSet";
import { ReactComponent as BackIcon } from "../../../assets/icons/checkbox-checked-regular.svg";
import {
  OrderDirection,
  PartSearchOrderOption,
} from "../../../interfaces/PartSearch";
import { FilterEditorProps } from "../../../interfaces/FilterEditorProps";
import Toggle from "react-toggle";

const EditSortBy = (props: FilterEditorProps) => {
  const [localSearchData, setLocalSearchData] = useState(props.searchData);

  useEffect(() => {
    setLocalSearchData(props.searchData);
  }, [props.searchData]);

  const handleSortChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalSearchData({
      ...localSearchData,
      Order: event.target.value as PartSearchOrderOption,
    });
  };

  const handleDirectionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocalSearchData({
      ...localSearchData,
      OrderDirection: event.target.value as OrderDirection,
    });
  };

  const handleBack = () => {
    props.onCancelCallback(localSearchData);
  };

  const handleSmartSearchChange = () => {
    setLocalSearchData({
      ...localSearchData,
      SmartSearch: !localSearchData.SmartSearch,
    });
  };

  return (
    <div
      id="edit-sortby-modal"
      className={`filter-modal-contents ${
        props.visible ? "slide-in" : "slide-out-top"
      }`}
    >
      <div className="flex-items">
        <h1 className="sort-and-filter-header">Sort By</h1>
      </div>
      <div className="flex-items">
        <hr></hr>
      </div>
      <table className="filter-options-toggle-table">
        <tbody>
          <tr className="filter-options-toggle-row sort-by">
            <td className="filter-option-toggle-label-cell">
              <label className="smart-sort-label">Smart Search</label>
            </td>
            <td className="filter-option-toggle-value-cell">
              <Toggle
                id="smart-sort-control"
                icons={false}
                className="large-toggle"
                defaultChecked={localSearchData.SmartSearch}
                onChange={handleSmartSearchChange}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <div className="flex-items">
        <hr></hr>
      </div>
      <table
        className="filter-options-table"
        data-disabled={localSearchData.SmartSearch}
      >
        <tbody>
          {Object.values(PartSearchOrderOption).map((option) => (
            <tr className="sort-options-row sort-by" key={option}>
              <td className="filter-option-label-cell-extended">
                <label className="filter-option-label" htmlFor={option}>
                  {option.split(/(?=[A-Z])/).join(" ")}
                </label>
              </td>
              <td className="filter-option-value-cell">
                <input
                  type="radio"
                  name="order-group"
                  className="checkbox-style"
                  value={option}
                  data-disabled={localSearchData.SmartSearch}
                  disabled={localSearchData.SmartSearch}
                  checked={localSearchData.Order === option}
                  onChange={handleSortChange}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex-items">
        <hr></hr>
      </div>

      <table
        className="filter-options-table"
        data-disabled={localSearchData.SmartSearch}
      >
        <tbody>
          {Object.values(OrderDirection).map((option) => (
            <tr className="sort-options-row sort-by" key={option}>
              <td className="filter-option-label-cell-extended">
                <label className="filter-option-label" htmlFor={option}>
                  {option == OrderDirection.Ascending ? "A-Z" : "Z-A"}
                </label>
              </td>
              <td className="filter-option-value-cell">
                <input
                  type="radio"
                  name="direction-group"
                  className="checkbox-style"
                  value={option}
                  disabled={localSearchData.SmartSearch}
                  data-disabled={localSearchData.SmartSearch}
                  checked={localSearchData.OrderDirection === option}
                  onChange={handleDirectionChange}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex-items">
        <hr></hr>
      </div>

      <div>
        <span className="filter-modal-button-span">
          <AbstractButton
            id="sort-okay-button"
            text="Done"
            icon={<BackIcon width="100%" height="100%" />}
            disabled={false}
            activeColor={ForegroundColorSet.Green}
            background={BackgroundColorSet.Black1}
            actionCallback={handleBack}
            specialClass="modal-button"
          />
        </span>
      </div>
    </div>
  );
};

export default EditSortBy;
