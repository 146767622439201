import React from "react";
import { PartsAppPurchaseOrder } from "../../interfaces/Po";
import { ReactComponent as LockIcon } from "../../assets/icons/lock-closed-12-filled.svg";

export interface PoCardProperities {
  poClicked(po: PartsAppPurchaseOrder): void;
  po: PartsAppPurchaseOrder;
}

const PoCard = (props: PoCardProperities) => {
  return (
    <div
      id={props.po.orderNumber + "-part-card"}
      key={props.po.orderNumber}
      onClick={() => { props.po.lockedBy != '' ? undefined : props.poClicked(props.po) }}
      className={`product-order-list-item`}
    >
      <div className={`product-order-header`}>
        <span className="po-part-name">{props.po.orderNumber}
          {props.po.lockedBy != '' ?
            <span>
              {props.po.lockedBy}&nbsp;&nbsp;<LockIcon></LockIcon>
            </span>
            : ''}
        </span>
        <span className="part-description">
          {props.po.description != null
            ? props.po.description
            : "No Description"}
        </span>
        <span className="part-description">Ref: {props.po.reference}</span>
      </div>
      <div className="purchase-order-values">
        <div className="purchase-order-value">
          <div className="part-item-value-label">Date: </div>
          <div className="part-item-value-content">
            &nbsp;{new Date(props.po.date).toLocaleDateString("en-GB")}
          </div>
        </div>
        <div className="purchase-order-value">
          <div className="part-item-value-label"></div>
          <div className="part-item-value-content"></div>
        </div>
      </div>
    </div>
  );
};

export default PoCard;
