import { ReactComponent as EnquireIcon } from "../../assets/icons/text-bullet-list-square-search-20-regular.svg";
import { ReactComponent as ReceiveIcon } from "../../assets/icons/tray-item-add-20-regular.svg";
import { ReactComponent as PickIcon } from "../../assets/icons/tray-item-remove-24-regular.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/task-list-square-rtl-24-regular.svg";
import { ReactComponent as HomeIcon } from "../../assets/icons/home-48-regular.svg";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Feature, PartsAppFeature } from "../../store/slices/authSlice";

interface NavBarProps {
  activePage: "enquire" | "home" | "receive" | "check" | "pick";
}

const NavBar = (props: NavBarProps) => {
  const navigate = useNavigate();
  
  const canAccessReceive = useSelector((state: RootState) => state.root.auth.features.filter((e: PartsAppFeature) => e.featureId == Feature.Receive).length > 0);

  const handleHomePress = () => {
    navigate("/");
  };
  const handleEnquirePress = () => {
    navigate("/enquire");
  };
  const handleReceivePress = () => {
    if(canAccessReceive)
    {
      navigate("/receive");
    }
  };


  return (
    <div className="nav-footer">
      <div id="nav-bottom" className="module-nav nav-bottom">
        <button id="nav-home-button" className="module-nav-button bottom">
          <HomeIcon
            onClick={() => handleHomePress()}
            className="module-nav-button-logo"
          />
          <div className="module-nav-button-label bottom">Home</div>
        </button>
        <button id="nav-enquire-button" className="module-nav-button bottom">
          <EnquireIcon
            onClick={() => {
              switch (props.activePage) {
                case "enquire":
                  break;
                default:
                  handleEnquirePress();
              }
            }}
            className={
              "module-nav-button-logo" +
              (props.activePage == "enquire" ? " active-tab" : "")
            }
          />
          <div className="module-nav-button-label bottom">Enquire</div>
        </button>
        <button id="nav-receive-button" className="module-nav-button bottom">
          <ReceiveIcon
            onClick={() => {
              switch (props.activePage) {
                case "receive":
                  break;
                default:
                  handleReceivePress();
              }
            }}
            className={
              "module-nav-button-logo" +
              (props.activePage == "receive" ? " active-tab" : "")
            }
          />
          <div className="module-nav-button-label bottom">Receive</div>
        </button>
        <button id="nav-check-button" className="module-nav-button bottom">
          <CheckIcon
            onClick={() => {
              switch (props.activePage) {
                case "check":
                  break;
                default:
                  console.log("Page to be implemented");
              }
            }}
            className={
              "module-nav-button-logo" +
              (props.activePage == "check" ? " active-tab" : "")
            }
          />
          <div className="module-nav-button-label bottom">Check</div>
        </button>
        <button id="nav-pick-button" className="module-nav-button bottom">
          <PickIcon
            onClick={() => {
              switch (props.activePage) {
                case "pick":
                  break;
                default:
                  console.log("Page to be implemented");
              }
            }}
            className={
              "module-nav-button-logo" +
              (props.activePage == "pick" ? " active-tab" : "")
            }
          />
          <div className="module-nav-button-label bottom">Pick</div>
        </button>
      </div>
    </div>
  );
};

export default NavBar;
