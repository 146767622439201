import React, { ChangeEvent, useEffect, KeyboardEvent, useState } from "react";
import BackgroundColorSet from "../../../utilities/enums/BackgroundColorSet";
import ForegroundColorSet from "../../../utilities/enums/ForegroundColorSet";
import AbstractButton from "../../components/AbstractButton";
import { ReactComponent as CancelIcon } from "../../../assets/icons/backspace-20-regular.svg";
import { ReactComponent as OkayIcon } from "../../../assets/icons/checkbox-checked-regular.svg";
import {
  defaultPartOrdered,
  PartsAppPartOrdered,
} from "../../../interfaces/PartsAppPart";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import Depot from "../../../interfaces/Depot";

interface UpdateBinProps {
  part: PartsAppPartOrdered;
  visibilityClassName: string;
  visible: boolean;
  onCancelCallback(): void;
  onOkayCallback(newBin: string): void;
}

const BinLocationModal = (props: UpdateBinProps) => {
  const [part, setPart] = useState(defaultPartOrdered);
  const [initialBin, setInitialBin] = useState("");
  const [depotName, setDepotName] = useState("");

  const depotId = useSelector(
    (state: RootState) => state.root.auth.defaultDepotId
  );
  const allDepots = useSelector((state: RootState) => state.root.system.depots);

  const okayClicked = () => {
    if (part) {
      props.onOkayCallback(part.bin);
      return;
    }
    props.onOkayCallback(part);
  };

  useEffect(() => {
    setPart(props.part);
    setInitialBin(props.part.bin);
  }, [props.part, props.visible]);

  useEffect(() => {
    if (allDepots.length > 0 && depotId != 0) {
      setDepotName(
        allDepots.filter((e: Depot) => e.id == depotId)[0].shortName
      );
    }
  }, [allDepots, depotId]);

  const binTextChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (newValue.length <= 6) {
      setPart({ ...part, bin: newValue });
    } else {
      if ("vibrate" in navigator) {
        navigator.vibrate(50);
      }
    }
  };

  const checkForReturn = (e: KeyboardEvent) => {
    if (e.key === "Enter" || e.key === "Go" || e.key === "Next") {
      const inputBox = document.getElementById("bin-input-box") as HTMLElement;
      inputBox.blur();
    }
  };

  return (
    <div
      id="home-header-change-depot-modal"
      className={props.visibilityClassName}
    >
      <span className="modal-header-text"> Change Bin</span>
      <hr className="modal-header-underline" />
      <div
        id="bin-location-update-explainer-text"
        className="modal-explainer-text"
      >
        New bin location for{" "}
        <b>{props.part.partNumber.replaceAll("-", "\u2011")}</b> at{" "}
        <b>
          Depot {props.part.depot}&nbsp;-&nbsp;{depotName}{" "}
        </b>
      </div>
      <input
        id="bin-input-box"
        className="bin-input-box"
        type="text"
        onChange={binTextChanged}
        onKeyDown={checkForReturn}
        value={part.bin}
      />
      <div className="modal-explainer-text-small">
        Current value: {initialBin}
      </div>
      <span className="modal-button-span">
        <AbstractButton
          id="cancel-button"
          specialClass="modal-button"
          text="Cancel"
          icon={<CancelIcon width="100%" height="100%" />}
          disabled={false}
          activeColor={ForegroundColorSet.Red}
          background={BackgroundColorSet.Black1}
          actionCallback={props.onCancelCallback}
        />
        <AbstractButton
          id="okay-button"
          text="Change"
          specialClass="modal-button"
          icon={<OkayIcon width="100%" height="100%" />}
          disabled={initialBin == part.bin}
          activeColor={ForegroundColorSet.Green}
          background={BackgroundColorSet.Black1}
          actionCallback={okayClicked}
        />
      </span>
    </div>
  );
};

export default BinLocationModal;
