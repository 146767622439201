import React, { useEffect, useState } from "react";
import "./BarcodeCameraScanner.css";
import Quagga, { QuaggaJSResultObject } from "@ericblade/quagga2";
import { isNullOrUndefined } from "../../utilities/Tools";
import PartsAppPart from "../../interfaces/PartsAppPart";
import { apiFetch } from "../../utilities/AuthenticatedFetch";
import { BarcodeSearchRequestModel } from "../../interfaces/PartSearch";

interface BarcodeCameraScannerProps {
  barcodeSearchEndpoint: string;
  depotId: string;
  shouldBeScanning: boolean;
  onSelect(part: PartsAppPart): void;
}

const READ_CONFIDENCE_THRESHOLD = 0.8;

const BarcodeCameraScanner = (props: BarcodeCameraScannerProps) => {
  const [, setDetectedValues] = useState(new Set<string>());
  const [foundParts, setFoundParts] = useState<PartsAppPart[]>([]);

  function getMedian(arr: Array<number>) {
    arr.sort((a: number, b: number) => a - b);
    const half = Math.floor(arr.length / 2);
    if (arr.length % 2 === 1) {
      return arr[half];
    }
    return (arr[half - 1] + arr[half]) / 2;
  }

  function getMedianOfCodeErrors(
    codeResult: QuaggaJSResultObject["codeResult"]
  ) {
    const errors = codeResult.decodedCodes
      .filter((x) => x.error !== undefined)
      .map((x) => (isNullOrUndefined(x.error) ? 0 : x.error));

    if (errors.length > 0) {
      const medianOfErrors = getMedian(errors);
      return medianOfErrors;
    } else {
      return 0;
    }
  }

  useEffect(() => {
    if (props.shouldBeScanning) {
      startQuagga();
    } else {
      stopQuagga();
    }
    return () => {
      stopQuagga();
    };
  }, [props.shouldBeScanning]);

  function startQuagga() {
    const renderBoxElement = document.getElementById("barcode-render-box");
    if (renderBoxElement) {
      Quagga.init(
        {
          inputStream: {
            name: "Live",
            type: "LiveStream",
            target: renderBoxElement,
          },
          decoder: {
            readers: [
              "upc_reader",
              "code_128_reader",
              "ean_8_reader",
              "ean_reader",
            ],
            multiple: true,
          },
          locate: true,
        },
        function (err: Error) {
          if (err) {
            console.log(err);
            return;
          }
          Quagga.start();
        }
      );
    }
  }

  function stopQuagga() {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        stream.getTracks().forEach((track) => {
          if (track.readyState == "live") {
            track.enabled = false;
            track.stop();
          }
          stream.removeTrack(track);
        });
      })
      .catch((e) => console.error(e));
    Quagga.stop();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDetection = (data: any) => {
    data.forEach((result: QuaggaJSResultObject) => {
      if (result.codeResult) {
        if (
          getMedianOfCodeErrors(result.codeResult) < READ_CONFIDENCE_THRESHOLD
        ) {
          setDetectedValues((prevSet) => {
            if (result.codeResult.code) {
              if (!prevSet.has(result.codeResult.code)) {
                fetchPartsFromBarcode(result.codeResult.code);
              }
              return new Set([...prevSet, result.codeResult.code]);
            } else return prevSet;
          });
        }
      }
    });
  };
  Quagga.onDetected(handleDetection);

  async function fetchPartsFromBarcode(barcode: string) {
    const requestParams: BarcodeSearchRequestModel = {
      BarcodesToFind: [barcode, barcode.substring(0, barcode.length - 1)],
      Depot: props.depotId,
    };
    const requestOptions: RequestInit = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestParams),
    };
    try {
      const response = await apiFetch<PartsAppPart[]>(
        props.barcodeSearchEndpoint,
        requestOptions
      );

      if (response && response.length > 0) {
        if ("vibrate" in navigator) {
          navigator.vibrate(50);
        }
        response.forEach((responsePart: PartsAppPart) => {
          setFoundParts((oldFoundParts) => [...oldFoundParts, responsePart]);
        });
      }
    } catch (error: unknown) {
      const empty: PartsAppPart[] = [];
      return empty;
    }
  }

  const handlePartClick = (part: PartsAppPart) => {
    if (part.depot) {
      props.onSelect(part);
    }
  };

  return (
    <div className="barcode-page-wrapper">
      <div className="camera-view-wrapper">
        <div id="barcode-render-box" className="barcode-render-box"></div>
      </div>
      <div className="barcode-ui-divider" />
      <div className="barcode-microcard-collection">
        {foundParts.length > 0
          ? foundParts
              .filter(
                (part: PartsAppPart, index, array) =>
                  array.findIndex(
                    (p: PartsAppPart) => p.partNumber == part.partNumber
                  ) == index
              )
              .map((part: PartsAppPart) => {
                return (
                  <>
                    <div
                      id={"part-microcard-" + part.partNumber + "-header"}
                      key={part.partNumber}
                      className={
                        "barcode-microcard" +
                        (part.depot ? "" : " parts-list-item-transparent")
                      }
                      onClick={() => handlePartClick(part)}
                    >
                      <span
                        style={{
                          fontWeight: "bold",
                          paddingRight: "3px",
                          fontSize: "1.2em",
                        }}
                      >
                        {part.partNumber}
                      </span>
                      {part.depot ? (
                        <span>{part.description}</span>
                      ) : (
                        <span className="gold-foreground">
                          <i>UNAVAILABLE AT DEPOT</i>
                        </span>
                      )}
                    </div>
                  </>
                );
              })
          : "Searching..."}
      </div>
    </div>
  );
};

export default BarcodeCameraScanner;
