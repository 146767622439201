import React, { useState } from "react";
import "./TabList.css";

export interface TabProps {
  index: number;
  label: string;
  enabled: boolean;
  callback(): void;
}

export interface TabListProps {
  tabs: TabProps[];
  isDisabled: boolean;
  twoOptions: boolean;
}

const TabList = (props: TabListProps) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabClick = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    tab: TabProps
  ) => {
    setSelectedTab(tab.index);
    tab.callback();
  };

  return (
    <div className={`tab-container ${props.isDisabled ? "greyed-out" : ""}`}>
      {props.tabs.map((e: TabProps) => (
        <span
          id={"part-detail-tab-" + e.index}
          onClick={(x) => {
            if (!props.isDisabled && e.enabled) handleTabClick(x, e);
          }}
          className={
            `tab ${selectedTab == e.index ? "selected" : ""} ${e.enabled ? "" : "greyed-out"}` +
            (props.twoOptions ? ` two-options` : ``)
          }
          key={e.label}
        >
          {e.label}
        </span>
      ))}
    </div>
  );
};

export default TabList;
