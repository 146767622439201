import React, { useState, useEffect } from "react";
import "./EditSortBy.css";
import AbstractButton from "../../components/AbstractButton";
import ForegroundColorSet from "../../../utilities/enums/ForegroundColorSet";
import BackgroundColorSet from "../../../utilities/enums/BackgroundColorSet";
import { FilterEditorProps } from "../../../interfaces/FilterEditorProps";
import { ReactComponent as BackIcon } from "../../../assets/icons/checkbox-checked-regular.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { PartProductGroup } from "../../../interfaces/SearchFilterCategoryValues";

const EditProductGroups = (props: FilterEditorProps) => {
  const [localSearchData, setLocalSearchData] = useState(props.searchData);

  const myProductGroups = useSelector(
    (state: RootState) =>
      state.root.system.searchFilterCategoryValues.productGroups
  );

  const [productGroups, setProductGroups] =
    useState<PartProductGroup[]>(myProductGroups);
  const [searchText, setSearchText] = useState("");

  const handleSearchTextChange = (event: { target: { value: string } }) => {
    setSearchText(event.target.value);
    if (event.target.value == "") {
      setProductGroups(myProductGroups);
    } else {
      setProductGroups(
        myProductGroups.filter(
          (e: PartProductGroup) =>
            e.prdGroup
              .toLowerCase()
              .includes(event.target.value.toLowerCase()) ||
            (e.desc &&
              e.desc.toLowerCase().includes(event.target.value.toLowerCase()))
        )
      );
    }
  };

  useEffect(() => {
    setLocalSearchData(props.searchData);
  }, [props.searchData]);

  useEffect(() => {
    setProductGroups(myProductGroups);
  }, [myProductGroups]);

  const handleBack = () => {
    props.onCancelCallback(localSearchData);
  };

  const handleCheckboxChange = (
    e: PartProductGroup,
    ev: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!ev.target.checked) {
      setLocalSearchData({
        ...localSearchData,
        ProductGroups: localSearchData.ProductGroups.filter(
          (p: string) => p != e.prdGroup
        ),
      });
    } else {
      setLocalSearchData({
        ...localSearchData,
        ProductGroups: [...localSearchData.ProductGroups, e.prdGroup],
      });
    }
  };

  const handleClearClick = () => {
    setLocalSearchData({
      ...localSearchData,
      ProductGroups: [],
    });
  };

  return (
    <div
      id="edit-sortby-modal"
      className={`filter-modal-contents ${
        props.visible ? "slide-in" : "slide-out-top"
      }`}
    >
      <div className="flex-items">
        <h1 className="sort-and-filter-header">Product Groups</h1>
      </div>

      <div className="flex-items">
        <hr></hr>
      </div>
      <div className="filter-options-search-container">
        <input
          type="text"
          id="product-group-search-box"
          className="filter-search-box"
          value={searchText}
          placeholder="Search groups..."
          onChange={handleSearchTextChange}
        />
        <button
          id="prefix-clear-checkboxes-button"
          className="clear-selection-button"
          onClick={handleClearClick}
        >
          CLEAR
        </button>
      </div>
      <div className="flex-items">
        <hr></hr>
      </div>
      <div className="filter-options-list-table-container">
        <table id="class-code-container" className="filter-options-list-table">
          <tbody>
            {productGroups.map((e: PartProductGroup) => (
              <tr className="filter-options-row" key={e.prdGroup}>
                <td className="filter-option-label-cell-extended filter-option-label product-group-selection">
                  {e.prdGroup} - {e.desc}
                </td>
                <td className="filter-option-value-cell">
                  <input
                    type="checkbox"
                    id={"prod-" + e.prdGroup}
                    checked={
                      localSearchData.ProductGroups.filter(
                        (f) => f == e.prdGroup
                      ).length == 1
                    }
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                      handleCheckboxChange(e, ev)
                    }
                    className="checkbox-style"
                  ></input>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex-items">
        <hr></hr>
      </div>

      <div>
        <span className="filter-modal-button-span">
          <AbstractButton
            id="prdgrps-cancel-button"
            text="Done"
            icon={<BackIcon width="100%" height="100%" />}
            disabled={false}
            activeColor={ForegroundColorSet.Green}
            background={BackgroundColorSet.Black1}
            actionCallback={handleBack}
            specialClass="modal-button"
          />
        </span>
      </div>
    </div>
  );
};

export default EditProductGroups;
