import { ReactComponent as EnquireIcon } from "../../assets/icons/text-bullet-list-square-search-20-regular.svg";
import { ReactComponent as ReceiveIcon } from "../../assets/icons/tray-item-add-20-regular.svg";
import { ReactComponent as PickIcon } from "../../assets/icons/tray-item-remove-24-regular.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/task-list-square-rtl-24-regular.svg";
import { ReactComponent as HomeIcon } from "../../assets/icons/home-person-24-regular.svg";
import { connect, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import MainMenuButton from "./components/HomeNavButton";
import "./Home.css";
import HomeHeader from "./components/HomeHeader";
import Depot from "../../interfaces/Depot";
import { RootState } from "../../store/store";
import { useNavigate } from "react-router-dom";
import { apiFetch } from "../../utilities/AuthenticatedFetch";
import { Feature, PartsAppFeature } from "../../store/slices/authSlice";

interface HomeProps {
  depotId: string;
}

const mapStateToProps = (state: RootState) => {
  return {
    depotId: state.root.auth.defaultDepotId,
  };
};

const Home = (props: HomeProps) => {
  const navigate = useNavigate();
  const canUseReceiveModule 
   = useSelector((state: RootState) => state.root.auth.features.filter((e: PartsAppFeature) => e.featureId == Feature.Receive).length > 0); 
  const firstName = useSelector(
    (state: RootState) => state.root.auth.firstName
  );
  const lastName = useSelector((state: RootState) => state.root.auth.lastName);
  const allDepots = useSelector((state: RootState) => state.root.system.depots);
  const [depotName, setDepotName] = useState("");

  useEffect(() => {
    try {
      setTimeout(() => {
        apiFetch<boolean>("/partsapp/auth/authed", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
      }, 1000);
    } catch {
      //ignore errors here, you'll be redirected if there's an issue
    }
  }, []);

  useEffect(() => {
    if (allDepots.length == 0) {
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } else {
      setDepotName(
        allDepots.filter((e: Depot) => e.id == props.depotId)[0].shortName
      );
    }
  }, [props.depotId]);

  return (
    <div className="main-menu">
      <HomeHeader Depots={allDepots} />
      <div
        id="main-menu-button-container"
        className="main-menu-button-container"
      >
        <MainMenuButton
          id="enq"
          text={process.env.REACT_APP_BUILD_ENV?.toUpperCase() != "PLA" ? "Enquire" : "Lookup"}
          icon={<EnquireIcon />}
          route="/enquire"
        />
        {process.env.REACT_APP_BUILD_ENV?.toUpperCase() != "PLA" ? 
        <>
          <MainMenuButton
            id="rec"
            route={canUseReceiveModule ? "/receive" : undefined}
            text="Receive"
            icon={<ReceiveIcon />}
          />
          <MainMenuButton id="chk" text="Check" icon={<CheckIcon />} />
          <MainMenuButton id="pck" text="Pick" icon={<PickIcon />} />
        </>
        : null}
      </div>
      <div className="main-menu-footer">
        <div className="main-menu-footer-icon">
          <HomeIcon width="10vh" height="15vh" />
        </div>
        <div id="main-menu-footer-text" className="main-menu-footer-text">
          {firstName} {lastName}
          <br />
          <b>{depotName}</b>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(Home);
