import { Inclusivity } from "../../../interfaces/PartSearch";
import React, { useState } from "react";

interface InclusivitySelectorProps {
  label: string;
  callback(value: Inclusivity): void;
}

const InclusivitySelector = (props: InclusivitySelectorProps) => {
  const [inclusivity, setInclusivity] = useState(Inclusivity.Shown);

  const onlyClicked = () => {
    if (inclusivity == Inclusivity.Only) {
      setInclusivity(Inclusivity.Shown);
      props.callback(Inclusivity.Shown);
    } else {
      setInclusivity(Inclusivity.Only);
      props.callback(Inclusivity.Only);
    }
  };

  const hideClicked = () => {
    if (inclusivity == Inclusivity.Hidden) {
      setInclusivity(Inclusivity.Shown);
      props.callback(Inclusivity.Shown);
    } else {
      setInclusivity(Inclusivity.Hidden);
      props.callback(Inclusivity.Hidden);
    }
  };

  return (
    <tr className="filter-option-row">
      <td className="filter-option-label-cell"> {props.label} </td>
      <td className="filter-option-checkbox-cell">
        <span className="checkbox-label">Only</span>
        <input
          id={
            (props.label === "Memo:" ? "Memo" : props.label.split(" ")[0]) +
            "-only"
          }
          type="checkbox"
          className="checkbox-style"
          onClick={onlyClicked}
          readOnly
          checked={inclusivity == Inclusivity.Only}
        ></input>
      </td>
      <td className="filter-option-checkbox-cell">
        <span className="checkbox-label">Hide</span>
        <input
          id={
            (props.label === "Memo:" ? "Memo" : props.label.split(" ")[0]) +
            "-hide"
          }
          type="checkbox"
          className="checkbox-style-red"
          onClick={hideClicked}
          readOnly
          checked={inclusivity == Inclusivity.Hidden}
        ></input>
      </td>
    </tr>
  );
};

export default InclusivitySelector;
